import { NEWSLETTER_SERVICE } from "@/services/newsletter.service.js";

const state = { processNewsletter: undefined};

const actions = {
    submitNewsletter({ dispatch, commit }, email) {
        dispatch('alert/clear', null, { root: true });
        commit('submitNewsletterRequest');

        NEWSLETTER_SERVICE.submitNewsletter(email)
        .then(
            output => {
                commit('submitNewsletterSuccess', output);
                dispatch('alert/success', output, { root: true });
            },
            error => {
                commit('submitNewsletterFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },
};

const mutations = { 
    /* Newsletter  */
    submitNewsletterRequest(state) {
        state.processNewsletter = null;
    },
    submitNewsletterSuccess(state) {
        state.processNewsletter = true;
    },
    submitNewsletterFailure(state) {
        state.processNewsletter = false;
    },
};

export const newsletter = {
    namespaced: true,
    state,
    actions,
    mutations
};
/* Imports */
import { API_HELPER } from "@/helpers/api.js";
import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";

/* Exports */
// API services that can be invoked on the modules
export const ACCOUNT_SERVICE = {
    login,
    signup,
    requestLink,
    requestConfirmationToken,
    resetPassword,
    confirmAccount,
    // requestToken,
    refreshToken,
    updateProfile,
    logout
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Users";

/* Services */
function login(username, password) 
{
    // Mandatory parameters
    if(!username || !password)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({username, password});

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Login"]);

    return processLogin(url, requestOptions);
}
function processLogin(url, requestOptions)
{
    return fetch(encodeURI(url), requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) console.log(response);

        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) console.log(data);

            if (!response.ok) {
                return Promise.reject(data);
            }
    
            var user = data;

            // login successful if there's a jwt token in the response
            if (user && user.refreshToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
        
            return user;
        });        
    });
}
function signup(input)
{
    // Mandatory parameters
    if(!input || !Object.values(input).some(v => v))
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'POST',
        headers: {"Content-Type":"application/json"},
        body: JSON.stringify(input)
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "SignUp"]);
    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}
function requestLink(username) 
{
    // Mandatory parameters
    if(!username)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Password", "Reset", username]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}
function requestConfirmationToken(username) 
{
    // Mandatory parameters
    if(!username)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    const requestOptions = {
        method: 'GET',
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "ConfirmAccount", username]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}
function resetPassword(username, password, token) 
{
    // Mandatory parameters
    if(!username || !password || !token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({username, password, token});
    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Password" ,"Reset"]);

    return API_HELPER.apiCall(url, null, requestOptions);    
}
function confirmAccount(username, token) 
{
    // Mandatory parameters
    if(!username || !token)
    {
        return Promise.reject("The parameters are not complete!");
    }

    //

    var raw = JSON.stringify({"username": username, "token": token});

    const requestOptions = {
        method: 'PUT',
        headers: {"Content-Type":"application/json"},
        body: raw
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "ConfirmAccount"]);
    
    return API_HELPER.apiCall(url, null, requestOptions);
}
function refreshToken(token, username)
{
    // Mandatory parameters
    if(!token || !username)
    {
        return Promise.reject("The parameters are not complete!");
    }

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token, username})
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "RefreshToken"]);

    return API_HELPER.apiCall(url, null, requestOptions);
}

function updateProfile(password) 
{
    // Mandatory parameters
    if(!password)
    {
        return Promise.reject("The parameters are not complete!");
    }

    const requestOptions = {
        method: 'PUT',
        headers: { "Content-Type":"application/json" },
        body: JSON.stringify(password),
    };

    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Password"]);

    return API_HELPER.loggedApiCall(url, null, requestOptions);    
}

function logout() {
    // TODO Integrate logout service
    return true;
}
<template>
  <div class="pois-height">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 title py-3">
          <h2 class="align-self-center">{{ title }}</h2>
        </div>
        <hr />
        <!-- <div id="category-filter-container" class="col-12 col-lg-8 categories align-items-center">
              <CategoryFilter v-if="type" @change="onCategoriesChanged" :type="type"/>
          </div> -->
      </div>
      <LogoLoading v-if="loading"/>
      <!-- Content -->
      <div v-else class="row flex-wrap py-3">
        <PoisThumb
          class="col-12 col-md-6 col-lg-4 p-2"
          v-for="content in pois"
          :key="content.id"
          :poiContent="content"
        />
        <NoResultsPlaceholder v-if="!pois.length"></NoResultsPlaceholder>
      </div>
      <div v-show="!loading && canLoadMore" class="see">
        <div class="col-12 d-flex justify-content-center">
          <img
            @click="loadMore"
            class="d-block text-center cursor-pointer"
            src="@/assets/showMore.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="col-12 d-flex justify-content-center">
          <p @click="loadMore" class="d-block text-center">
            {{ $t("showMore") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PoisThumb from "@/components/PoisThumb.vue";
// import CategoryFilter from "@/components/CategoryFilter.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import router from "@/router";
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  components:{PoisThumb,  NoResultsPlaceholder, LogoLoading},
  data() {
    return {
      type: null,
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      title: "",
    };
  },
  metaInfo () {
    var result =
    { 
        title: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Tabuaço` : "Tabuaço", 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRouter.poisDescriptionSufix") : this.$t("intro")},
          {name: 'keywords', content: this.categories && this.categories.length ? this.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
          // Twitter Card
          {name: 'twitter:card', content: "summary"},
          {name: 'twitter:title', content: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Tabuaço` : "Tabuaço"},
          {name: 'twitter:description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRouter.poisDescriptionSufix") : this.$t("intro")},
          {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
          //Facebook OpenGraph
          {property: 'og:title', content: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Tabuaço` : "Tabuaço"},
          {property: 'og:site_name', content: 'Tabuaço'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
          {property: 'og:description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRouter.poisDescriptionSufix") : this.$t("intro")},
        ]
    } 

    if(this.$route.query.type)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.pois", 'pt')])}?type=${this.$route.query.type}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.pois", 'en')])}?type=${this.$route.query.type}`},
        ];
    }

    return result;
  }, 
  props:{
    searchQuery: String
  },
  methods: {
    ...mapActions({
      getPois: "pois/getList",
    }),
    updateItemsRequested: function (categories) {
      const currentPage = 1;
      const searchText = this.searchQuery;
      const sorter = 2; // desc
      const propertyName = "id";

      //Make request
      var input = new InputGetList(
        this.itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        searchText
      );
      input.type = [this.type];
      if (categories && categories.length) {
        input.categories = categories;
      }

      this.getPois(input);
    },
    loadMore() {
      if (this.canLoadMore) {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE;
      }
    },
    onCategoriesChanged: function (categories) {
      this.updateItemsRequested(categories);
    },
    initializePage: function () {
      if (!this.$route.query.type) {
        router.push({ path: "/" });
      } else {
        this.type = parseInt(this.$route.query.type);
        switch (this.type) {
          case 1:
            this.title = this.$t("shop.oQueVisitar").toUpperCase();
            break;
          case 2:
            this.title = this.$t("shop.ondeComer").toUpperCase();
            break;
          case 3:
            this.title = this.$t("shop.ondeDormir").toUpperCase();
            break;
        }
      }
      // Get pois
      this.updateItemsRequested();
    },
  },
  computed: {
    ...mapState({
      poisOutput: (state) => state.pois.listOutput,
      categoriesOutput: (state) => state.categories.listOutput,
    }),
    loading: function () {
      return this.poisOutput == null;
      // return this.poisOutput == null || this.categoriesOutput == null;
    },
    pois: function () {
      return this.poisOutput && this.poisOutput.data
        ? this.poisOutput.data
        : [];
    },
    numPois: function () {
      console.log(this.poisOutput.data)
      return this.poisOutput && this.poisOutput.totalRecords
        ? this.poisOutput.totalRecords
        : 0;
    },
    canLoadMore: function () {
      return this.numPois > this.itemsPerPage;
    },
    categories: function () {
      return this.categoriesOutput &&
        this.categoriesOutput.data &&
        this.categoriesOutput.data.categories
        ? this.categoriesOutput.data.categories
        : null;
    },
  },
  mounted: function () {
    this.initializePage();
  },
  watch: {
    $route(to) {
      if (to.name == "Pois") {
        this.initializePage();
      }
    },
    itemsPerPage() {
      this.updateItemsRequested();
    },
    searchQuery: function()
    {
      this.updateItemsRequested();
    }
  },
};
</script>

<style scoped>
.pois-height {
  min-height: 100vh;
}
/* Title */
.title {
  justify-content: center;
  display: flex;
}
.title h2 {
  margin: 0;
  font-weight: bold !important;
}
hr {
  opacity: 1 !important;
  background-color: transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
.see > p {
  margin-top: 1rem;
  cursor: pointer;
}
</style>

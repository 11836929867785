<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
            <div class="vueperslide__content-wrapper">
                <div class="vueperslide__title">
                    <h1>{{heroContent.content}}</h1>
                </div>
                <div class="mt-4">
                    <a :href="heroContent.url" class="btn-border-white text-dec-none">{{heroContent.buttonText}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
    props: {
        heroContent: {
            type: Object
        }
    },
    computed:{
        imageUrl: function()
        {
            var relativePath = this.heroContent && this.heroContent.imagePath ? this.heroContent.imagePath : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        }
    }
}
</script>

<style scoped>
.vueperslide__title h1{
    font-size: 4.5rem;
    color: white;
    font-weight: 900;
    text-shadow: 0 0 5px #000;
    padding: 0 24rem;
}
.btn-border-white
{
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  height: 45px;
  font-weight: 900;
  padding: 0 80px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 2px solid #fff;
  color: #fff;
  background-color: rgba(0,0,0,.5);
}
.btn-border-white:hover {
  color: white;
  background-color: black;
  border: 2px solid black;
}
@media (max-width: 1304px) {
    .vueperslide__title h1{
        font-size: 3.5rem;
        padding: 0;
    }
    .btn-border-white
    {
        padding: 0 10px;
    }
}
@media (max-width: 576px) {
    .vueperslide__title h1{
        font-size: 2.5rem;
    }
    .btn-border-white
    {
        font-size: 1rem;
    }
}
</style>
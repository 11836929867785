<!-- This component can be used with inspirations and products -->
<template>
  <div class="d-flex flex-column card cursor-pointer">
    <div class="d-flex justify-content-center align-items-center card-image mb-2">
      <img :src="imageUrl" class="w-100 h-100" @click="sendClicked"/>
      <span class="p-2 text position-absolute">{{type}}</span>
      <div @click="sendClicked" :class="{'type-pois': type == POIS, 'type-product': type == PRODUCTS}"></div>
      <span v-if="isFavorite" @click="favoriteButtonClicked" id="icon-fav2">
        <img src="@/assets/shop/isFav.svg" :style="{ width: '25px' }" class="fav" alt="">
      </span>
    </div>
    <div class="d-flex flex-column mt-2 card-text p-3 w-100"  @click="sendClicked">
      <h4>{{ favorite.title }}</h4>
      <p>{{excerpt}}</p>
    </div>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { mapState } from "vuex";

const EXCERPT_LENGTH = 100;
const POIS = "Poi";
const PRODUCTS = "Product";

export default {  
  props: {
    favorite: Object
  },
  data() {
    return {
      clicked: false,
      //
      POIS: POIS,
      PRODUCTS: PRODUCTS
    }
  },
  methods: {
    sendClicked () {
    if(this.favorite.type == POIS)
      {
        this.$router.push({name: "PoisDetail", query: {id: this.favorite.id} });
      }
      else{ // product
        this.$router.push({name: "ProductDetail", params: { slug: this.favorite.slug} });
      }  
    },
    favoriteButtonClicked()
    {
      this.clicked = true;
      if(this.isFavorite)
      {
        this.$emit("warning-alert", this.favorite);
      }
    }
  },
  computed:{
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['removeTask']),
    type: function()
    {
      return this.favorite.type == POIS ? POIS : PRODUCTS;
    },
    isFavorite: function()
    {
      return this.listOutput && this.listOutput.data ? this.listOutput.data.find(x=> x.slug == this.favorite.slug) : false; 
    },
    imageUrl: function()
    {
      var relativePath = this.favorite.featuredImagePath ? this.favorite.featuredImagePath : null;
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);      
    },
    excerpt: function()
    {
      var result = '';

      if(this.favorite && this.favorite.content)
      {
        var div = document.createElement('div');
        div.innerHTML = this.favorite.content.trim();

        result = div.textContent || div.innerText || "";
        if(result.length > EXCERPT_LENGTH)
        {
          result = result.substring(0, EXCERPT_LENGTH) + "...";
        }
      }

      return result;
    }
  }
}
</script>

<style scoped>
img
{
  object-fit: cover;
}

.card {
  border: 1px solid #DDB427;
  background: white;
  min-height: 400px;
  overflow: hidden;
}

.card-image
{
  position: relative;
  height: 60%;
}
.text{
  top: 0;
  left: 0;
  z-index: 1;
  color: white;
  margin: -5px auto;
}
.type-pois, .type-product{
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  border-right: 25px solid transparent;
}
.type-pois
{
  border-top: 30px solid #DDB427;
}
.type-product{
  border-top: 30px solid #4D4D4D;
}
.fav{
  width: 35px;
  margin: -5px 5px;
}
#icon-fav2{
  position: absolute;
  top: 0;
  right: 0;
}
.icon-fav2 {
  font-size: 40px;
}
.path1:before {
  content: "\e938";
  color: rgb(0, 0, 0);
}
.path2:before {
  content: "\e939";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.path3:before {
  content: "\e93a";
  margin-left: -0.70703125em;
  color: rgb(255, 255, 255);
}
.card-image .icon {
  position: absolute;
  top: 0;
  right: 0;
}

.card-text
{
  height: 40%;
  overflow: hidden;
}

.card h4 {
  text-align: center;
  font-weight: bold;
}
.card h5 {
  text-align: center;
  color: #DDB427;
  margin-bottom: 10px;
  font-size: 15px;
}
.card p {
  text-align: center;
  width: 90%;
  /* border: 1px dashed black; */
  margin: 0 auto;
  font-size: 16px;
}

@media screen and (max-width: 1400px) {
  .md-layout-item span{
    left: -60%;
  }
}
@media screen and (max-width: 1140px) {
  .md-layout-item span{
    left: -55%;
    font-size: 13px;
  }
}
@media screen and (max-width: 960px) {
  .md-layout-item span{
    display: none;
  }
}
@media (max-width: 767px) /* Two columns */
{
  .type,
  .type-product{
    width: 150px;
  }
}
@media screen and (max-width: 405px) {
  .container-list .card {
    width: 300px;
    height: 400px;
  }
  .card p {
    margin: 25px auto;
    font-size: 13px;
  }
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.products)?_c('div',{staticClass:"container",attrs:{"id":"store"}},[_c('h4',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('latestViewed.title')))]),_c('vueper-slides',{staticClass:"no-shadow flex-grow-1 m-0 py-2",attrs:{"fixed-height":"550px","bullets":false,"visible-slides":3,"slide-multiple":"","gap":1,"dragging-distance":70,"touchable":false,"breakpoints":{
      1400: { visibleSlides: 2, slideMultiple: 2 },
      992: { visibleSlides: 1, slideMultiple: 1 },
      577: {
        visibleSlides: 1,
        slideMultiple: 1,
        touchable: true,
        arrows: false,
      },
    }},scopedSlots:_vm._u([{key:"arrow-left",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/svg/storeSlide/arrow-left.svg"),"alt":"","loading":"lazy"}})]},proxy:true},{key:"arrow-right",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/svg/storeSlide/arrow-right.svg"),"alt":"","loading":"lazy"}})]},proxy:true}],null,false,2293043849)},_vm._l((_vm.products.data),function(product){return _c('vueper-slide',{key:product.id,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ProductThumb',{attrs:{"product":product}})]},proxy:true}],null,true)})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
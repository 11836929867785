import { GALLERY_SERVICE } from "@/services/gallery.service.js";

const state = { listOutput: null };

const actions = {
    getList({ dispatch, commit }, inputGetGallery) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        GALLERY_SERVICE.list(inputGetGallery)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
};

export const gallery = {
    namespaced: true,
    state,
    actions,
    mutations
};
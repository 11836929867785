import { FAQS_SERVICE } from "@/services/faqs.service.js";


const state = { listOutput: null, detailOutput : null };

const actions = {
    getList({ dispatch, commit }, inputGetFaqs) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        FAQS_SERVICE.list(inputGetFaqs)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetails({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('listDetailRequest');
        
        FAQS_SERVICE.getDetail(id)
            .then(
                output => {
                    commit('listDetailSuccess', output);
                },
                error => {
                    commit('listDetailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Detail */
    listDetailRequest(state) {
        state.detailOutput = null;
    },
    listDetailSuccess(state, output) {
        state.detailOutput = output;
    },
    listDetailFailure(state) {
        state.detailOutput = false;
    }
};


export const faqs = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
  <div v-if="poiContent" class="cursor-pointer">
    <div class="content">
      <img :src="imageUrl" class="w-100 h-100" @click="sendClicked" loading="lazy"/>
      <img
        v-if="!isFavorite"
        @click="favoriteButtonClicked"
        class="fav cursor-pointer"
        src="@/assets/shop/favIconPois.svg"
        alt=""
        :style="{ width: '25px' }"
        loading="lazy"
      />
      <img
        v-if="isFavorite"
        @click="favoriteButtonClicked"
        class="fav cursor-pointer"
        src="@/assets/shop/isFav.svg"
        alt=""
        :style="{ width: '25px' }"
        loading="lazy"
      />
      <div class="row row-align">
        <div class="col-12 d-flex">
          <div class="category-name text-white mt-1 content-align">
            <span class="categoryName-style">{{ categoryName }}</span>
            <span v-if="this.poiContent.rating">
              <!-- Rating component -->
              <StarRating :rating="this.poiContent.rating"></StarRating>
            </span>
          </div>
        </div>
        <div class="col-12 d-flex">
          <div class="poi-name text-white mt-2">
            <h5>{{ poiContent.title }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "@/components/StarRating.vue";
import { API_HELPER } from "@/helpers/api.js";
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: { StarRating },
  props: {
    poiContent: Object,
  },
  mounted () {
    if(this.listOutput)
    {
      this.getFavsRequested();
    }
  },
  data() {
    return {
      clicked: false
    }
  },
  computed: {
    ...mapState("favorites", ["listOutput"]),
    ...mapState("favorites", ["addTask"]),
    ...mapState("favorites", ["removeTask"]),

    imageUrl: function () {
      return API_HELPER.pathJoin([
        process.env.VUE_APP_URL_ROOT,
        this.poiContent.featuredImagePath,
      ]);
    },
    categoryName: function () {
      return this.poiContent.categories &&
        this.poiContent.categories.length &&
        this.poiContent.categories[0].name
        ? this.poiContent.categories[0].name
        : "";
    },
    isFavorite: function () {
      return this.listOutput && this.listOutput.data
        ? this.listOutput.data.find((x) => x.slug == this.poiContent.slug)
        : false;
    },
  },
  methods: {
    ...mapActions("favorites", ["getList"]),
    ...mapActions("favorites", ["addFavorite"]),
    ...mapActions("favorites", ["removeFavorite"]),

    sendClicked() {
      this.$router.push({
        name: "PoisDetail",
        query: { id: this.poiContent.id },
      });
    },
    favoriteButtonClicked() {
      this.clicked = true;

      if (this.isFavorite) {
        this.removeFavorite(this.poiContent.slug);
      } else {
        this.addFavorite(this.poiContent.slug);
      }
    },
    getFavsRequested() {
      const itemsPerPage = 999;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";

      //Make request
      var inputGetFavorites = new InputGetList(
        itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        null
      );

      // Get favs
      this.getList(inputGetFavorites);
    },
  },
  watch: {
    addTask: function (val) {
      if (val && this.clicked) {
        this.getFavsRequested();
      }
      if(val != null) this.clicked = false;
    },
    removeTask: function (val) {
      if (val && this.clicked) {
        this.getFavsRequested();
      }
      if(val != null) this.clicked = false;
    },

  },
};
</script>

<style scoped>
/* In order to guarantee squares, there is a container */
.content {
  width: 100%;
  height: 335px;
  position: relative;
}
.content .fav{
  position: absolute;
  top: 3%;
  right: 3%;
}
.row-align {
  position: absolute;
  bottom: 3%;
}
.category-name {
  font-size: 12px;
  justify-content: start;
  background-color: #3c8793;
  padding-left: 5px;
  padding-right: 5px;
}
.poi-name {
  font-size: 17px;
  justify-content: start;
  padding-left: 15px;
}
.content-align {
  display: inline-flex;
}
.categoryName-style {
  margin-right: 5px;
}
@media (max-width: 991px) {
  .category-name {
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .overlay_2 .poi-name {
    font-size: 13px;
  }
}
</style>

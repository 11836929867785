<template>
<div class="container">
  <!-- Title -->
  <div class="row">
    <div class="col-12 pt-3 pb-3">
      <h1 class="text-center">{{ $t("faq.title") }}</h1>
    </div>
    <hr>
  </div>
  <LogoLoading v-if="!faqGroups"/>
  <div v-else class="container">
  <!-- FAQ group -->
  <div class="d-flex flex-column py-2" v-for="(faqGroup, index) in faqGroups" :key="index">
    <div class="row background-color-primary">
      <div class="col-12 d-flex justify-content-between">
        <span class="text-light title-faqs font-clanpro fw-bold">{{ `${index + 1}. ` + faqGroup.name}}</span>
          <div class="d-flex align-self-center cursor-pointer" @click="getDetail(index)">
            <span v-bind:class="{'rotated': selected == index}">
              <img src="@/assets/svg/uni_selection_white_arrow.svg" alt="" loading="lazy">
            </span>
          </div>
      </div>
    </div>
    <!-- Questions -->
    <div class="questions">
      <div v-for="(item,indexI) in items" :key="indexI">
        <div v-show="selected == index">
          <div class="d-flex justify-content-between cursor-pointer" @click="categorySelected == indexI ? categorySelected = -1 : categorySelected = indexI">
            <span class="py-1 font-clanpro fw-bold">{{ `- ${item.question}` }}</span>
            <span class="open-indicator d-flex justify-content-center">
              <span v-bind:class="{'rotated': categorySelected == indexI}">
                <img src="@/assets/svg/uni_selection_arrow.svg" alt="" loading="lazy">
              </span>
            </span>
          </div>
          <span class="py-1 font-clanpro" v-show="categorySelected == indexI" v-html="item.answer"></span>
          <hr class="questions-hr" v-if="separatorBorder || indexI != items.length - 1"/>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import LogoLoading from "@/components/LogoLoading.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

export default {
  components: { LogoLoading },
  metaInfo () {
      return { 
            title: `${this.$t("vueRouter.faqTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.faqDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Faq", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Faq", 'pt')])},
            ]
            
        } 
    },
  data: function () {
    return {
      isShow: false,
      selected: -1,
      categorySelected: -1,
      separatorBorder: false,
      items: null
    };
  },
  computed: {
    ...mapState('faqs', ['listOutput']),
    ...mapState('faqs', ['detailOutput']),
    faqGroups: function() {
      var result = [];

      if(this.listOutput && this.listOutput.data)
      {
        result = this.listOutput.data;
        result.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
      }

      return result;
    },
  },
  methods: {
    ...mapActions("faqs", ["getList"]),
    ...mapActions("faqs", ["getDetails"]),

    getDetail(index)
    {
      if(this.selected == index) this.selected = -1;
      else {
        this.selected = index;
        this.items = this.getDetails(index + 1);
      }
    }
  },
  mounted() {
    const itemsPerPage = 999;
    const currentPage = 1;
    const searchText = '';
    const sorter = 1; // desc
    const propertyName = "id";

    var input = new InputGetList(itemsPerPage,currentPage,sorter,propertyName,searchText);
     // List specific parameters
    input.visible= true;
    this.getList(input);
  },
  watch: {
    detailOutput(val) {
      if(val && val.items)
      {
        this.items = val.items;
      }
    }
  },
}
</script>

<style scoped>
.container
{
  min-height: 100vh;
}
h1{
  font-size: 40px;
  font-weight: 900;
}
.title-faqs{
  font-size: 24px;
}
.rotated {
  transform: rotate(180deg); /* Equal to rotateZ(45deg) */
}
.questions-hr {
  width: 100%;
  height: 15px !important;
  border-top: 2px dotted #DDB427;
  background-color: transparent;  margin: 0;
}
</style>
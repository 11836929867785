<template>
  <div class="d-flex">
    <b-sidebar id="sidebar-left" left no-header>
      <div class="p-5 h-100">
        <div class="d-flex flex-column h-100">
          <h1 class="font-bolder py-3" :style="{color: 'black'}">{{ $t("sideMenu.menu") }}</h1>
          <div v-b-toggle.sidebar-left></div>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'StaticPages', params: {slug: $t('slug.project')} }">{{$t("sideMenu.oProjecto")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'TravelPaths'}">{{ $t("sideMenu.percursos") }}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Reservations'}">{{ $t("sideMenu.reservas")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Pois', query: { type: 1 }}">{{ $t("sideMenu.oQueVisitar")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Pois', query: { type: 2 }}">{{ $t("sideMenu.ondeComer")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Pois', query: { type: 3 }}">{{ $t("sideMenu.ondeDormir")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Shop' }">{{ $t("sideMenu.lojaOnline")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'News' }">{{ $t("sideMenu.noticias")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Events'}">{{ $t("sideMenu.agenda")}}</router-link>
          <router-link class="nav-link font-weight-bold" :to="{ name: 'Gallery' }">{{ $t("sideMenu.galeriaVisual")}}</router-link>
          <!-- This logic, using the default hash navigation, is not working correctly, so a custom implementation was done -->
          <span class="nav-link font-weight-bold cursor-pointer" @click="$emit('hash-requested', 'support')">{{ $t("sideMenu.contactos")}}</span>
          <div class="d-flex justify-content-center flex-column align-items-center mt-6">
            <a href="/"><img src="@/assets/svg/menu/logo.svg" alt="" :style="{width: '170px'}" loading="lazy"></a>
            <IconsSocial class="icon px-2"/>
          </div>
        </div>
      </div>
    </b-sidebar>
    <div :class="{shadow: menuActive}"></div>
  </div>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";
export default {
  components:{IconsSocial},
  data(){
    return{
      drag: false,
      menuActive: false
    }
  },
  mounted() {
      document.addEventListener('mousedown', () => this.drag = false);
      document.addEventListener('mousemove', () => this.drag = true);
      document.addEventListener('mouseup', this.handleMouseUp);
      document.getElementById('btn-open').addEventListener("click", () => this.menuActive = true);
      document.getElementById('btn-close').addEventListener("click", () => this.menuActive = false);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', () => this.drag = false);
    document.removeEventListener('mousemove', () => this.drag = true);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.getElementById('btn-open').removeEventListener("click", () => this.menuActive = true);
    document.getElementById('btn-close').removeEventListener("click", () => this.menuActive = false);
  },
  methods: 
  {
    handleMouseUp(event) {
      var toggle = document.getElementById("navbar-toggle");

      if(toggle.classList.contains("not-collapsed") && !this.drag)
      {
        var element = document.getElementById("sidebar-left");
        var isClickInside = element.contains(event.target);
        var isAnchor = event.target.tagName == 'A';
        var isToggle = event.target.classList.contains("sizebtn");
        
        if (!isToggle || (!isClickInside && isAnchor)) 
        {
          document.getElementById("btn-close").click();
          this.menuActive = false;
        }
      }

      this.drag = false;
    }
  }
}
</script>

<style scoped>
.icon {
  position: static;
}
.shadow {
  width: 100%;
  background-color: rgba(0,0,0,.5);
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3;
}
>>>.user-text {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 60%;
  left: 35%;
  font-size: 11px;
}
>>>.b-sidebar {
    background-color:rgba(221, 180, 39, .8) !important;  
    box-shadow: 0 0 10px rgba(0,0,0,0.75);
    width: 360px;
}
>>>.nav-link {
    color: black !important;
    font-size: 1.3rem;
    text-decoration:  none;
    padding: 0.4rem 0;
}
>>>.b-sidebar.b-sidebar-left > .b-sidebar-header .close {
    margin-left: auto !important;
    margin-right: 0 !important;
}
>>>.b-sidebar-outer {
    z-index: 999;
}
>>>.b-sidebar > .b-sidebar-body {
    padding-top: 70px;
}
</style>
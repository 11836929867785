<template>
  <div class="container">
    <!-- Header and initial load more -->
    <div class="d-flex justify-content-between">
      <div class="col-6 title" :class="{'col-12': !loading && !products.length}">{{$t('products')}}</div>
      <div v-if="!loading && canLoadMore && itemsPerPage == DEFAULT_ITEMS_PER_PAGE" class="see d-flex align-items-center cursor-pointer" @click="loadMore">
          <div class="px-2">
            <span>{{$t("seeMore")}}</span>
          </div>
          <b-icon icon="plus-circle"></b-icon>
      </div>
      <div v-if="itemsPerPage != DEFAULT_ITEMS_PER_PAGE" @click="itemsPerPage = DEFAULT_ITEMS_PER_PAGE" class="d-flex align-items-center cursor-pointer">
          <div class="px-2">
            <b-icon icon="dash-circle"></b-icon>
          </div>
          <span>{{$t("seeLess")}}</span>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="product-container d-flex justify-content-between flex-wrap mt-2">
      <LogoLoading/>
    </div>
    <!-- Content -->
    <div v-else class="product-container d-flex justify-content-between flex-wrap mt-2">
      <ProductThumb class="product-thumb" v-for="product in products" :key="product.id" :product="product" />
      <NoResultsPlaceholder v-if="!products.length"></NoResultsPlaceholder>
    </div>
  </div>
</template>

<script>
import ProductThumb from "@/components/Shop/ProductThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 4;

export default {
    components: {
      ProductThumb,
      LogoLoading,
      NoResultsPlaceholder
    },
    props: {
      searchQuery: String
    },
    data() {
        return {
          initialized: false,
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          //
          DEFAULT_ITEMS_PER_PAGE: DEFAULT_ITEMS_PER_PAGE,
          products: []
        }
    },
    methods: {
      ...mapActions('products', ['getFeaturedList']),

      updateItemsRequested() {
        const currentPage = 1;
        const searchText = this.searchQuery;
        const sorter = 2; // desc
        const propertyName = "id";

        //Make request
        var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);

        this.getFeaturedList(input);
      },
      loadMore() {
          if (this.canLoadMore) {
            this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE;
          }
      }
    },
    computed: {
      ...mapState({
        productsOutput: state => state.products.featuredOutput,
      }),
      loading: function () {
        return this.productsOutput == null;
      },
      numProducts: function () {
        return this.productsOutput && this.productsOutput.totalRecords ? this.productsOutput.totalRecords : 0;
      },
      canLoadMore: function () {
        return this.numProducts > this.itemsPerPage;
      },
    },
    mounted() {
      // GET list of products
      this.updateItemsRequested();
    },
    watch: {
      itemsPerPage() {
        this.updateItemsRequested();
      },
      searchQuery: function () {
        this.updateItemsRequested();
      },
      productsOutput: function () {
        this.products = this.productsOutput && this.productsOutput.data && this.productsOutput.data.length ? this.productsOutput.data : [];
      }
    }
}
</script>

<style scoped>
.title {
  font-size: 25px;
}

.product-container {
  gap: 1rem;
}

>>>.content,
.product-container>div,
.product-container>span {
  width: 15vw;
  height: 15vw;
  margin-bottom: 2%;
}

>>>.overlay {
  visibility: hidden;
  transition: 1s ease;
  opacity: 0;
  background-color: hsla(1, 63%, 36%, 0);
}

.vueperslide__content-wrapper {
  flex: none !important;
  margin: 0 !important;
}

>>>.product-price h1 {
  font-size: 1.5rem !important;
}

>>>.product-price {
  display: none !important;
}

@media (max-width: 1399px) {

    >>>.content,
    .product-container>div,
    .product-container>span {
        width: 25vw;
        height: 25vw;
        margin-bottom: 5%;
    }
}

@media (max-width: 1199px) {

    >>>.content,
    .product-container>div,
    .product-container>span {
        width: 20vw;
        height: 20vw;
    }
}

@media (max-width: 991px)

/* Two columns */
    {

    >>>.content,
    .product-container>div,
    .product-container>span {
        width: 30vw;
        height: 30vw;
    }
}

@media (max-width: 575px)

/* one column */
    {

    >>>.content,
    .product-container>div,
    .product-container>span {
        width: 100%;
        height: 100%;
        margin-bottom: 10%
    }
}
</style>

<template>
    <div class="container-fluid p-0">
        <div class="d-flex justify-content-center align-items-center title background-color-primary">
            <h4 class="fw-bold">{{$t('whatToDo.title')}}</h4>
        </div>
        <!-- mobile -->
        <transition name="slide">
            <div class="row d-lg-none m-0 pt-4 gy-4 gy-lg-0">
                <div class="col-12 col-lg-4" v-for="(action,index) in whatWeDoList" :key="index">
                    <div @click="toggleClick(index)" class="card border-0">
                        <h2 v-show="mobileClick !== index" class="fw-bold text-center" v-html="action.type"></h2>
                        <div v-show="mobileClick == index" class="overlay slide-up">
                            <router-link class="text-dec-none d-flex justify-content-center align-items-center h-100 text-decoration-underline" :to="{ name: 'Pois', query: { type: action.query }}">
                                <span class="text-center">{{$t('whatToDo.see')}}</span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- desktop -->
        <div class="d-none d-lg-flex pt-4 gy-4 gy-lg-0" :style="{gap: '1.5%'}">
            <div class="w-100" v-for="action in whatWeDoList" :key="action.id">
                <router-link class="text-dec-none" :to="{ name: 'Pois', query: { type: action.query }}">
                    <div class="card border-0">
                        <h2 class="fw-bold text-center" v-html="action.type"></h2>
                        <div class="overlay cursor-pointer"></div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            whatWeDoList:[
                {id: 0, type: this.$t('whatToDo.oQueVisitar'), query: 1},
                {id: 1, type: this.$t('whatToDo.ondeComer'), query: 2},
                {id: 2, type: this.$t('whatToDo.ondeDormir'), query: 3},
            ],  
            mobileClick: -1,
        }
    },
    methods: {
        toggleClick(index) {
            if(this.mobileClick == index)
            {
                this.mobileClick = -1;
            }else{
                this.mobileClick = index;   
            }
        }
    },

}
</script>

<style scoped>
#card-image{
    background-image: url("../../assets/shop/tabuacoBG.svg");
    background-repeat: no-repeat;
}
a{
    color: black;
}
a:hover{
    color: black;
}
.title{
    height: 60px;
}
.card h2{
    z-index: 2;
    pointer-events: none;
}
.card{
    background-color: #E6E6E6;
    border-radius: unset;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;    
    z-index: 1;
}
.overlay:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: #DDB427;
    z-index: -1;
    transition: transform 0.5s;
    background-image: url("../../assets/staticLogo.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.slide-up {
  animation: 0.5s slide-up;
}
.slide-enter, .slide-leave-to {
   overflow: hidden;
} 
@keyframes slide-up {
  from {
    transform: scaleY(0);
    transform-origin: bottom center;
  }
  to {
    transform: scaleY(1);
    transform-origin: bottom center;
  }
}
@media (min-width: 992px) {
    .overlay:hover::after {
        transform: scaleY(1);
    }

}
@media (max-width: 992px) {
    .overlay::after {
        transform: scaleY(1);
    }
}

</style>
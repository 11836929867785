/* Imports */
import { API_HELPER } from "@/helpers/api.js";


/* Exports */
// API services that can be invoked on the modules
export const RESERVATIONS_SERVICE = {
    list,
    getDetails,
    reservationEmail
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Reservations";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: {"Content-Type":"application/json"},
    }; 

    // Process API call
    return API_HELPER.apiCall(url,queryParams, requestOptions);
}
function getDetails(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    
    console.log(url)

    const requestOptions = {
        method: 'GET',
    }; 

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}
function reservationEmail(content) {
   
    var raw = JSON.stringify(content);
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type":"application/json" },
        body: raw,
    };
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Email"]); 

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);    
}

import { ORDERS_SERVICE } from "@/services/orders.service.js";
// import router from '@/router'

const state = {
    listOrders: {},
    order: {},
    orderOutput: {},
    processTask: undefined
}

const actions = {

    getOrders({ dispatch, commit }, inputGetOrders) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        ORDERS_SERVICE.getOrders(inputGetOrders)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    createOrder({ dispatch, commit }, { billingAddress,shippingAddress,paymentMethod,iban, phoneNumber}) {
        commit('addRequest');

        ORDERS_SERVICE.createOrder(billingAddress,shippingAddress,paymentMethod,iban, phoneNumber)
        .then(
            output => {
                commit('addSuccess', output);
            },
            error => {
                commit('addFailure', error);
                dispatch('alert/error', error, { root: true }); 
            }
        );
    },
    getDetail({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('getOrderIdRequest');
        
        ORDERS_SERVICE.getDetail(id)
        .then(
            output => {
                commit('getOrderIdSuccess', output);
            },
            error => {
                    commit('getOrderIdFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
    
const mutations = {
     /* List */
    listRequest() {
        //
    },
    listSuccess(state, output) {
        state.listOrders = output;
    },
    listFailure(state) {
        state.listOrders = [];
    },
    //add
    addRequest(state) {
        state.order = null;
    },
    addSuccess(state, output) {
        state.order = output;
    },
    addFailure(state) {
        state.order = false;
    },
    /* getOrderId */
    getOrderIdRequest(state) {
        state.orderOutput = null;
        state.processTask = null;
    },
    getOrderIdSuccess(state, output) {
        state.orderOutput = output;
        state.processTask = true;
    },
    getOrderIdFailure(state) {
        state.orderOutput = false;
        state.processTask = false;
    },
}; 
    

export const orders = {
    namespaced: true,
    state,
    actions,
    mutations
};
<template>
<div>
    <div class="container">
      <div class="row my-4 justify-content-between align-items-center">
          <div class="col-12 title">
              <h1 class="align-self-center fw-bold">{{$t('gallery.title')}}</h1>
          </div>
          <hr>
          <!-- <div id="category-filter-container" class="col-12 col-lg-8 categories align-items-center">
              <CategoryFilter v-if="type" @change="onCategoriesChanged" :type="type"/>
          </div> -->
      </div>
    </div>
    <!-- Loading -->
    <LogoLoading v-if="loading"/>
    <!-- Content -->
    <div v-else class="photo-container container d-flex flex-wrap mt-2 p-0">
      <GalleryThumb @showImage="showImage(index)" class="slider-thumb box" v-for="(content, index) in photos" :key="index" :galleryContent="content"/>      
      <NoResultsPlaceholder v-if="!photos.length"></NoResultsPlaceholder>
    </div>
    <div v-show="!loading && canLoadMore" class="see">
      <div class="col-12 d-flex justify-content-center">
        <img @click="loadMore" class="d-block text-center cursor-pointer" src="@/assets/showMore.svg" alt="" loading="lazy">
      </div>
      <div class="col-12 d-flex justify-content-center">
        <p @click="loadMore" class="d-block text-center">{{$t("showMore")}}</p>
      </div>
    </div>
    <FullScreenFile v-if="showImageIndex != null" :startIndex="showImageIndex" @closeRequest="showImageIndex = null" :gallery="photos"></FullScreenFile>
</div>
</template>

<script>
import GalleryThumb from "@/components/GalleryThumb.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import LogoLoading from "@/components/LogoLoading.vue"
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import FullScreenFile from "@/components/FullScreenFile.vue";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  components:{GalleryThumb,  NoResultsPlaceholder, LogoLoading, FullScreenFile},
    data(){
      return{
        type: null,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        title: '',
        showImageIndex: null
      }
    },
    metaInfo () {
      return { 
            title: `${this.$t("vueRouter.galleryTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.galleryDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.gallery", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.gallery", 'pt')])},
            ]
            
        } 
    },
    mounted: function()
    {
      this.initializePage();
    },
    methods:
    {
      ...mapActions({
          getGallery: 'gallery/getList',
      }),
      initializePage: function()
      {
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";
        
        //Make request
        var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);

        this.getGallery(input); 
      },
      loadMore()
      {
        if(this.canLoadMore)
        {
            this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
        }
      },
      showImage: function(index)
      {
        this.showImageIndex = index;
      },  
    },
    computed:
    {
      ...mapState({ 
          galleryOutput: state => state.gallery.listOutput,
      }),
      loading: function(){
          return this.galleryOutput == null;
      },
      photos: function() {
          return this.galleryOutput && this.galleryOutput.data ? this.galleryOutput.data : [];
      },
      numPhotos: function(){
          return this.galleryOutput && this.galleryOutput.totalRecords ? this.galleryOutput.totalRecords : 0;
      },
      canLoadMore: function()
      {
          return this.numPhotos > this.itemsPerPage;
      },
    },
    watch: 
    {
      $route(to) {
          if (to.name == "Gallery") {
              this.initializePage();
          }
      },
      itemsPerPage()
      {
          this.initializePage();
      }
    }
}
</script>

<style scoped>
/* Title */
h1{
    font-size: 3rem!important;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
    font-weight: bold !important;
}
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
.see > p {
  margin-top: 1rem;
  cursor: pointer;
}
.skeleton-box
{
  min-height: 245px;
}

/* Size of poi elements: these are not done using bootstrap to have correct alignments, using gap */
.photo-container /* Four columns */
{
  gap: 2%;
}
.photo-container > .slider-thumb,
.photo-container > span
{
  width: 22%;
  height: 245px;
  margin-bottom: 2%;
  flex-grow: 1;
  max-width: 310px;
}

@media (max-width: 1199px) 
{
  .photo-container
  {
    gap: 5%;
    padding: 0 1rem;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}
/* Header bar */
@media (max-width: 767px) /* Two columns */
{
  .photo-container
  {
    gap: 10%;
    padding: 0 1rem;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}

@media (max-width: 575px) /* Two columns */
{
    h1{
        font-size: 2rem!important;
    }
  .photo-container
  {
    padding: 0 1rem;
    justify-content: center;
  }

  .photo-container > .slider-thumb,
  .photo-container > span
  {
    width: 95%;
    max-width: 95%;
    margin-bottom: 3%;
  }
  .title h2{
   font-size: 20px;
  }
}
</style>
<template>
<section>
    <LogoLoading v-if="loading"/>
    <div v-show="!loading">
      <div class="home">
        <Hero/>
        <hr class="container">
        <News :title="$t('news.title')"/>
        <hr class="container">
        <WhatToDo/>
        <hr class="container">
        <Events :title="$t('events.title')"/>
        <hr class="container">
        <Map/>
        <hr class="container">
        <StoreSlider :title="$t('shop.featuredTitle')" />
        <hr class="container">
        <VisualGallery/>
        <Contact/>
      </div>
    </div>
</section>
</template>

<script>
import Vue from 'vue';
// @ is an alias to /src
import Hero from '@/components/HomePage/Hero.vue';
import News from '@/components/HomePage/News.vue';
import Events from '@/components/HomePage/Events.vue';
import WhatToDo from '@/components/HomePage/WhatToDo.vue';
import Map from '@/components/HomePage/map.vue';
import StoreSlider from '@/components/HomePage/StoreSlider.vue';
import VisualGallery from "@/components/HomePage/VisualGallery.vue";
import Contact from "@/components/HomePage/Contact.vue";

import { mapState } from 'vuex';

import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  name: 'Home',
  metaInfo () {
    return { 
      title: 'Tabuaço',
      htmlAttrs: {
          lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: 'Tabuaço'},
        {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: 'Tabuaço'},
        {property: 'og:site_name', content: 'Tabuaço'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
        {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL_ROOT},
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL_ROOT},
      ]
    } 
  }, 
  created() {
    this.$vueEventBus.$on('hash-changed', this.processHash)
  },
  beforeDestroy() {
    this.$vueEventBus.$off('hash-changed')
  },
  components: {
    Hero,
    News,
    Events,
    WhatToDo,
    Map,
    StoreSlider,
    VisualGallery,
    Contact,
    LogoLoading
  },
  methods:
  {
    processHash: function()
    {
      var elemnt = document.getElementById("support");
      // Execute after all images have loaded (done because we had problems in some browsers)
      if(elemnt && Vue.prototype.$hash == "support") 
      {
        Vue.prototype.$hash = null;
        Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
          // elemnt.scrollIntoView()
          window.scrollTo(0, elemnt.offsetTop);
                });
      }
    }
  },
  computed:{
     ...mapState({ 
      herosOutput: state => state.hero.listOutput,
      newsOutput: state => state.news.listOutput,
      eventsOutput: state => state.events.listOutput,
      productsOutput: state => state.products.featuredOutput,
      galleryOutput: state => state.gallery.listOutput,
    }),
    loading: function()
    {
      return this.herosOutput == null || this.newsOutput == null || this.eventsOutput == null || this.productsOutput == null || this.galleryOutput == null;
    },
  },
  mounted: function()
  {
    if(!Vue.prototype.$hash)
    {
      setTimeout(function () {
        window.scrollTo(0, 0);
      },100);
    }
  },
  watch:
  {
    loading: function(val)
    {
      if(!val)
      {
        this.processHash();
      }
    },
  }
}
</script>
<style scoped>
hr{
  margin: 1rem auto;
}
</style>

<template>
    <div id="events">
        <div class="container">
            <div class="title d-flex justify-content-end">
                <div class="col-6 text-center title">
                    <h4 class="fw-bold">{{title}}</h4>
                </div>
                <div class="col-6 col-lg-3 d-flex justify-content-end align-items-center pr-5">
                    <span class="px-3">{{$t('viewAll')}}</span>
                    <img @click="sendClicked" class="cursor-pointer" src="@/assets/svg/view-all-icon.svg" alt="" loading="lazy">
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-12 col-lg-4 py-2" v-for="event in eventsList" :key="event.id">
                    <EventsThumb :eventContent="event" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventsThumb from '@/components/HomePage/EventsThumb.vue';
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";

export default {
    props: {
        title: String
    },
    components: {
        EventsThumb,
    },
    data() {
        return {
        }
    },
    mounted() 
    {
        this.initializePage();
    },
    computed: {
        ...mapState({ 
            eventsOutput: state => state.events.listOutput,
        }),
        eventsList() {
            return this.eventsOutput && this.eventsOutput.data ? this.eventsOutput.data: [];
        }
    },
    methods: {
        ...mapActions('events', ['getList']),

        initializePage(){
            // GET list of last 3 events
            const itemsPerPage = 3;
            const currentPage = 1;
            const sorter = 2; // descending
            const propertyName = "id";
            const searchText = '';
            
            //Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            
            this.getList(input); 
        },
        sendClicked () {
            this.$router.push({name: "Events"});
        },

    },
}
</script>

<style scoped>
    @media (max-width: 991px) {
        .title{
            text-align: left!important;
        }
    }
</style>
<!-- Parent control for all pop ups. -->
<template>
    <div>
        <!-- Vertically centered modal -->
        <div class="modal d-flex justify-content-center align-items-center">
            <div class="modal-container d-flex flex-column justify-content-center align-items-center text-center">
                <div class="modal-content" data-aos="fade-down">
                    <div class="modal-header d-flex">
                        <h5 class="modal-title" id="exampleModalLabel">{{modelTitle}}</h5>
                        <button type="button" class="btn-close" @click="closePopUp"></button>
                    </div>
                    <div class="modal-body">
                        <slot name="content">
                            <!-- Content goes here -->
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <!-- buttons goes here -->
                        </slot>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
      return {  
        drag: false
      }
    },
    props:
    {
        modelTitle: null,
    },
    methods: {   
        closePopUp()
        {
            this.$emit("detailsClosed");
        },   
        handleMouseUp(evt) {
            if (!this.drag && evt.target.classList.contains('modal')) {
                this.closePopUp();
            }

            this.drag = false;
        }
    },
    mounted() {
        document.addEventListener('mousedown', () => this.drag = false);
        document.addEventListener('mousemove', () => this.drag = true);
        document.addEventListener('mouseup', this.handleMouseUp);
    },
    destroyed() {
        document.removeEventListener('mousedown', () => this.drag = false);
        document.removeEventListener('mousemove', () => this.drag = true);
        document.removeEventListener('mouseup', this.handleMouseUp);
    },
};
</script>

<style scoped>
.row{
    margin: 0;
    padding: 0;
}
.modal
{
    background-color: rgba(0, 0, 0, 0.6);
}
.modal-container
{
    max-width: 100%;
}
.modal-content{
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: .3125em;
    background: #fff;
    font-size: 1rem;
}
.modal-header{
    display: block;
    flex-shrink: 0;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}
.btn-close:focus{
    box-shadow: none;
}
.modal-body{
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}
.modal-footer{
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
</style>
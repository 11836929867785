import { render, staticRenderFns } from "./FeaturedSlider.vue?vue&type=template&id=4200f058&scoped=true&"
import script from "./FeaturedSlider.vue?vue&type=script&lang=js&"
export * from "./FeaturedSlider.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedSlider.vue?vue&type=style&index=0&id=4200f058&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4200f058",
  null
  
)

export default component.exports
/* Imports */
import { API_HELPER } from "@/helpers/api.js";
// import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";



/* Exports */
// API services that can be invoked on the modules
export const ORDERS_SERVICE = {
    getOrders,
    createOrder,
    getDetail
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Orders"

/* Services */
function getOrders(inputGetOrders)
{
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);

    const requestOptions = {
        method: 'GET',
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, inputGetOrders, requestOptions);

}

function createOrder(billingAddress,shippingAddress,paymentMethod, iban, phoneNumber)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify({"billingAddress": billingAddress, "shippingAddress": shippingAddress, "paymentMethod": paymentMethod, "iban": iban, "phoneNumber":phoneNumber});
    
    const requestOptions = {
        method: 'POST',
        headers:  {  "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

function getDetail(id)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, id]);    

    const requestOptions = {
        method: 'GET',
        headers:  {  "Content-Type":"application/json"},
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}

<template>
    <!-- Based on Vue-Multiselect -->
    <multiselect ref="uniselect" v-model="selectedItem" :options="options" :multiple="false" :close-on-select="true" :clear-on-select="false" :preselect-first="false" :allow-empty="false" label="name" track-by="optionId" :showLabels="false" :searchable="false">
        <!-- Add radio buttons -->
        <template slot="option" slot-scope="props">
            <div class="option__desc d-flex align-items-center cursor-pointer">
                <input type="radio" class="option__title cursor-pointer" :checked="selectedItem && selectedItem.optionId == props.option.optionId"/>
                <span>{{ props.option.name }}</span>
            </div>
        </template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: {
        Multiselect
    },
    props: {
        value: Object,
        options: Array,
    },
    data () {
        return {
            selectedItem: null,
        }
    },
    watch: {
        selectedItem: function()
        {
            if(this.selectedItem)
            {
                this.$emit('input', this.selectedItem);
            }
        },
        options: function()
        {
            this.selectedItem = this.value ? this.value : null;
        },
        value: function(val)
        {  
            this.selectedItem = val;
        }
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.apply-button
{
    background-color: black;
    border: none;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}
input[type='radio']
{
    margin-right: 0.5rem;
}
input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 15px;
  height: 15px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid black;
  background-color: white;
  border-radius: 50%;
}
/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: black;
}
/deep/.multiselect__tags {
    min-height: 30px!important;
    border-radius: 0px!important;
    height: 30px!important;
    padding: 3px 0 3px 10px!important;
}
/deep/.multiselect__content
{
    padding: 6px 12px;
}
/deep/.multiselect__option
{
    line-height: 36px;
    padding: unset;
    min-height: unset;
}
/deep/.multiselect__option--highlight
{
    background: none;
    color: unset;
}

/deep/.multiselect__option--selected
{
    background: unset;
    font-weight: normal;
}

/deep/.multiselect__tags
{
    border: 1px solid black;
}
/deep/.multiselect__select
{
    height: 30px!important;
}
/deep/.multiselect__select:before
{
    color: black;
    border-color: black transparent;
}

/deep/.multiselect__placeholder
{
    display: block !important;
    margin-bottom: 0px!important;
    padding-top: 0px!important;
}
/deep/.multiselect__placeholder,
/deep/.multiselect__tags-wrap
{
    color: black;
}

/deep/.multiselect__content-wrapper
{
    width: max-content;
    min-width: 100%;
}

</style>
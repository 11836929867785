<template>
  <section>
    <LogoLoading v-if="!reservationOutput"/>
      <div v-else class="container-fluid" v-for="(reservation, index) in reservationList.filter((x) => { return x.imagePath; })" :key="index">
        <BaseReservation 
          v-model="reservationInfo" 
          :imageUrl="(reservation.imagePath ? baseImageUrl + reservation.imagePath : null)"
          :title="reservation.title" 
          :flexDirection="index % 2 === 0 ? 'row-reverse' : 'row'"
          :titleRetangle="reservation.title"
          :textRetangle="reservation.content"
          :callToAction="reservation.callToAction"
        >
          <template v-slot:slotContent>
            <!-- If Qr and app links = true -->
            <div v-if="reservation.storeLinks">
              <p class="text-center font-clanpro mt-2">{{$t("reservation.downloadApp")}}</p>
              <div class="row square-divider">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="col-12 text-center">
                    <img src="@/assets/reservation/googlePlay.svg" alt="" loading="lazy">
                  </div>
                  <div class="col-12 text-center mb-3">
                    <img src="@/assets/reservation/googlePlayQR.svg" alt="" loading="lazy">
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="col-12 text-center">
                    <img src="@/assets/reservation/appleStore.svg" alt="" loading="lazy">
                  </div>
                  <div class="col-12 text-center mb-3">
                    <img src="@/assets/reservation/appleStoreQR.svg" alt="" loading="lazy">
                  </div>
                </div>
              </div>
              <p class="text-center font-clanpro mt-2">{{$t("reservation.downloadRoutes")}}</p>
              <div class="row" :class="[(reservation.form ? 'square-divider' : '')]" v-for="file in reservation.filesPath" :key="file">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="col-12 text-center">
                    <a :href="basePDFPath + '/' + file" target="_blank" class="cursor-pointer">
                      <img src="@/assets/reservation/Pdf-icon.svg" alt="" loading="lazy">
                    </a>
                  </div>
                  <div class="col-12 text-center mb-3">
                    <span class="p-style">{{$t("reservation.path") }}</span><span class="mx-2">{{+ [index]}}</span>
                    <br>
                    <span class="p-style">{{"PR"}}</span><span class="mx-2">{{+ [index]}}</span>
                  </div>
                </div>
              </div>
            </div>   
            <!-- If Form = true   -->
            <div v-if="reservation.form">
              <form action="" @submit.prevent="handleMessageSubmit(reservation.id)">
                <p class="text-center font-clanpro mt-2">{{$t("reservation.setVisit")}}</p>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 text-center my-2">
                      <center><label class="font-clanpro" for="date">{{$t("reservation.defineDate")}}</label></center>
                      <input class="form-control font-clanpro" type="date" :min="minDate" v-model="date" :class="{ 'is-invalid': submitted && hasError('Date') }">
                      <div v-if="submitted && hasError('Date')" class="invalid-feedback">
                          <span v-if="!$v.date.required">{{$t("reservation.valDate")}}</span>
                          <br v-if="!$v.date.required && !$v.date.minValue">
                          <span v-if="!$v.date.minValue">{{$t("reservation.minDate")}}</span>
                          <span v-if="errors.Date">{{errors.Date}}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-center my-2">
                      <center><label class="font-clanpro" for="numPerson">{{$t("reservation.numPerson")}}</label></center>
                      <input class="form-control font-clanpro" type="number" v-model="peopleNumber" :class="{ 'is-invalid': submitted && hasError('PeopleNumber') }">
                      <div v-if="submitted && hasError('PeopleNumber')" class="invalid-feedback">
                          <span v-if="!$v.peopleNumber.required">{{$t("reservation.valNumPerson")}}</span>
                          <br v-if="!$v.peopleNumber.required && errors.PeopleNumber">
                          <span v-if="errors.PeopleNumber">{{errors.PeopleNumber}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <center><label class="font-clanpro" for="Name">{{$t("reservation.name")}}</label></center>
                      <input class="form-control font-clanpro" type="text" v-model="name" :class="{ 'is-invalid': submitted && hasError('Name') }">
                      <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                          <span v-if="!$v.name.required">{{$t("reservation.valName")}}</span>
                          <span v-if="errors.Name">{{errors.Name}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <center><label class="font-clanpro" for="Email">{{$t("reservation.email")}}</label></center>
                      <input class="form-control font-clanpro" type="text" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }">
                      <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                          <span v-if="!$v.email.required">{{$t("reservation.valEmail")}}</span>
                          <span v-if="!$v.email.email">{{ $t("reservation.invEmail") }}</span>
                          <span v-if="errors.Email">{{errors.Email}}</span>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <center><label class="font-clanpro" for="otherInfo">{{$t("reservation.otherInfo")}}</label></center>
                      <textarea class="form-control font-clanpro" type="text" v-model="content" rows="3" id="otherInfo" name="message"></textarea>
                    </div>
                    <div class="col-12 mb-3 text-center">
                      <button class="button font-clanpro" id="sendBtn" v-bind:class="{disabled: loading}">
                        <span v-show="!loading">{{$t("reservation.sendRequest")}}</span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>      
          </template>
        </BaseReservation>
      </div>

<!-- No Image -->
      <div class="d-flex flex-wrap">
      <div class="container-fluid" :class="(index === noImageReservations.length - 1 && noImageReservations.length % 2 != 0 ? 'col-sm-12' : 'col-sm-6')" v-for="(reservation, index) in noImageReservations" :key="reservation.name">
        <BaseReservation 
          v-model="reservationInfo" 
          :imageUrl="(reservation.imagePath ? baseImageUrl + reservation.imagePath : null)"
          :title="reservation.title" 
          :flexDirection="index % 2 === 0 ? 'row-reverse' : 'row'"
          :titleRetangle="reservation.title"
          :textRetangle="reservation.content"
          :callToAction="reservation.callToAction"
        >
          <template v-slot:slotContent>
            <div v-if="reservation.storeLinks">
              <p class="text-center font-clanpro mt-2">{{$t("reservation.downloadApp")}}</p>
              <div class="row square-divider">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="col-12 text-center">
                    <a href="/">
                      <img src="@/assets/reservation/googlePlay.svg" alt="" loading="lazy">
                    </a>
                  </div>
                  <div class="col-12 text-center mb-3"> 
                    <img src="@/assets/reservation/googlePlayQR.svg" alt="" loading="lazy">
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div class="col-12 text-center">
                    <a href="/">
                      <img src="@/assets/reservation/appleStore.svg" alt="" loading="lazy">
                    </a>
                  </div>
                  <div class="col-12 text-center mb-3">
                    <img src="@/assets/reservation/appleStoreQR.svg" alt="" loading="lazy">
                  </div>
                </div>
              </div>
              <p class="text-center font-clanpro mt-2">{{$t("reservation.downloadRoutes")}}</p>
              <div class="row" :class="[(reservation.form ? 'square-divider' : '')]" v-for="(file, index) in reservation.filesPath" :key="index">
                <div class="col-sm-12 col-md-12" :class="(index === reservation.filesPath.length - 1 && reservation.filesPath.length % 2 != 0 ? 'col-lg-12' : 'col-lg-6')">
                  <div class="col-12 text-center">
                    <a :href="basePDFPath + '/' + file" target="_blank" class="cursor-pointer">
                      <img src="@/assets/reservation/Pdf-icon.svg" alt="" loading="lazy">
                    </a>
                  </div>
                  <div class="col-12 text-center mb-3">
                    <span class="p-style">{{$t("reservation.path") }}</span><span class="mx-2">{{+ [index]}}</span>
                    <br>
                    <span class="p-style">{{"PR"}}</span><span class="mx-2">{{+ [index]}}</span>
                  </div>
                </div>
              </div>
            </div>      
            <div v-if="reservation.form">
              <form action="" @submit.prevent="handleMessageSubmit(reservation.id)">
                <p class="text-center font-clanpro mt-2">{{$t("reservation.setVisit")}}</p>
                <div class="container">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-6 text-center my-2">
                      <center><label class="font-clanpro" for="date">{{$t("reservation.defineDate")}}</label></center>
                      <input class="form-control font-clanpro" :min="minDate" type="date" v-model="date" :class="{ 'is-invalid': submitted && hasError('Date') }">
                      <div v-if="submitted && hasError('Date')" class="invalid-feedback">
                          <span v-if="!$v.date.required">{{$t("reservation.valDate")}}</span>
                          <br v-if="!$v.date.required && !$v.date.minValue">
                          <span v-if="!$v.date.minValue">{{$t("reservation.minDate")}}</span>
                          <span v-if="errors.Date">{{errors.Date}}</span>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 text-center my-2">
                      <center><label class="font-clanpro" for="numPerson">{{$t("reservation.numPerson")}}</label></center>
                      <input class="form-control font-clanpro" type="number" v-model="peopleNumber" :class="{ 'is-invalid': submitted && hasError('PeopleNumber') }">
                      <div v-if="submitted && hasError('PeopleNumber')" class="invalid-feedback">
                          <span v-if="!$v.peopleNumber.required">{{$t("reservation.valNumPerson")}}</span>
                          <br v-if="!$v.peopleNumber.required && errors.PeopleNumber">
                          <span v-if="errors.PeopleNumber">{{errors.PeopleNumber}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <center><label class="font-clanpro" for="Name">{{$t("reservation.name")}}</label></center>
                      <input class="form-control font-clanpro" type="text" v-model="name" :class="{ 'is-invalid': submitted && hasError('Name') }">
                      <div v-if="submitted && hasError('Name')" class="invalid-feedback">
                          <span v-if="!$v.name.required">{{$t("reservation.valName")}}</span>
                          <span v-if="errors.Name">{{errors.Name}}</span>
                      </div>
                    </div>
                    <div class="col-12">
                      <center><label class="font-clanpro" for="Email">{{$t("reservation.email")}}</label></center>
                      <input class="form-control font-clanpro" type="text" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }">
                      <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                          <span v-if="!$v.email.required">{{$t("reservation.valEmail")}}</span>
                          <span v-if="!$v.email.email">{{ $t("reservation.invEmail") }}</span>
                          <span v-if="errors.Email">{{errors.Email}}</span>
                      </div>
                    </div>
                    <div class="col-12 mb-3">
                      <center><label class="font-clanpro" for="otherInfo">{{$t("reservation.otherInfo")}}</label></center>
                      <textarea class="form-control font-clanpro" type="text" v-model="content" rows="3" id="otherInfo" name="message"></textarea>
                    </div>
                    <div class="col-12 mb-3 text-center">
                      <button class="button font-clanpro" id="sendBtn" v-bind:class="{disabled: loading}">
                        <span v-show="!loading">{{$t("reservation.sendRequest")}}</span>
                        <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                        <span v-show="loading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>      
          </template>
        </BaseReservation>
        </div>
      </div>
  </section>
</template>

<script>
import BaseReservation from "@/components/BaseReservation.vue";
import InputGetList from "@/classes/InputGetList";
import { mapState, mapActions } from 'vuex';
import LogoLoading from "@/components/LogoLoading.vue"
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";  
import { API_HELPER } from "@/helpers/api.js";

export default {
  extends: BaseReservation,
  components:{BaseReservation, LogoLoading},
     metaInfo () {
    return { 
          title: this.$t("vueRouter.ReservationsTitle"), 
          titleTemplate: '%s - Tabuaço', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRouter.ReservationsDescription")},
            {name: 'keywords', content: this.reservationList && this.reservationList.length ? this.reservationList.map(x=> x.title).join(", ") : this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRouter.ReservationsTitle")},
            {name: 'twitter:description', content: this.$t("vueRouter.ReservationsDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRouter.ReservationsTitle")},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.$t("vueRouter.ReservationsDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Reservations", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Reservations", 'en')])}
          ]
      } 
  }, 
  data: function() {
    return{
      reservationInfo: false,
      submitted: false,
      loading: false,
      date:"",
      peopleNumber: "",
      name: "",
      email: "",
      content: "",
      minDate: null
    }
  },
   validations: {
     date: { required,
            minValue(val) {
              return new Date(val) > new Date();
            }, 
          } ,
     peopleNumber: { required } ,
     name: { required } ,
     email: { email, required}
    },
  mounted() 
    {
      // set min date
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDate = date.toISOString().substr(0, 10);

      this.updateItemsRequested();
    },
    computed: {
        ...mapState("reservations", ["processTask"]),
        ...mapState({ 
            reservationOutput: state => state.reservations.listOutput,
        }),
        ...mapState({ 
          alertType: state => state.alert.type,
          alertOutput: state => state.alert.output
        }),
        reservationList() { 
          return this.reservationOutput && this.reservationOutput.data ? this.reservationOutput.data: [];
        },
        basePDFPath: function()
        {
          return process.env.VUE_APP_URL_ROOT;
        },
        baseImageUrl(){
          return process.env.VUE_APP_URL_ROOT;
        },
        noImageReservations(){
          return this.reservationList.filter((x) => { return !x.imagePath; })
        },
        errors: function()
        {
          return this.alertOutput && this.alertOutput.errors ? this.alertOutput.errors : {};   
        },
    },
    methods:{
      ...mapActions('reservations', ['getList']),
      ...mapActions('reservations', ['reservationEmail']),
        ...mapActions({
          clearAlert: 'alert/clear' 
      }),

      updateItemsRequested(){
          const itemsPerPage = 10;
          const currentPage = 1;
          const sorter = 2; // descending
          const propertyName = "id";
          const searchText = '';
          
          //Make request
          var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
          
          this.getList(input); 
      },
      handleMessageSubmit(reservationId) {
        this.clearAlert();
        this.submitted = true;

        //stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        
        this.loading = true;
        const { date, peopleNumber, name, email, content } = this;
        this.reservationEmail({ reservationId, date, peopleNumber, name, email, content });
      },
      resetForm() {  
        this.submitted = false;
        this.date = "";
        this.peopleNumber = "";
        this.name = "";
        this.email = "";
        this.content = "";
      },
      hasError(fieldName)
      {
        return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
      },
    },
    watch:{
      action: function()
      {
        this.submitted = false;
      },
      alertType: function(val)
      {
        if(val != null)
        {
          this.loading = false;

          if(val == "alert-danger")
          {
            this.$emit("error-alert-requested", { message: this.$t("reservation.popupError"), primaryButton: this.$t("closePopUp")});
          }
          else if(val == "alert-success")
          {
            this.resetForm();
            this.$emit("success-alert-requested", { message: this.$t("reservation.popupSuccess"), primaryButton: this.$t("closePopUp")});
          }
        }
      }
  }
}
</script>

<style scoped>
/* .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
  padding-left: 30px !important;
  padding-right: 30px !important;
} */
.div-style{
  background-color: #cfcbcb;
  height: 439px;
}
#button{
  background-color: transparent !important;
}
#sendBtn{
  background-color: #DBCEA4 !important;
  width: 70%;
}
.image-style{
  object-fit: cover;
  width: 100%;
  height: 439px;
}
.row-spaces{
  margin: 20px 0;
}
.font-clanpro{
  font-size: 13px;
  font-weight: bold;
}
.square-divider{
  border-bottom: 1px solid black;
  margin: 20px 0;
}
.p-style{
  margin-bottom: 0 !important;
}
</style>
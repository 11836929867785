<template>
    <BasePopUp @detailsClosed="closeConfirmRequested" :modelTitle="title">
            <template v-slot:content>
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <b-icon class="py-2" :icon="icon"></b-icon>
                </div>
                <h6 class="text-center mb-0 py-2" v-html="message"/>
            </template>
            <template v-slot:footer>
                <button class="btn btn-success border-0 mx-2 px-3" @click="primaryButtonClicked">{{primaryButtonText}}</button>
                <button class="btn btn-error border-0 mx-2 px-3" @click="secondaryButtonClicked" v-if="secondaryButtonText">{{secondaryButtonText}}</button>
            </template>
    </BasePopUp>
</template>

<script>
const SUCCESS = 0;
const WARNING = 1;
const ERROR = 2;

import i18n from '@/helpers/i18n';
import BasePopUp from './BasePopUp.vue';

export default {
    components: {
        BasePopUp,
    },
    props: {
        title: String,
        message: String,
        primaryButtonText:
        {
            type: String,
            default: i18n.t("yesBtn")
        },
        primaryButtonCallback: { type: Function },
        secondaryButtonText:
        {
            type: String,
            default: null
        },
        secondaryButtonCallback: { type: Function },
        type: 
        {
            type: Number,
            default: SUCCESS
        }
    },
    methods:
    {
        primaryButtonClicked: function()
        {
            this.$emit("primary-click");
            if(this.primaryButtonCallback) this.primaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        secondaryButtonClicked: function()
        {
            this.$emit("secondary-click");
            if(this.secondaryButtonCallback) this.secondaryButtonCallback();

            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
        closeConfirmRequested()
        {       
            this.$destroy();
            this.$el.parentNode.removeChild(this.$el);
        },
    },
    computed: {
        icon: function()
        {
            var result = null;

            switch(this.type)
            {
                case SUCCESS:
                {
                    result = "check-circle";
                    break;
                }
                case WARNING:
                {
                    result = "exclamation-circle";
                    break;
                }
                case ERROR:
                {
                    result = "x-circle";
                    break;
                }
                default:
                {
                    throw("Type is not supported");
                }
            }
            return result;
        }
    }
}
</script>

<style scoped>
.btn-success{
    background-color: #DDB427 !important;
}
.btn-error{
    background-color: red !important;
    color: white;
}
.bi-exclamation-circle,
.bi-check-circle,
.bi-x-circle{
    font-size: 6rem;
}
.bi-exclamation-circle
{
    color: #f8bb86;
}
.bi-check-circle
{
    color: #a5dc86;
}
.bi-x-circle
{
    color: #F27474;
}
h6{
    font-family: 'clanpro-news'
}
.btn:hover{
    color: white;
}
</style>
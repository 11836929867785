<template>
    <div class="vueperslides__track-inner">
        <div class="vueperslide" :style="{ backgroundImage: 'url(' + imageUrl + ')' }">
            <div class="vueperslide__content-wrapper">
                <div class="vueperslide__title">
                    <h1>{{$t('banner.title')}}</h1>
                </div>
                <div class="mt-4">
                    <a @click="sendClicked" class="btn-border-white text-dec-none">{{$t('banner.buyNow')}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
    props: {
        bannerContent: {
            type: Object
        }
    },
    computed:{
        imageUrl: function()
        {
            var relativePath = this.bannerContent && this.bannerContent.featuredImagePath ? this.bannerContent.featuredImagePath : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
    },
    methods: {
        sendClicked () {
            this.$router.push({name: "ProductDetail", params: {slug: this.bannerContent.slug} });
        },
    },
}
</script>

<style scoped>
.vueperslide__title h1{
    font-size: 3rem;
    color: white;
    font-weight: 900;
    text-shadow: 2px 0 5px #000;
    padding: 0 30rem;
}
.btn-border-white
{
  display: inline-flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  height: 45px;
  font-weight: 900;
  padding: 0 80px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 2px solid #fff;
  color: #fff;
  background-color: rgba(0,0,0,.5);
}
.btn-border-white:hover {
  color: white;
  background-color: black;
  border: 2px solid black;
}
@media (max-width: 1400px) {
    .vueperslide__title h1{
        padding: 0;
    }
    .btn-border-white
    {
        padding: 0 10px;
    }
}
@media (max-width: 576px) {
    .vueperslide__title h1{
        font-size: 1rem;
    }
    .btn-border-white
    {
        font-size: 1rem;
    }
}
</style>
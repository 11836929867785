import { FAVORITES_SERVICE } from "@/services/favorites.service.js";

const state = {listOutput: {}, addTask: undefined, removeTask: undefined}

const actions = {
    getList({ dispatch, commit }, inputGetFavorites) {

        dispatch('alert/clear', null, { root: true });
        commit('listRequest');

        FAVORITES_SERVICE.list(inputGetFavorites)
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
},
    addFavorite({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('addRequest');

        FAVORITES_SERVICE.add(slug)
            .then(
                output => {
                    commit('addSuccess', output);
                },
                error => {
                    commit('addFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );   
    },

    removeFavorite({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('removeRequest');

        FAVORITES_SERVICE.remove(slug)
            .then(
                output => {
                    commit('removeSuccess', output);
                },
                error => {
                    commit('removeFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Add */
    addRequest(state) {
        state.addTask = null;
    },
    addSuccess(state, output) {
        state.addTask = true;
        state.listOutput = output;
    },
    addFailure(state) {
        state.addTask = false;
        state.listOutput = false;
    },
    /* Remove */
    removeRequest(state) {
        state.removeTask = null;
    },
    removeSuccess(state, output) {
        state.removeTask = true;
        state.listOutput = output;
    },
    removeFailure(state) {
        state.removeTask = false;
        state.listOutput = false;
    },   
};

export const favorites = {
    namespaced: true,
    state,
    actions,
    mutations
};
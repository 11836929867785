import { POIS_SERVICE } from "@/services/pois.service.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

const state = { listOutput: null, groupedListOutput: [], poiOutput: null};

const actions = {
    getList({ dispatch, commit }, inputGetPois) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        POIS_SERVICE.list(inputGetPois)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getGroupedList({ dispatch, commit }, inputGetPois) {
        dispatch('alert/clear', null, { root: true });

        if(!inputGetPois.type || inputGetPois.type.length > 1)
        {
            throw "The grouped list get should be used to obtain POIs from a single category type";
        }
        var type = inputGetPois.type[0];
        commit('groupListRequest', type);
        
        POIS_SERVICE.list(inputGetPois)
            .then(
                output => {
                    commit('groupListSuccess', { output, type });
                },
                error => {
                    commit('groupListFailure', type);
                    dispatch('alert/error', error, { root: true });
                }
            );

    },
    getDetail({ dispatch, commit }, poisId) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        POIS_SERVICE.get(poisId)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Group List Problem with watch -> Used because vue has no idea that it has changed */
    groupListRequest(state, type) {
        state.groupedListOutput[type] = null;
        state.groupedListOutput = UTILS_HELPER.deepClone(new Array, state.groupedListOutput);  
    },
    groupListSuccess(state, {type, output}) {
        state.groupedListOutput[type] = output;
        state.groupedListOutput = UTILS_HELPER.deepClone(new Array, state.groupedListOutput);  
    },
    groupListFailure(state, type) {
        state.groupedListOutput[type] = false;
        state.groupedListOutput = UTILS_HELPER.deepClone(new Array, state.groupedListOutput);  
    },
    /* Detail */
    detailRequest(state) {
        state.poiOutput = null;
    },
    detailSuccess(state, output) {
        state.poiOutput = output;
    },
    detailFailure(state) {
        state.poiOutput = false;
    },
};
export const pois = {
    namespaced: true,
    state,
    actions,
    mutations
};
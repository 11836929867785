/* Imports */
import { API_HELPER } from "@/helpers/api.js";


/* Exports */
// API services that can be invoked on the modules
export const PRODUCT_SERVICE = {
    list,
    getDetail,
    getBannerList,
    getRelated,
    getReviews
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "products";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: {"Content-Type":"application/json"},
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}
// Service for product details without logged
function getDetail(slug)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]);    

    const requestOptions = {
        method: 'GET',
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
function getBannerList()
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, "Banner"]);    

    const requestOptions = {
        method: 'GET',
        headers: {"Content-Type":"application/json"},
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
function getRelated(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'Related']);    

    const requestOptions = {
        method: 'GET',
        headers: {"Content-Type":"application/json"},
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}
function getReviews(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, 'Reviews']);    

    const requestOptions = {
        method: 'GET',
        headers: { "Content-Type":"application/json"},
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}
<template>
  <div class="container">
    <!-- Action icons -->
    <div class="action-selectors d-flex justify-content-around mt-5">
      <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = LOGINACTION">
        <span class="action-text" v-bind:class="{ active: action== LOGINACTION}">{{$t("login.login").toUpperCase()}}</span>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = REGISTERACTION">
        <span class="action-text" v-bind:class="{ active:action== REGISTERACTION}">{{$t("login.register").toUpperCase()}}</span>
      </div>
      <div class="d-flex flex-column align-items-center justify-content-center cursor-pointer" @click="action = RECOVERACTION">
        <span class="action-text" v-bind:class="{ active:action== RECOVERACTION}">{{$t("login.recover").toUpperCase()}}</span>
      </div>
    </div>
    <!-- Login -->
    <form
      id="login-form"
      v-if="action == LOGINACTION"
      class="d-flex flex-column align-items-center h-100 text-center p-5 mt-5"
      @submit.prevent="handleLoginSubmit"
    >
      <!-- Username/email -->
      <div class="form-group w-100">
        <label for="username">{{ $t("login.email") }}</label>
        <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }"/>
        <div v-if="submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName'))" class="invalid-feedback">
          <span v-if="!$v.username.required">{{ $t("login.userVal") }}</span>
          <span v-if="errors.InvalidUserName">{{errors.InvalidUserName}}</span>
          <span v-if="errors.DuplicateUserName">{{errors.DuplicateUserName}}</span>
        </div>
      </div>
      <!-- Password -->
      <div class="form-group w-100">
        <label for="password">{{ $t("login.password") }}</label>
        <input type="password" v-model="password" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }" />
        <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
          <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
          <span v-if="errors.Password">{{errors.Password}}</span>
          <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
          <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
          <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
          <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
          <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
        </div>
      </div>
      <!-- Submit button -->
      <div class="form-group w-100 mt-5">
        <button class="submit-button w-100" v-bind:class="{disabled: loading || !username || !password}">
          <span v-show="!loading">{{$t("login.loginBtn").toUpperCase()}}</span>
          <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
        </button>                    
      </div>
    </form>
    <!-- Register -->
    <form id="register-form" v-else-if="action == REGISTERACTION" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleRegisterSubmit">
      <div class="w-100">
        <!-- Username/Email -->
        <div class="form-group w-100">
          <label for="email">{{ $t("login.email") }}</label>
          <input v-model="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail')) }"/>
          <div v-if="submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail'))" class="invalid-feedback">
              <span v-if="!$v.email.required">{{ $t("login.valEmail") }}</span>
              <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
              <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
              <span v-if="errors.InvalidEmail">{{errors.InvalidEmail}}</span>
          </div>
        </div>
        <!-- Username -->
        <!-- <div class="form-group w-100">
          <label for="username">{{ $t("login.username") }}</label>
          <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName')) }"/>
          <div v-if="submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName'))" class="invalid-feedback">
            <span v-if="!$v.username.required">{{ $t("login.userVal") }}</span>
            <span v-if="errors.InvalidUserName">{{errors.InvalidUserName}}</span>
            <span v-if="errors.DuplicateUserName">{{errors.DuplicateUserName}}</span>
          </div>
        </div> -->
        <!-- Password -->
        <div class="form-group w-100">
          <label for="password">{{ $t("login.password") }}</label>
          <input type="password" v-model="password" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }" />
          <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
            <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
            <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
            <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
            <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
            <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
            <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
          </div>
        </div>
        <!-- Confirm Password -->
        <div class="form-group w-100">
          <label for="confirmPassword">{{ $t("login.rpass") }}</label>
          <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
          <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
            <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
            <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
          </div>
        </div>
        <div class="d-flex w-100 justify-content-end" @click="action = RESENDEMAIL">
          <span v-if="action != RESENDEMAIL" class="cursor-pointer mt-2 resendConfirmEmail">{{$t("login.resendConfirmEmail")}}</span>
        </div>
        <!-- Submit button -->
        <div class="form-group w-100 mt-5" v-bind:class="{disabled: loading || !password || !email}">
          <button  class="submit-button w-100">
            <span v-show="!loading">{{$t("login.register").toUpperCase()}}</span>
            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
          </button>
        </div>
      </div>
    </form>
    <!-- Resend token -->
    <form v-if="action == RESENDEMAIL" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" action="" @submit.prevent="handleResendConfirmationTokenSubmit">
      <div class="d-flex w-100 justify-content-end" @click="action = REGISTERACTION">
        <span v-if="action == RESENDEMAIL" class="cursor-pointer mt-2 resendConfirmEmail">{{$t("login.back")}}</span>
      </div>
      <div class="form-group w-100">
        <label for="email">{{ $t("login.email") }}</label>
        <input v-model="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail')) }"/>
        <div v-if="submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail'))" class="invalid-feedback">
          <span v-if="!$v.email.required">{{ $t("login.valEmail") }}</span>
          <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
          <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
          <span v-if="errors.InvalidEmail">{{errors.InvalidEmail}}</span>
        </div>
      </div>
      <!-- Submit button -->
      <div class="form-group w-100 mt-5" v-bind:class="{disabled: loading || !email}">
        <button  class="submit-button w-100">
          <span v-show="!loading">{{$t("login.resendToken").toUpperCase()}}</span>
          <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
        </button>
      </div>
    </form>
    <!-- Recover (request link) -->
    <form id="recover-form" v-if="action == RECOVERACTION && !requestedLink" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleRecoverSubmit">
        <!-- Email -->
       <div class="form-group w-100">
          <label for="email">{{ $t("login.email") }}</label>
          <input v-model="email" id="email" name="email" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail')) }"/>
          <div v-if="submitted && (hasError('Email') || hasError('DuplicateEmail') || hasError('InvalidEmail'))" class="invalid-feedback">
              <span v-if="!$v.email.required">{{ $t("login.valEmail") }}</span>
              <span v-if="!$v.email.email">{{ $t("login.invEmail") }}</span>
              <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
              <span v-if="errors.InvalidEmail">{{errors.InvalidEmail}}</span>
          </div>
        </div>
        <!-- Submit button -->
        <div class="form-group w-100 mt-5" v-bind:class="{disabled: loading || !email}">
            <button class="submit-button w-100">
                <span v-show="!loading">{{$t("login.recover").toUpperCase()}}</span>
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
            </button>
        </div>
        <!-- Info message -->
        <span class="action-info text-center mt-3">{{ $t("login.infoMsg") }}</span>
    </form>
     <!-- Recover (define password) -->
    <form id="recover-form" v-else-if="action == RECOVERACTION && requestedLink" class="d-flex flex-column justify-content-between align-items-center h-100 text-center p-5 mt-5" @submit.prevent="handleResetSubmit">
      <!-- Username/Email -->
      <div class="form-group w-100">
        <label for="username">{{ $t("login.email") }}</label>
        <input v-model="username" id="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }"/>
        <div v-if="submitted && (hasError('Username') || hasError('InvalidUserName') || hasError('DuplicateUserName'))" class="invalid-feedback">
          <span v-if="!$v.username.required">{{ $t("login.userVal") }}</span>
          <span v-if="errors.InvalidUserName">{{errors.InvalidUserName}}</span>
          <span v-if="errors.DuplicateUserName">{{errors.DuplicateUserName}}</span>
        </div>
      </div>
      <!-- Token -->
      <div class="form-group w-100">
        <label for="token">{{ $t("login.token") }}</label>
        <input v-model="token" id="token" name="token" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Token') || hasError('InvalidToken')) }"/>
        <div v-if="submitted && (hasError('Token') || hasError('InvalidToken'))" class="invalid-feedback">
          <span v-if="!$v.token.required">{{ $t("login.tokenVal") }}</span>
          <span v-if="errors.InvalidToken">{{errors.InvalidToken}}</span>
          <span v-if="errors.Token">{{errors.Token}}</span>
        </div>
      </div>
      <!-- Password -->
      <div class="form-group w-100">
        <label for="password">{{ $t("login.newPassword") }}</label>
        <input type="password" v-model="password" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }" />
        <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
          <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
          <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
          <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
          <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
          <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
          <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
        </div>
      </div>
      <!-- Confirm Password -->
      <div class="form-group w-100">
        <label for="confirmPassword">{{ $t("login.rpass") }}</label>
        <input type="password" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
        <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
          <span v-if="!$v.confirmPassword.required">{{ $t("login.valPass") }}</span>
          <span v-else-if="!$v.confirmPassword.sameAsPassword">{{ $t("login.matchPass") }}</span>
        </div>
      </div>
      <!-- Submit button -->
      <div class="form-group w-100 mt-5" v-bind:class="{disabled: loading || !password}">
        <button class="submit-button w-100">
          <span v-show="!loading">{{$t("login.recover").toUpperCase()}}</span>
          <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-show="loading" class="sr-only">{{ $t("loading") }}</span>
        </button>
      </div>
      <!-- Info message -->
      <span class="action-info text-center">{{ $t("login.infoMsg") }}</span>
    </form>
  </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { mapState, mapActions } from "vuex";
import { API_HELPER } from "@/helpers/api.js";

const LOGINACTION = 0;
const REGISTERACTION = 1;
const RECOVERACTION = 2;
const RESENDEMAIL = 3;

export default {
   metaInfo () {
      return { 
            title: `${this.$t("vueRouter.loginTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.loginDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.login", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.login", 'pt')])},
            ]
            
        } 
    },
  data() {
    return {
      action: LOGINACTION,
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      token: "",
      LOGINACTION: LOGINACTION,
      REGISTERACTION: REGISTERACTION,
      RECOVERACTION: RECOVERACTION,
      RESENDEMAIL: RESENDEMAIL,
      submitted: false,
      loading: false,
      requestedLink: false
    };
  },
  validations() {
    switch (this.action) {
      case LOGINACTION: {
        return {
          username: { required },
          password: { required },
        };
      }
      case REGISTERACTION:
      {
        return {
          // username: { required },
          email: { required, email },
          password: { required },
          confirmPassword: { required, sameAsPassword: sameAs('password')}
        }
      }
      case RESENDEMAIL:
      {
        return {
          email: { required, email}
        }
      }
      case RECOVERACTION:
      {
        if(!this.requestedLink)
        {
          return {
            email: { required, email }
          }
        }
        else
        {
          return {
            username: { required },
            token: { required },
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      alertType: (state) => state.alert.type,
      alertOutput: (state) => state.alert.output,
    }),
    ...mapState("account", ["processTask"]),
    errors: function () {
      return this.alertOutput && this.alertOutput.errors ? this.alertOutput.errors : {}; 
    },
  },
  methods: {
    ...mapActions("account", ["login"]),
    ...mapActions('account', ['signup']),
    ...mapActions('account', ['requestLinkPassword']),
    ...mapActions('account', ['requestConfirmationToken']),
    ...mapActions('account', ['resetPassword']),
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    handleLoginSubmit() {
      this.clearAlert();
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;

      const { username, password } = this;
      this.login({ username, password });
    },
     handleRegisterSubmit() {
        this.clearAlert();
        this.submitted = true;

        // stop here if form is invalid
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }
        this.loading = true;
        
        const { email, password } = this;
        this.signup({ username: email, email, password });
    },
    handleRecoverSubmit(){
          this.clearAlert();
          this.submitted = true;

          // stop here if form is invalid
          this.$v.$touch();
          if (this.$v.$invalid) {
              return;
          }
          this.loading = true;

          const { email } = this;
          this.requestLinkPassword(email)
      },
      handleResetSubmit(){
          this.clearAlert();
          this.submitted = true;

          // stop here if form is invalid
          this.$v.$touch();
          if (this.$v.$invalid) {
              return;
          }
          this.loading = true;

          const { username, password, token } = this;
          this.resetPassword({ username, password, token })
      },
      handleResendConfirmationTokenSubmit(){
          this.clearAlert();
          this.submitted = true;

          // stop here if form is invalid
          this.$v.$touch();
          if (this.$v.$invalid) {
            return;
          }

          this.loading = true;

          const { email } = this;
          this.requestConfirmationToken( email )
      },
      hasError(fieldName) {
        var hasLocalError = false;

        if(this.$v[UTILS_HELPER.convertToCamelCase(fieldName)])
        {
          hasLocalError = this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error;
        }
        return hasLocalError || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
      },
  },
  watch: {
    action: function () {
      this.submitted = false;
      this.username = "";
      this.password = "";
      this.confirmPassword = "";
      this.email = ""
      this.token = ""
    },
    alertType: function(val)
    {
      if(val != null)
      {
        this.loading = false;
        
        if(val == "alert-danger")
        {
          this.$emit("error-alert-requested", { message: this.$t("errorAcc"), primaryButton: this.$t("closePopUp") });
        }
        else if(val == "alert-success")
        {
          if(this.action == REGISTERACTION)
          {
            this.submitted = false;
            // this.username = "";
            this.email = "";
            this.password = "";
            this.confirmPassword = "";
            this.$emit("success-alert-requested", { message: this.$t("registAcc")});
          }
          else if(this.action == RESENDEMAIL)
          {
            this.submitted = false;
            this.email = "";
            this.$emit("success-alert-requested", { message: this.$t("resendEmail"), primaryButton: this.$t("closePopUp")});
          }
          else if(this.action == RECOVERACTION)
          {
            this.submitted = false;
            this.$emit("success-alert-requested", { message: this.requestedLink ? this.$t("resetAcc") : this.$t("recoverAcc")});
            if(this.requestedLink)
            {
              this.handleRecoverSubmit();
              this.email = ""
            }
            else
            {
              this.requestedLink = true;
              this.username = "";
              this.token = "";
              this.password = "";
              this.confirmPassword = ""
            }
          }
        }
      }         
    }
  }
}
</script>

<style scoped>
.container{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#login-form{
  font-family: clanpro-news;
}
#register-form{
  font-family: clanpro-news;
}
#recover-form{
  font-family: clanpro-news;
}
.submit-button{
  background-color: #DDB427;
  color: white;
}
.action-text{
  color: black;
}
.active{
  color: #DDB427;
}
.resendConfirmEmail{
  color: #DDB427;
}
</style>

<template>
  <div class="container">
    <!-- Header and initial load more -->
    <div class="d-flex justify-content-between">
      <div v-if="title" class="col-6 title" :class="{'col-12': !loading && !pois.length}">{{title}}</div>
      <div v-if="!loading && canLoadMore && itemsPerPage == DEFAULT_ITEMS_PER_PAGE" class="d-flex align-items-center cursor-pointer" @click="loadMore">
        <div class="px-2">
            <span>{{$t("seeMore")}}</span>
        </div>
        <b-icon icon="plus-circle"></b-icon>
      </div>
      <div v-if="itemsPerPage != DEFAULT_ITEMS_PER_PAGE" @click="itemsPerPage = DEFAULT_ITEMS_PER_PAGE" class="d-flex align-items-center cursor-pointer">
        <div class="px-2">
            <span>{{$t("seeLess")}}</span>
        </div>
        <b-icon icon="dash-circle"></b-icon>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="poi-container d-flex justify-content-between flex-wrap mt-2">
      <LogoLoading />
    </div>
    <!-- Content -->
    <div v-else class="poi-container d-flex justify-content-between flex-wrap mt-2">
      <PoisThumb class="poi-thumb" v-for="poi in pois" :key="poi.id" :poiContent="poi"></PoisThumb>
      <NoResultsPlaceholder v-if="!pois.length"></NoResultsPlaceholder>
    </div>
  </div>
</template>

<script>
import PoisThumb from "@/components/PoisThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 4;

export default {
    components: {
      PoisThumb,
      LogoLoading,
      NoResultsPlaceholder
    },
    props: {
      title: String,
      searchQuery: String,
      type: Number
    },
    data() {
        return {
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          DEFAULT_ITEMS_PER_PAGE: DEFAULT_ITEMS_PER_PAGE,
          pois: []
        }
    },
    methods: {
        ...mapActions({
          getPoisList: 'pois/getGroupedList',
        }),
        updateItemsRequested() {
          const currentPage = 1;
          const searchText = this.searchQuery;
          const sorter = 2; // desc
          const propertyName = "id";

          //Make request
          var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);
          input.type = [this.type];

          this.getPoisList(input);
        },
        loadMore() {
          if (this.canLoadMore) {
            this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE;
          }
        }
    },
    computed: {
      ...mapState({
        groupedListOutput: (state) => state.pois.groupedListOutput,
      }),
      loading: function () {
        return this.groupedListOutput[this.type] === null;
      },
      numPois: function () {
        return this.groupedListOutput && this.groupedListOutput[this.type] && this.groupedListOutput[this.type].totalRecords ? this.groupedListOutput[this.type].totalRecords : 0;
      },
      canLoadMore: function () {
        return this.numPois > this.itemsPerPage;
      },
    },
    mounted() {
      // GET list of pois
      this.updateItemsRequested();
    },
    watch: {
      itemsPerPage() {
        this.updateItemsRequested();
      },
      groupedListOutput: function () {
          this.pois = this.groupedListOutput && this.groupedListOutput[this.type] && this.groupedListOutput[this.type].data && this.groupedListOutput[this.type].data && this.groupedListOutput[this.type].data.length ? this.groupedListOutput[this.type].data : [];
      },
      searchQuery: function () {
        this.updateItemsRequested();
      }
    }
}
</script>

<style scoped>
.title {
    font-size: 25px;
}

.poi-container {
    gap: 1rem;
}

>>>.content,
.poi-container>div,
.poi-container>span {
    width: 15vw;
    height: 15vw;
    margin-bottom: 2%;
}

@media (max-width: 1399px) {

    >>>.content,
    .poi-container>div,
    .poi-container>span {
        width: 25vw;
        height: 25vw;
        margin-bottom: 5%;
    }
}

@media (max-width: 1199px) {

    >>>.content,
    .poi-container>div,
    .poi-container>span {
        width: 20vw;
        height: 20vw;
    }
}

@media (max-width: 991px)

/* Two columns */
    {

    >>>.content,
    .poi-container>div,
    .poi-container>span {
        width: 30vw;
        height: 30vw;
    }
}

@media (max-width: 575px)

/* one column */
    {

    >>>.content,
    .poi-container>div,
    .poi-container>span {
        width: 100%;
        height: 100vw;
        margin-bottom: 10%
    }
}
</style>

<template>
  <section id="product-detail">
    <LogoLoading v-if="loadingContent" />
    <div v-if="productDetail && !loadingContent" id="img">
      <div class="container py-5">
        <div class="row">
          <div class="col-12 col-md-7">
              <h1 class="fw-bold">{{ productDetail.title }}</h1>
              <hr class="container" />
              <p>{{ categories }}</p>
              <hr class="container" />
              <p v-html="productDetail.taglines"></p>
              <div class="d-flex flex-column text-end">
                  <div v-for="(customField, index) in productDetail.customFields" :key="index">
                      <div class="d-flex align-items-center py-2">
                          <div class="col-6">
                              <p v-if="customField.required == true" class="m-0 px-2">
                                  {{ customField.names[currentLanguage] + "*" }}
                              </p>
                              <p v-else class="m-0 px-2">
                                  {{ customField.names[currentLanguage] }}
                              </p>
                          </div>
                          <div class="col-6" style="height: 30px">
                              <UniSelect @input="customFieldChanged(customField, cartItem.customFields[index])" :options="customFieldOptions(customField)" v-model="cartItem.customFields[index]"></UniSelect>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="d-flex text-end align-items-center flex-wrap py-2">
                  <div class="col-12 col-md-6">
                      <div class="d-flex align-items-center justify-content-end px-0 px-md-3">
                          <p class="m-0 px-2">{{ $t("productDetail.quant") }}</p>
                          <input :min="1" style="width: 80px" class="form-control col-6" v-model="cartItem.quantity" :oninput="(cartItem.quantity = Math.abs(cartItem.quantity))" type="number" />
                      </div>
                  </div>
                  <div class="col-12 col-md-6 py-4 p-md-0">
                      <button v-if="addedToCart" class="submit-button w-100 disabled">
                          <span class="fw-bold">{{ $t("productDetail.addedToCart") }}</span>
                      </button>
                      <button v-else class="submit-button w-100" v-bind:class="{ disabled: disableAddButton || loading }" @click="addToCart">
                          <span v-show="!loading" class="fw-bold">{{ $t("productDetail.add") }}</span>
                          <span v-show="loading" class="sr-only">{{ $t("loading") }}</span>
                          <span v-show="loading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                      </button>
                  </div>
              </div>
              <div v-if="productPrice" class="d-flex justify-content-end product-price mt-0 mt-md-5">
                <h1 class="fw-bold">{{ productPrice }}€</h1>
                <span class="mt-4">/Un</span>
              </div>
              <hr class="container" />
              <div class="d-flex justify-content-between align-items-center">
                  <p class="m-0">Ref: {{ productDetail.sku }}</p>
                  <div class="d-flex">
                      <p class="m-0 px-3 cursor-pointer">
                          {{ $t("productDetail.fav") }}
                      </p>
                      <img v-if="!isFavorite" @click="favoriteButtonClicked" class="cursor-pointer" src="@/assets/shop/favIcon.svg" alt="" :style="{ width: '25px' }" loading="lazy" />
                      <img v-if="isFavorite" @click="favoriteButtonClicked" class="cursor-pointer" src="@/assets/shop/isFav.svg" alt="" :style="{ width: '25px' }" loading="lazy" />
                  </div>
              </div>
              <hr class="container" />
              <p>
                  {{ $t("productDetail.tags") }}:
                  <span class="primary-color">{{ tags }}</span>
              </p>
              <hr class="container" />
          </div>
          <ThreePartGallery v-if="gallery != null" class="d-none d-md-block col-5" :gallery="gallery"></ThreePartGallery>
          <!-- XS, SM Gallery -->
          <ThreePartGallery v-if="gallery != null" class="d-md-none col-12 my-3" :gallery="gallery"></ThreePartGallery>
        </div>
        <!-- tabs -->
        <div class="row mt-5">
            <div class="d-flex tab-btn">
                <ul>
                    <li class="cursor-pointer flex-grow-1" :class="{ active: activeTab == index }" v-for="(info, index) in moreInfo" :key="index" @click="activeTab = index">
                        <span>{{ info.name }}</span>
                    </li>
                </ul>
            </div>
            <div class="py-5">
                <div v-if="productDetail" class="col-12">
                    <div v-if="activeTab == 0">
                        <p class="m-0" v-html="productDetail.content" />
                    </div>
                    <div v-if="activeTab == 1">
                        <p class="m-0" v-html="productDetail.additionalInfo"></p>
                    </div>
                    <div v-if="activeTab == 2">
                        <template v-if="reviewsOutput && reviewsOutput.length">
                            <div v-for="(review, index) in reviewsOutput" :key="index">
                                <p class="m-0">{{ review.description }}</p>
                                <hr>
                            </div>
                        </template>
                        <template v-else>
                            <p>{{ $t("productDetail.noReviews") }}</p>
                        </template>
                    </div>
                    <div v-if="activeTab == 3">
                        <p class="m-0">teste</p>
                    </div>
                </div>
            </div>
        </div>
        <hr class="container" />
        <RelatedSlider v-if="productDetail.id" :id="productDetail.id" />
        <Newsletter class="py-5" />
      </div>
    </div>
  </section>
</template>

<script>
import LogoLoading from "@/components/LogoLoading.vue"
import router from "@/router";
import { mapState, mapActions } from "vuex";
import { API_HELPER } from "@/helpers/api.js";
import ThreePartGallery from "@/components/ThreePartGallery.vue";
import Newsletter from "@/components/Newsletter.vue";
import UniSelect from "@/components/UniSelect.vue";
import { CONSTANTS } from "@/helpers/consts";
import RelatedSlider from "@/components/Shop/RelatedSlider.vue";
import InputGetList from "@/classes/InputGetList";
import CartItem from "@/classes/CartItem";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
    components: {
        ThreePartGallery,
        UniSelect,
        Newsletter,
        RelatedSlider,
        LogoLoading
    },
       metaInfo () {
    var result =
    { 
        title: this.productDetail ? `${this.productDetail.title} - Tabuaço` : "Tabuaço", 
        htmlAttrs: {
            lang: this.$i18n.locale,
        },
        meta: 
        [
            {name: 'description', content: this.productDetail && this.productDetail.content ? UTILS_HELPER.htmlToText(this.productDetail.content) : this.$t("intro")},
            {name: 'keywords', content: this.productDetail && this.productDetail.categories && this.productDetail.categories.length ? this.productDetail.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.productDetail ? `${this.productDetail.name} - Tabuaço` : "Tabuaço"},
            {name: 'twitter:description', content: this.productDetail && this.productDetail.content ? UTILS_HELPER.htmlToText(this.productDetail.content) : this.$t("intro")},
            {name: 'twitter:image', content: this.featuredImagePath ? this.featuredImagePath : API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.productDetail ? `${this.productDetail.name} - Tabuaço` : "Tabuaço"},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: this.featuredImagePath ? this.featuredImagePath : API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.productDetail && this.productDetail.content ? UTILS_HELPER.htmlToText(this.productDetail.content) : this.$t("intro")},
        ]
    } 

    if(this.$route.query.id)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'pt')])}?id=${this.$route.query.id}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'en')])}?id=${this.$route.query.id}`},
        ];
    }

    return result;
  }, 
    data: function () {
        return {
            selectedCategory: null,
            activeTab: 0,
            moreInfo: [{
                    name: this.$t("productDetail.desc")
                },
                {
                    name: this.$t("productDetail.infoA")
                },
                {
                    name: this.$t("productDetail.reviews")
                },
                {
                    name: this.$t("productDetail.other")
                },
            ],
            loading: false,
            cartItem: new CartItem(null, 1, []),
            disableAddButton: true,
            gallery: [],
            productPrice: null,
        };
    },
    mounted() {
        this.initializeParams();
    },
    methods: {
        ...mapActions({
            getSlug: "products/getDetail",
            getFavorites: "favorites/getList",
            getCart: "cart/getList"
        }),
        ...mapActions("favorites", ["addFavorite"]),
        ...mapActions("favorites", ["removeFavorite"]),
        ...mapActions("cart", ["manageCart"]),
        ...mapActions("products", ["getReviews"]),
        addToCart() {
            const loggedIn = localStorage.getItem('user');
            if(loggedIn) {
                this.loading = true;
                console.log({
                    productId: this.cartItem.productId,
                    quantity: this.cartItem.quantity,
                    customFields: this.cartItem.customFields.filter(Boolean),
                });
                this.manageCart({
                    productId: this.cartItem.productId,
                    quantity: this.cartItem.quantity,
                    customFields: this.cartItem.customFields.filter(Boolean),
                });
            }else{
                router.push({name: 'Login'})
            }
        },
        initializeParams: function () {
            if (!this.$route.params.slug) {
                router.push({
                    name: "Shop"
                });
            } else {
                if (
                    this.$route.params.ProductDetail ||
                    !this.$route.params.ProductDetail
                ) {
                    this.getSlug(this.$route.params.slug);
                }
            }
            // favs
            this.getFavsRequested();
        },
        favoriteButtonClicked() {
            if (this.isFavorite) {
                this.removeFavorite(this.productDetail.slug);
            } else {
                this.addFavorite(this.productDetail.slug);
            }
        },
        getFavsRequested() {
            const itemsPerPage = 999;
            const currentPage = 1;
            const sorter = 1; // asc
            const propertyName = "id";

            //Make request
            var inputGetFavorites = new InputGetList(
                itemsPerPage,
                currentPage,
                sorter,
                propertyName,
                null
            );

            // Get favs
            this.getFavorites(inputGetFavorites);
        },
        reviewsRequested() {
            const itemsPerPage = 999;
            const currentPage = 1;
            const sorter = 1; // asc
            const propertyName = "id";

            //Make request product reviews
            var inputGetReviews = new InputGetList(
                itemsPerPage,
                currentPage,
                sorter,
                propertyName,
                null
            );

            // List specific parameters
            inputGetReviews.productId = this.productDetail.id;
            inputGetReviews.approvedOnly = true

            // Get product reviews
            this.getReviews(inputGetReviews);
        },
        customFieldOptions: function (customField) {
            var result = [];
            let currentLanguage = this.currentLanguage;
            if (
                this.productDetail.customFields &&
                this.productDetail.customFields.length
            ) {
                customField.options.forEach(function (option) {
                    result.push({
                        customFieldId: customField.id,
                        optionId: option.id,
                        name: option.names[currentLanguage],
                        required: customField.required,
                    });
                });
            }

            return result;
        },
        customFieldChanged: function(customField, value){
            if(customField.required)
            {
                customField.options.forEach(option =>
                {
                    if(option.id == value.optionId)
                    {
                        this.productPrice = option.price;
                    }
                });
            }
        }
    },
    computed: {
        currentLanguage: function()
        {
            return this.$i18n.locale ?? CONSTANTS.supportedLanguages[0].key;
        },
        ...mapState("products", ["productDetailOutput"]),
        ...mapState("favorites", ["listOutput"]),
        ...mapState("favorites", ["addTask"]),
        ...mapState("favorites", ["removeTask"]),
        ...mapState("cart", ["cartItems"]),
        ...mapState("cart", ["processTask"]),
        ...mapState("products", ["reviewOutput"]),
        addedToCart: function()
        {
            return this.cartItems && Array.isArray(this.cartItems)? this.cartItems.filter(e => e.Id === this.productDetail.Id).length > 0 : false;
        },
        loadingContent: function () {
            return this.productDetailOutput == null;
        },
        productDetail() {
            return this.productDetailOutput ? this.productDetailOutput : null;
        },
        reviewsOutput() {
            return this.reviewOutput && this.reviewOutput.data ? this.reviewOutput.data : []
        },
        categories() {
            let result = "";

            if (this.productDetail && this.productDetail.categories) {
                let names = this.productDetail.categories.map((x) => x.name);
                result = names.join("| ");
            }
            return result;
        },
        tags: function () {
            let result = "";

            if (this.productDetail && this.productDetail.tags) {
                let tags = this.productDetail.tags[this.currentLanguage].map(
                    (x) => x
                );
                result = tags.join(", ");
            }
            return result;
        },
        isFavorite: function () {
            return this.listOutput && this.listOutput.data ?
                this.listOutput.data.find((x) => x.slug == this.productDetail.slug) :
                false;
        },
        customFieldRequired() {
            return this.productDetail && this.productDetail.customFields ?
                this.productDetail.customFields.filter((x) => x.required == true) :
                [];
        },
    },
    watch: {
        $route(to) {
            if (to.name == "ProductDetail") {
                this.initializeParams();
            }
        },
        productDetailOutput(val) {
            if (val == false) {
                router.push({
                    name: "Shop"
                });
            }
            if (val) {
                this.productPrice = this.productDetail.finalPrice;
                this.cartItem.productId = val.id;
                this.reviewsRequested();
                //gallery
                let featuredImage = val.featuredImagePath;
                let images = val.imagesPath;
                images.push(featuredImage);
                this.gallery = images.map((file) =>
                    API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file])
                );
            }
        },
        addTask: function (val) {
            if (val) {
                this.getFavsRequested();
            }
        },
        removeTask: function (val) {
            if (val) {
                this.getFavsRequested();
            }
        },
        processTask(val) {
            if (val != null) {
                this.loading = false;
                if (val) 
                {
                    this.getCart();
                    this.$emit("success-alert-requested", { message: this.$t("productDetail.addCart"), primaryButton: this.$t("closePopUp")});
                } 
                else {
                    this.$emit("error-alert-requested", { message: this.$t("productDetail.erroraddCartContent"), primaryButton: this.$t("closePopUp")});
                }
            }
        },
        cartItem: {
            handler(val) {
                if (val && val.customFields) {
                    // Array with non-null values only(used the filter for that)
                    let newArray = val.customFields.filter(Boolean);
                    for (let x = 0; x <= newArray.length; x++) {
                        // Condition to activate the add to cart button
                        if (
                            (newArray[x] &&
                                newArray[x].required == true &&
                                newArray.length == this.customFieldRequired.length) ||
                            newArray.length == this.productDetail.customFields.length
                        ) {
                            this.disableAddButton = false;
                        }
                    }
                }
                // Activate button when quantity is 0
                if (val && val.quantity == 0) {
                    this.disableAddButton = true;
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
p {
    font-family: clanpro-news;
}

#img {
    background-image: url("../assets/shop/tabuacoBG.svg");
    background-repeat: no-repeat;
}

::placeholder {
    font-family: clanpro-news;
    opacity: 1;
}

.submit-button {
    background-color: #ddb427;
    border: 2px solid black;
    color: black;
}

input:focus,
button:focus {
    outline: none;
}

.tab-btn ul,
.tab-btn li,
.tab-btn span {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.tab-btn ul {
    overflow: hidden;
    width: 100%;
    background-color: #cfcbcb;
    border: 1px solid black;
}

.tab-btn li {
    transform: skewX(35deg);
    -ms-transform: skewX(35deg);
    -webkit-transform: skewX(35deg);
}

.tab-btn li span {
    /* padding:3px 6px 3px 6px; */
    transform: skewX(-35deg);
    -ms-transform: skewX(-35deg);
    -webkit-transform: skewX(-35deg);
}

.tab-btn li.active {
    background-color: white;
    border: 1px solid black;
}

.tab-btn li:nth-child(1),
.tab-btn li:nth-child(4) {
    background-color: #cfcbcb;
    transform: unset !important;
}

.tab-btn li:nth-child(1) span,
.tab-btn li:nth-child(4) span {
    transform: unset !important;
}

.tab-btn li:nth-child(1).active,
.tab-btn li:nth-child(4).active {
    background-color: white;
}

@media (max-width: 576px) {
    .tab-btn li span {
        font-size: 0.6rem;
    }
}
</style>

<template>
  <section>
    <LogoLoading v-if="!eventDetail"/>
    <div v-else>
        <div id="image-BG">
            <div class="container my-5">
                <div class="row">
                  <!-- Title -->
                  <div class="title-detail d-flex col-sm-12 col-md-6 col-lg-6">
                    <h1>{{eventDetail.title}}</h1>
                  </div>
                  <!-- Navigation buttons -->
                  <div id="nav-buttons" class="d-flex col-sm-12 col-md-6 col-lg-6">
                    <a @click="$router.back()" class="backBtn cursor-pointer">{{ $t("goBack")}}</a> 
                  </div>
                  <hr>
                  <div class="col-sm-12 col-md-8 col-lg-8">
                      <span class="font-clanpro-bold"><span>{{categorieEvents[0].name}} | {{startDate}} - {{endDate}}</span></span>
                      <br><br>
                      <span class="font-clanpro">{{eventDetail.content}}</span>
                      <br><br>
                      <span class="font-clanpro-bold">{{$t("eventsPage.priceRange")}}</span>
                      <br>
                      <span class="font-clanpro">{{eventDetail.price}}</span>
                      <br><br>
                      <span class="font-clanpro-bold">{{$t("eventsPage.schedule")}}</span>
                      <br>
                      <span class="font-clanpro">{{eventDetail.time}}</span>
                      <br><br>
                      <span class="font-clanpro-bold">{{$t("eventsPage.contact")}}</span>
                      <br>
                      <span class="font-clanpro">{{eventDetail.contact}}</span>
                      <br><br>
                      <span class="font-clanpro-bold">{{$t("eventsPage.address")}}</span>
                      <br>
                      <span class="font-clanpro">{{eventDetail.address}}</span>
                      <br>
                      <span class="font-clanpro">GPS: </span>
                      <span class="font-clanpro">{{eventDetail.latitude}}</span> , <span class="font-clanpro">{{eventDetail.longitude}}</span>
                      
                  </div>
                  <div class="col-sm-12 col-md-4 col-lg-4">
                      <img class="w-100 h-100 image-style" :src="imageUrl" alt="" loading="lazy">
                  </div>
                </div>
            </div>
        </div>
        <iframe class="mt-3" width="100%" height="320" :src="mapUrl" title="Main content" name="contents"></iframe>
    </div>
  </section>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import router from '@/router';
import { mapState, mapActions } from "vuex";
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue"
export default {
components: {
  LogoLoading
},
metaInfo () {
      var result =
      { 
          title: this.eventDetail ? `${this.eventDetail.title} - Tabuaço` : "Tabuaço", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.eventDetail && this.eventDetail.content ? UTILS_HELPER.htmlToText(this.eventDetail.content) : this.$t("intro")},
              {name: 'keywords', content: this.eventDetail && this.eventDetail.categories && this.eventDetail.categories.length ? this.eventDetail.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
              // Twitter Card
              {name: 'twitter:card', content: "summary"},
              {name: 'twitter:title', content: this.eventDetail ? `${this.eventDetail.title} - Tabuaço` : "Tabuaço"},
              {name: 'twitter:description', content: this.eventDetail && this.eventDetail.content ? UTILS_HELPER.htmlToText(this.eventDetail.content) : this.$t("intro")},
              {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              //Facebook OpenGraph
              {property: 'og:title', content: this.eventDetail ? `${this.eventDetail.title} - Tabuaço` : "Tabuaço"},
              {property: 'og:site_name', content: 'Tabuaço'},
              {property: 'og:type', content: 'website'},
              {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              {property: 'og:description', content: this.eventDetail && this.eventDetail.content ? UTILS_HELPER.htmlToText(this.eventDetail.content) : this.$t("intro")}
          ]
      } 

      if(this.$route.params.slug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.eventsDetail", 'pt')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.eventsDetail", 'en')])}/${this.$route.params.slug}`},
          ];
      }

      return result;
  }, 
  methods: {
    ...mapActions({
          getSlug: 'events/getDetails',
        }),
    initializeParams: function(){
      if(!this.$route.params.slug)
      {
        router.push({name: "Events"});
      }
      else{
        this.getSlug(this.$route.params.slug);
      }
    },
  },
  computed:
  {
    ...mapState('events', ['detailOutput']),
    eventDetail() 
    {
      return this.detailOutput && this.detailOutput ? this.detailOutput: null;
    },
    categorieEvents()
    {
      return this.eventDetail && this.eventDetail.categories ? this.eventDetail.categories : [];
    },
    imageUrl()
    {
      var relativePath = this.eventDetail && this.eventDetail.featuredImagePath ? this.eventDetail.featuredImagePath : null;
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
    },
    startDate() {
      return UTILS_HELPER.formatshortDate(this.eventDetail.startDate);
    },
    endDate() {
      return UTILS_HELPER.formatshortDate(this.eventDetail.endDate);
    },
    mapUrl: function()
    {
      return this.eventDetail ? `https://maps.google.com/maps?q=${this.eventDetail.latitude},${this.eventDetail.longitude}&hl=es;z=14&output=embed` : null;
    },
  },
  mounted() {
    this.initializeParams();
  },
  watch:
  {
    detailOutput: function(val)
    {
      if(val == false)
      {
        router.push({name: "Events"});
      }
    }
  }
}
</script>

<style scoped>
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
#image-BG{
  background-image: url("../assets/shop/tabuacoBG.svg");
  background-repeat: no-repeat;
  object-fit: cover;
}
.backBtn{
  text-decoration: none;
  color: black;
  padding-top: 1rem;
}
.image-style{
  object-fit: cover;
}
@media (min-width: 992px) {
  #nav-buttons
  {
    justify-content: flex-end;
  }
}

@media (max-width: 991px)
{
  .container {
    margin: 1rem 0!important;
  }
  #nav-buttons
  {
    justify-content: end;
  }
  .title-detail
  {
    justify-content: start;
  }

}
@media (max-width: 850px)
{
  #image-BG{
    background-image: none;
  }
}
@media (max-width: 767px)
{
  .image-style{
    margin-top: 2rem;
  }
}
</style>
/* Imports */
import { API_HELPER } from "@/helpers/api.js";


/* Exports */
// API services that can be invoked on the modules
export const NEWSLETTER_SERVICE = {
    submitNewsletter
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Newsletter";

/* Services */
function submitNewsletter(email)
{
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, email]);
    
    const requestOptions = {
        method: 'GET',
    }

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}

<template>
  <section>
      <span v-for="n in rating" :key="n">
          <img src="@/assets/shop/star.svg" alt="" loading="lazy">
      </span>
  </section>
</template>

<script>
export default {
props:{
    rating:{
        type: Number,
        required: false,
    },
}
}
</script>

<style scoped>

</style>
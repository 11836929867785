<template>
<form v-if="isNotMobile" action="" @submit.prevent="handleMessageSubmit">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4 p-0">
        <p class="fw-bold">{{ $t("newsletter.description") }}</p>
      </div>
      <div class="col-12 col-lg-8 p-0">
        <div class="row">
          <div class="col-12 col-lg-9 py-2 py-lg-0">
            <input v-model="email" class="w-100 font-clanpro" type="text" :placeholder="$t('newsletter.insertEmail')" id="email" :class="{ 'is-invalid': submitted && hasEmailError }"/>
            <div v-if="submitted && hasEmailError" class="invalid-feedback">
              <span v-if="!$v.email.required">{{$t("newsletter.email")}}</span>
              <span v-if="!$v.email.email">{{ $t("reservation.invEmail") }}</span>
              <span v-if="errors.Email">{{errors.Email}}</span>
              <span v-if="errors.DuplicateEmail">{{errors.DuplicateEmail}}</span>
            </div>
          </div>
          <div class="col-12 col-lg-3 py-2 py-lg-0">
            <button class="submit-button w-100" :class="{ disabled: !isCompleted || loading }">
              <span v-show="!loading">{{$t("newsletter.subscribe")}}</span>
              <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
              <span v-show="loading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
            </button> 
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import EventBus from '@/events/bus.js';

export default {
  data: function() {
    return {
      email: "",
      submitted: false,
      loading: false,
      isNotMobile: true,
    };
  },
  created()
  {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var isMobile = params.get("mode");
    if(isMobile == "mobileapp" )
    {
        this.isNotMobile = false;
    }
  },
  validations: {
    email: { email, required },
  },
  computed: {
    ...mapState("newsletter", ["processNewsletter"]),
    ...mapState({
      alertType: (state) => state.alert.type,
      alertOutput: (state) => state.alert.output,
    }),
    errors: function() {
      return this.alertOutput && this.alertOutput.errors ? this.alertOutput.errors : {};
    },
    isCompleted() {
      return this.email;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("newsletter", ["submitNewsletter"]),

    handleMessageSubmit() {
      this.clearAlert();
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      this.submitNewsletter( this.email );
    },
    resetForm() {
      this.submitted = false;
      this.email = "";
    },
    hasError(fieldName) {
      return ( this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName));
    },
    hasEmailError()
    {
      return this.$v[UTILS_HELPER.convertToCamelCase("Email")].$error || Object.prototype.hasOwnProperty.call(this.errors, "Email") || Object.prototype.hasOwnProperty.call(this.errors, "DuplicateEmail");
    }
  },
  watch: {
    processNewsletter: function(val)
    {
      if(val != null)
      {
        this.loading = false;
        if(val)
        {
          this.resetForm();
          EventBus.$emit("success-alert-requested", { message: this.$t("newsletter.subscribeNewsContent"), primaryButton: this.$t("closePopUp")});
        }
        else
        {
          EventBus.$emit("error-alert-requested", { message: this.$t("newsletter.errorNewsletterContent"), primaryButton: this.$t("closePopUp")});
        }
      }
    }
  },
};
</script>

<style scoped>
input{
  border: 2px solid black;
}
input:focus,
button:focus{
  outline: none;
}
::placeholder { 
  font-family: clanpro-news;
  opacity: 1;
}
.submit-button
{
  background-color: #DDB427;
  border: 2px solid black;
  color: black;
}
</style>
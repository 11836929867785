<template>
  <div v-if="banners.length" id="banner">
    <vueper-slides
      fixed-height="405px"
      :arrows="false"
      class="img-slide"
      :touchable="false"
      autoplay
      :duration="10000"
      :breakpoints="{ 992: { touchable: true, bullets: false, arrows: false } }"
    >
      <vueper-slide v-for="(banner, i) in banners" :key="i">
        <template v-slot:content>
          <BannerThumb :bannerContent="banner" />
        </template>
      </vueper-slide>
      <template #bullet="{ active }">
        <div :class="active ? 'check_rectangle' : 'unchecked_rectangle'"></div>
      </template>
    </vueper-slides>
  </div>
</template>

<script>
import BannerThumb from "@/components/Shop/BannerThumb.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BannerThumb,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      banners: [],
    };
  },
  mounted() {
    this.initializePage();
  },
  computed: {
    ...mapState({
      bannersOutput: (state) => state.products.bannerOutput,
    }),
  },
  methods: {
    ...mapActions("products", ["getBannerList"]),

    initializePage() {
      this.getBannerList();
    },
  },
  watch: {
    bannersOutput(val) {
      if (val && val != null) {
        this.banners = [val];
      }
    },
  },
};
</script>

<style scoped>
.check_rectangle {
  width: 250px;
  height: 5px;
  background-color: white;
}
.unchecked_rectangle {
  width: 30px;
  height: 5px;
  background-color: #ddb427;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Translations
import i18n from '@/helpers/i18n'

// JQuery
import 'jquery/src/jquery.js'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.prototype.$vueEventBus = new Vue(); // Global event bus
Vue.config.productionTip = false

// Vue 360 Images
import VueThreeSixty from 'vue-360'

import 'vue-360/dist/css/style.css'

Vue.use(VueThreeSixty)

import Vue360 from 'vue-360';
import 'vue-360/dist/css/style.css'
Vue.use(Vue360)

// Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// VuePhoneNumber Plugin
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);


const options = {
  // Translations
  locale: i18n.locale,
};
Vue.use( options);


// Scroll to top on route change
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);
  
  // Scroll to top between each route change
  setTimeout(function () {
      window.scrollTo(0, 0);
    },100);

  // Block logged in only pages pages
  const loggedIn = localStorage.getItem('user');
  const privatePages = [ i18n.t('vueRouter.cart'), i18n.t('vueRouter.favorites'), i18n.t('vueRouter.account'), `${i18n.t('vueRouter.shop')}/:slug` ];
  const authRequired = privatePages.includes(to.path);

  if (authRequired && !loggedIn) {
    next('/login');
  }
  next();
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

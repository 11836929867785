import { CART_SERVICE } from "@/services/cart.service.js";

const state = { cartItems: {}, processTask: {} }

const actions = {
    getList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        CART_SERVICE.list()
        .then(
            output => {
                commit('listSuccess', output);
            },
            error => {
                commit('listFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
        
    },
    manageCart({ dispatch, commit }, product) {
        commit('manageRequest');
        
        CART_SERVICE.manage(product)
        .then(
            output => {
                commit('manageSuccess', output);
            },
            error => {
                commit('manageFailure', error);
                dispatch('alert/error', error, { root: true }); 
            }
        );
     
    },
}

    
const mutations = {
    //list
    listRequest() {
    },
    listSuccess(state, output) {
        state.cartItems = output;
    },
    listFailure(state) {
        state.cartItems = false;
    },
    // manageCart
    manageRequest(state) {
        state.processTask = null;
    },
    manageSuccess(state) {
        state.processTask = true;
    },
    manageFailure(state) {
        state.processTask = false;
    },
}; 
    

export const cart = {
    namespaced: true,
    state,
    actions,
    mutations
};
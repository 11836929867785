import { ACCOUNT_SERVICE } from "@/services/account.service.js";
import router from '@/router/index';

const user = JSON.parse(localStorage.getItem('user'));

const state = user
    ? { status: { loggedIn: true }, user, refresh: undefined, reset: null, linkConfirmAcc: null, processConfirm: null, processUpdate: null}
    : { status: {}, user: null, refresh: undefined, reset: null, linkConfirmAcc: null, processConfirm: null, processUpdate: null};

const actions = {
    login({ dispatch, commit }, { username, password }) {
        dispatch('alert/clear', null, { root: true });
        commit('loginRequest', { username });
        
        ACCOUNT_SERVICE.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.go(-1);
                },
                error => {
                    commit('loginFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }

            );
    },
    signup({ dispatch, commit }, { username,email,password }) {
        dispatch('alert/clear', null, { root: true });
        commit('signupRequest');
        
        ACCOUNT_SERVICE.signup({ username,email,password })
            .then(
                user => {
                    commit('signupSuccess', user);
                    dispatch('alert/success', user, { root: true });

                },
                error => {
                    commit('signupFailure', user);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    refreshToken({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('refreshRequest');
        
        /* Refresh the token and try again */
        ACCOUNT_SERVICE.refreshToken(state.user.refreshToken, state.user.username)
        .then(user =>{
            commit('refreshSuccess', user);
       })
       .catch((error) => 
       {
            commit('refreshFailure', error);
            dispatch('alert/error', error, { root: true });
            dispatch('account/logout', null, { root: true });
       });
    },
    requestLinkPassword({ dispatch, commit }, username) {
        dispatch('alert/clear', null, { root: true });
        commit('linkRequest');

        ACCOUNT_SERVICE.requestLink(username)
            .then(
                user => {
                    commit('linkSuccess',user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('linkFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    requestConfirmationToken({ dispatch, commit }, username) {
        dispatch('alert/clear', null, { root: true });
        commit('linkConfirmAccRequest');

        ACCOUNT_SERVICE.requestConfirmationToken(username)
            .then(
                user => {
                    commit('linkConfirmAccSuccess',user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('linkConfirmAccFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    resetPassword({ dispatch, commit }, {username, password, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('resetPasswordRequest');

        ACCOUNT_SERVICE.resetPassword(username, password, token)
            .then(
                user => {
                    commit('resetPasswordSuccess', user);
                    dispatch('alert/success', user, { root: true });
                },
                error => {
                    commit('resetPasswordFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    confirmAccount({ dispatch, commit }, {username, token}) {
        dispatch('alert/clear', null, { root: true });
        commit('confirmAccountRequest');
        ACCOUNT_SERVICE.confirmAccount(username, token)
        .then(
            user => {
                commit('confirmAccountSuccess', user);
                dispatch('alert/success', user, { root: true });
            },
            error => {
                commit('confirmAccountFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },

    updateProfile({ dispatch, commit }, password) {
        dispatch('alert/clear', null, { root: true });
        commit('updateProfileRequest');

        ACCOUNT_SERVICE.updateProfile(password)
        .then(
            user => {
                commit('updateProfileSuccess', user);
                dispatch('alert/success', user, { root: true });
            },
            error => {
                commit('updateProfileFailure', error);
                dispatch('alert/error', error, { root: true });
            }
        );
    },

    logout({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });
        commit('logout');

        ACCOUNT_SERVICE.logout();
    },
}

const mutations = {
    /* Login */
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
        
        // uncomment if you want to test the token with a set expiration date
        // var currentDate = new Date();
        // user.expiration = new Date(currentDate.getTime() + 3*60000);

        // login successful if there's a jwt token in the response
        if (user && user.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    /* Signup */
    signupRequest(state)
    {
        state.status = null;
    },
    signupSuccess(state)
    {
        state.status = {registered: true};
    },
    signupFailure(state)
    {
        state.status = false;
    },
      /* Request link */
    linkRequest(state) {
        state.reset = null;
    },
    linkSuccess(state) {
        state.reset = true;
    },
    linkFailure(state) {
        state.reset = false;
    },
      /* Request link */
    linkConfirmAccRequest(state) {
        state.linkConfirmAcc = null;
    },
    linkConfirmAccSuccess(state) {
        state.linkConfirmAcc = true;
    },
    linkConfirmAccFailure(state) {
        state.linkConfirmAcc = false;
    },
     /* Reset Password */
    resetPasswordRequest(state) {
        state.reset = null;
    },
    resetPasswordSuccess(state) {
        state.reset = true;
    },
    resetPasswordFailure(state) {
        state.reset = false;
    },
      /* Confirm Account */
    confirmAccountRequest(state) {
        state.processConfirm = null;
    },
    confirmAccountSuccess(state) {
        state.processConfirm = true;
    },
    confirmAccountFailure(state) {
        state.processConfirm = false;
    },
    /* Profile */
    updateProfileRequest(state) {
        state.processUpdate = null;
    },
    updateProfileSuccess(state) {
        state.processUpdate = true;
        // localStorage.setItem('user', JSON.stringify(state.user));
    },
    updateProfileFailure(state) {
        state.processUpdate = false;
    },
     /* refreshToken */
     refreshRequest(state) {
        state.refresh = 'refreshing';
    },
    refreshSuccess(state, user) {
        state.user = user;

        // login successful if there's a jwt token in the response
        if (user && user.refreshToken) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
        }

        state.refresh = true;
    },
    refreshFailure(state) {
        state.refresh = false;
    },
    logout(state) {
        state.status = {};
        state.user = null;

        localStorage.removeItem('user');
        localStorage.removeItem('locale');

        router.go('/login'); // Force all modules to reset state
    },
}

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};
/* Imports */
import { API_HELPER } from "@/helpers/api.js";


/* Exports */
// API services that can be invoked on the modules
export const FAVORITES_SERVICE = {
    list,
    add,
    remove
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Favorites";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
        headers: { "Content-Type":"application/json" }
    }; 

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, queryParams, requestOptions);
}

function add(slug) {
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]);  

    // // Body must be the same as the api
    // var object = {"productsId": ids};

    // var raw = JSON.stringify(object);
    const requestOptions = {
        method: 'POST',
        headers: { "Content-Type":"application/json" }
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);    
}

function remove(slug) {
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, slug]); 

    const requestOptions = {
        method: 'DELETE',
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
import { PRODUCT_SERVICE } from "@/services/products.service.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

const latestViewed = JSON.parse(localStorage.getItem('latestProducts'));
const state = { featuredOutput:{}, publishDateOutput: {}, bestSellersOutput: {}, productDetailOutput: {}, bannerOutput: {}, relatedOutput: {}, reviewOutput: {}, latestViewed: latestViewed ? { listOutput: latestViewed} : {listOutput: { data: [] }}}

const actions = {

    getFeaturedList({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });

        commit('listRequest');
        
        PRODUCT_SERVICE.list(inputGetProducts)
            .then(
                output => {
                    commit('listSuccess', output );
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    getPublishDateList({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });

        commit('getPublishDateListRequest');
        
        PRODUCT_SERVICE.list(inputGetProducts)
            .then(
                output => {
                    commit('getPublishDateListSuccess', output );
                },
                error => {
                    commit('getPublishDateListFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    bestSellersList({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });

        commit('getBestSellersListRequest');
        
        PRODUCT_SERVICE.list(inputGetProducts)
            .then(
                output => {
                    commit('getBestSellersListSuccess', output );
                },
                error => {
                    commit('getBestSellersListFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    getDetail({ dispatch, commit }, slug) {
        dispatch('alert/clear', null, { root: true });
        commit('getProductRequest');
        
        PRODUCT_SERVICE.getDetail(slug)
        .then(
            output => {
                commit('getProductSuccess', output);
            },
            error => {
                    commit('getProductFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },

    getBannerList({ dispatch, commit }) {
        dispatch('alert/clear', null, { root: true });

        commit('bannerRequest');
        
        PRODUCT_SERVICE.getBannerList()
            .then(
                output => {
                    commit('bannerSuccess', output );
                },
                error => {
                    commit('bannerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getLatestList({ dispatch, commit }, product) {
        let result = state.listOutput ? UTILS_HELPER.deepClone(new Object, state.listOutput) : {data: []};
        if(product.id)
        {
            if(!result.data.find(x=> x.id == product.id))
            {
                if(result.data.length == 6)
                {     
                    result.data.pop();
                }
                result.data.unshift(product);
            }
        }
        dispatch('alert/clear', null, { root: true });
        commit('addLatestSuccess', result)  
    },
    getRelated({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });
        commit('getRelatedRequest');
        
        PRODUCT_SERVICE.getRelated(inputGetProducts)
        .then(
            output => {
                commit('getRelatedSuccess', output);
            },
            error => {
                    commit('getRelatedFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getReviews({ dispatch, commit }, inputGetProducts) {
        dispatch('alert/clear', null, { root: true });
        commit('getReviewRequest');
        
        PRODUCT_SERVICE.getReviews(inputGetProducts)
        .then(
            output => {
                commit('getReviewSuccess', output);
            },
            error => {
                    commit('getReviewFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest() {
        state.featuredOutput = null;
        // state.featuredOutput = UTILS_HELPER.deepClone(new Array, state.featuredOutput);  
    },
    listSuccess(state, output) {
        state.featuredOutput = output;
        // state.featuredOutput = UTILS_HELPER.deepClone(new Array, state.featuredOutput);  
    },
    listFailure(state) {
        state.featuredOutput = false;
        // state.featuredOutput = UTILS_HELPER.deepClone(new Array, state.featuredOutput);  
    },
    /* getPublishDate */
    getPublishDateListRequest() {
        state.publishDateOutput = null;
    },
    getPublishDateListSuccess(state, output) {
        state.publishDateOutput = output;
    },
    getPublishDateListFailure(state) {
        state.publishDateOutput = false;
    },
    /* getBestSellers */
    getBestSellersListRequest() {
        state.bestSellersOutput = null;
    },
    getBestSellersListSuccess(state, output) {
        state.bestSellersOutput = output;
    },
    getBestSellersListFailure(state) {
        state.bestSellersOutput = false;
    },
    /* getProductDetail */
    getProductRequest() {
        state.productDetailOutput = null;
    },
    getProductSuccess(state, output) {
        state.productDetailOutput = output;
    },
    getProductFailure(state) {
        state.productDetailOutput = false;
    },
    /* getBanners */
    bannerRequest() {
        state.bannerOutput = null;
    },
    bannerSuccess(state, output) {
        state.bannerOutput = output;
    },
    bannertFailure(state) {
        state.bannerOutput = false;
    },
    /* getLatestProducts */
    addLatestSuccess(state, output) {
        state.listOutput = output;
        localStorage.setItem('latestProducts', JSON.stringify(state.listOutput));
    },
    /* getRelated */
    getRelatedRequest() {
        state.relatedOutput = null;
    },
    getRelatedSuccess(state, output) {
        state.relatedOutput = output;
    },
    getRelatedFailure(state) {
        state.relatedOutput = false;
    },
    /* getReview */
    getReviewRequest() {
        state.reviewOutput = null;
    },
    getReviewSuccess(state, output) {
        state.reviewOutput = output;
    },
    getReviewFailure(state) {
        state.reviewOutput = false;
    },
};


export const products = {
    namespaced: true,
    state,
    actions,
    mutations
};
import { render, staticRenderFns } from "./TravelPaths.vue?vue&type=template&id=afffdd62&scoped=true&"
import script from "./TravelPaths.vue?vue&type=script&lang=js&"
export * from "./TravelPaths.vue?vue&type=script&lang=js&"
import style0 from "./TravelPaths.vue?vue&type=style&index=0&id=afffdd62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afffdd62",
  null
  
)

export default component.exports
<template>
    <div class="vueperslide__content-wrapper d-flex cursor-pointer" @click="sendClicked">
        <div class="teste position-relative">
            <img class="w-100 h-100 img" :src="imageUrl" alt="" loading="lazy">
            <div class="overlay">
                <a class="btn text-dec-none">{{$t('shop.viewProduct')}}</a>
            </div>
        </div>
        <div class="product-info mb-5 mt-2 w-100">
            <h5 class="product-name text-start">
                {{product.title}}
            </h5>
            <div class="d-flex justify-content-end product-price">
                <h1 class="fw-bold">{{product.finalPrice}}€</h1>
                <span class="mt-4">/Un</span>
            </div>
        </div>
    </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { mapActions } from 'vuex';

export default {
    props: {
        product: Object 
    },
    computed: {
        imageUrl: function()
        {
            var relativePath = this.product.featuredImagePath ? this.product.featuredImagePath : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
    },
    methods: {
        ...mapActions('products', ['getLatestList']),
        sendClicked () {
            this.$router.push({name: "ProductDetail", params: {ProductDetail: this.product, slug: this.product.slug} });
            this.getLatestList(this.product);
        },
    },

}
</script>

<style scoped>
.overlay {
  width: 100%;
  position: absolute;
  bottom: 3%;
  left: 0;
  right: 0;
}
.btn
{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 45px;
  font-weight: 900;
  padding: 25px;
  width: 95%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 2px solid #DDB427;
  color: black;
  background-color: #DDB427;
  border-radius: 0!important;
}
@media screen and (min-width: 991px) 
{
  .vueperslide__content-wrapper:hover .overlay {
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (max-width: 991px) 
{
  .product-info{
    margin-bottom: 10rem !important;
  }
}
@media (max-width: 576px) {
    .img{
        width: 100%!important;
    }
}
</style>
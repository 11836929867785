<template>
    <section>
        <div class="container p-0">
            <div class="col-12">
                <div id="canvas-container">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import VueThreeSixty from 'vue-360'
// import {PhotoSphereViewer} from 'photo-sphere-viewer';
// import { Viewer } from '@photo-sphere-viewer/core';

export default {
    data() {
        return {
            viewer: null
        };
    },
    mounted() {
        setTimeout(this.setupViewer, 1000);
    },
    methods: 
    {
        setupViewer: function()
        {
            const create360Viewer = require('360-image-viewer');
            // const canvasFit = require('canvas-fit');
            
            // load your image
            const image = new Image();
            // image.src = 'http://localhost:8080/180Image.jpeg';
            image.src = 'https://visittabuaco.pt/180Image.jpeg';
            
            image.onload = () => {
                // when the image is loaded, setup the viewer
                const viewer = create360Viewer({
                    image: image
                });
                // attach canvas to body
                var container = document.getElementById("canvas-container");
                container.appendChild(viewer.canvas);
                
                const resizeCanvas = () => {
                    const width = container.offsetWidth;
                    const height = container.offsetHeight;
                    const dpr = window.devicePixelRatio;

                    viewer.canvas.width = width * dpr;
                    viewer.canvas.height = height * dpr;
                    viewer.canvas.style.width = `${width}px`;
                    viewer.canvas.style.height = `${height}px`;
                };

                window.addEventListener('resize', resizeCanvas);
                window.dispatchEvent(new Event('resize'));

                viewer.start();
            }
        }
    },
    beforeDestroy() {
        this.viewer.destroy();
    }
}
</script>

<style scoped>
.title{
    height: 60px;
}
#canvas-container
{
    width: 100%;
    min-height: 700px;
    cursor: grab;
}
</style>
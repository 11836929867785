import { RESERVATIONS_SERVICE } from "@/services/reservations.service.js";

const state = { listOutput: null, detailOutput: null, processTask: undefined};

const actions = {
    getList({ dispatch, commit }, inputGetReservations) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        RESERVATIONS_SERVICE.list(inputGetReservations)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetails({ dispatch, commit }, id) {
        dispatch('alert/clear', null, { root: true });
        commit('listDetailRequest');
        
        RESERVATIONS_SERVICE.getDetails(id)
            .then(
                output => {
                    commit('listDetailSuccess', output);
                },
                error => {
                    commit('listDetailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    reservationEmail({ dispatch, commit },  inputMessage ) {
        dispatch('alert/clear', null, { root: true });
        commit('reservationEmailRequest');
        
        RESERVATIONS_SERVICE.reservationEmail(inputMessage)
            .then(
                output => {
                    commit('reservationEmailSuccess', output);
                    dispatch('alert/success', output, { root: true });

                },
                error => {
                    commit('reservationEmailFailure', error);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = false;
    },
    /* Detail */
    listDetailRequest(state) {
        state.detailOutput = null;
    },
    listDetailSuccess(state, output) {
        state.detailOutput = output;
    },
    listDetailFailure(state) {
        state.detailOutput = false;
    },
    /* reservationEmail */
    reservationEmailRequest(state) {
        state.processTask = null;
    },
    reservationEmailSuccess(state) {
        state.processTask = true;
    },
    reservationEmailFailure(state) {
        state.processTask = false;
    }
};

export const reservations = {
    namespaced: true,
    state,
    actions,
    mutations
};
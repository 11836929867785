<template>
   <div id="cart">
      <div class="container">
          <!-- Title -->
          <div class="row">
              <div class="col-12 mt-4">
                <h3 class="text-center font-weight-bold">{{$t("cart.tripBag")}}</h3>
              </div>
          </div>
          <!-- Progress bar -->
          <div v-if="cartItems.length" class="row mt-5">
              <ul class="progressbar d-flex justify-content-center">
                <li v-for="(step, index) in steps" :key="index" v-bind:class="{ active: index == getStepIndex(currentStep), completed: index < getStepIndex(currentStep)}">{{step.text}}</li>
              </ul>
          </div>
          <!-- Cart --> 
          <div v-if="currentStep == CARTACTION" class="table-responsive mt-5 mb-5">
              <table class="table">
                <!-- Header -->
                <thead  class="text-center">
                    <tr class="border-0">
                      <th scope="col" class="border-0 background">
                          <div class="p-2 px-3 text-light">{{$t("cart.prod")}}</div>
                      </th>
                      <th scope="col" id="quantity" class="border col-2 border-light background">
                          <div class="py-2 text-light">{{$t("cart.quant")}}</div>
                      </th>
                      <th scope="col" id="sub-total" class="border border-light background">
                          <div class="py-2 text-light">{{$t("cart.subTotal")}}</div>
                      </th>
                      <th scope="col" id="actions" class="border border-light background">
                          <div class="py-2 text-light">{{$t("cart.act")}}</div>
                      </th>
                    </tr>
                </thead>
                <!-- Products -->
                <tbody>
                    <tr v-for="(cartItem, index) in cartItems" :key="index"> 
                      <th scope="row" class="border border-light background-body-color cursor-pointer" @click="productClicked(cartItem.product)">
                          <div class="p-2">
                            <div class="ml-3 d-inline-block align-middle">
                                <div>
                                  <h5 class="mb-0"> 
                                    {{cartItem.product.title}}
                                  </h5>
                                  <p class="font-weight-normal" v-for="(customField, index) in cartItem.customFields" :key="index"><span class="font-weight-bold">{{customField.name}}: </span>{{customField.value}}</p>
                                </div>
                            </div>
                          </div>
                      </th>
                      <td class="border border-light align-middle background-body-color text-center">
                          <input :min="0" @change="quantityChanged(cartItem)" class="form-control col-6" v-model="cartItem.quantity" type="number" :disabled="loading">
                      </td>
                      <td class="border border-light align-middle background-body-color text-center"><strong>{{(cartItem.product.finalPrice * cartItem.quantity).toFixed(2)}} €</strong></td>
                      <td class="border border-light align-middle background-body-color text-center"><a href="#" class="text-dark"><img class="icon-delete" src="../assets/Cart/delete.svg" loading="lazy" :disabled="loading" @click="removeCartItem(cartItem)"/></a></td>
                    </tr>
                    <div v-if="!cartItems.length">
                      <h5 class="mb-2 mt-2 align-middle background-body-color text-center"> 
                          {{$t("cart.tripBagEmpty")}}
                      </h5>
                    </div>
                </tbody>
                <!-- Footer -->
                <tbody>
                    <tr class="text-center">
                      <td class="border-0"></td>
                      <td v-if="cartItems.length" class="border border-light align-middle background text-light"><strong>{{$t("cart.total")}}</strong></td>
                      <td v-if="cartItems.length" class="border border-light align-middle background-body-color"><strong>{{totalValue}} €</strong></td>
                    </tr>
                </tbody>
              </table>
              <!-- Action buttons -->
              <div class="button mt-5">
                <button v-if="cartItems.length" class="btn1" @click="contAdd" >{{$t("cart.contAdd")}}</button>
                <button v-if="cartItems.length" class="btn2" v-bind:class="{ disabled : currentStep == steps.length}" @click="doNext">
                    <span v-show="!loading" class="text-uppercase">{{$t("cart.next")}}</span>
                    <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                </button>
              </div>
          </div>
          <!-- Info -->
          <div v-if="currentStep == INFO" class="info mt-5">
              <div class="row back-color">
                <!-- Title -->
                <div class="about-you d-flex justify-content-center align-items-center">{{$t("cart.aboutYou")}}</div>
                <div class="col-lg-3"></div>
                <!-- Form -->
                <div class="col-lg-6">
                    <div id="ui">
                      <form class="form-group" currentStep="" >
                          <div class="row">
                            <div class="col-lg-6">
                                <label for="">{{$t("cart.firstName")}}</label>
                                <input v-model="billingAddress.firstName" id="firstName" name="firstName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.firstName.$error}">
                                <div v-if="submitted && $v.billingAddress.firstName.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.firstName.required">{{$t("cart.valFirstName")}}</span> 
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="">{{$t("cart.lastName")}}</label>
                                <input v-model="billingAddress.lastName" id="lastName" name="lastName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.lastName.$error}">
                                <div v-if="submitted && $v.billingAddress.lastName.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.lastName.required">{{$t("cart.valLastName")}}</span> 
                                </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-lg-6">
                                <label for="">{{$t("cart.contactNumber")}}</label>
                                <input v-model="billingAddress.phoneNumber" id="phoneNumber" name="phoneNumber" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.phoneNumber.$error}">
                                <div v-if="submitted && $v.billingAddress.phoneNumber.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.phoneNumber.required">{{$t("cart.valContactNumber")}}</span> 
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="">E-mail</label>
                                <input v-model="billingAddress.email" id="email" name="email" type="email" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.email.$error}">
                                <div v-if="submitted && $v.billingAddress.email.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.email.required">{{$t("cart.emailReq")}}</span> 
                                  <span v-if="!$v.billingAddress.email.email">{{$t("cart.emailInv")}}</span>
                                </div>
                            </div>
                          </div>
                          <div class="row mt-4">
                            <div class="col-lg-6">
                                <label for="">{{$t("cart.nat")}}</label>
                                <input v-model="billingAddress.nationality" id="nationality" name="nationality" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.nationality.$error}">
                                <div v-if="submitted && $v.billingAddress.nationality.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.nationality.required">{{$t("cart.valNat")}}</span> 
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <label for="">{{$t("cart.vat")}}</label>
                                <input v-model="billingAddress.vat" id="vat" name="vat" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.vat.$error}">
                                <div v-if="submitted && $v.billingAddress.vat.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.vat.required">{{$t("cart.valVat")}}</span> 
                                </div>
                            </div>
                          </div>
                          <label class="mt-4" for="">{{$t("cart.bilAd")}}</label>
                          <input  v-model="billingAddress.address" id="address" name="address" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.address.$error}">
                          <div v-if="submitted && $v.billingAddress.address.$error" class="invalid-feedback">
                            <span v-if="!$v.billingAddress.address.required">{{$t("cart.valBilAd")}}</span> 
                          </div>
                          <div class="row mt-4">
                            <div class="col-lg-4">
                                <label for="">{{$t("cart.zip")}}</label>
                                <input v-model="billingAddress.zipCode" id="zipCode" name="zipCode" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.zipCode.$error}">
                                <div v-if="submitted && $v.billingAddress.zipCode.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.zipCode.required">{{$t("cart.valZip")}}</span> 
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="">{{$t("cart.city")}}</label>
                                <input v-model="billingAddress.city" id="city" name="city" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.city.$error}">
                                <div v-if="submitted && $v.billingAddress.city.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.city.required">{{$t("cart.valCity")}}</span> 
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="">{{$t("cart.country")}}</label>
                                <input v-model="billingAddress.country" id="country" name="country" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.billingAddress.country.$error}">
                                <div v-if="submitted && $v.billingAddress.country.$error" class="invalid-feedback">
                                  <span v-if="!$v.billingAddress.country.required">{{$t("cart.valCountry")}}</span> 
                                </div>
                            </div>
                          </div>
                      </form>
                    </div>
                </div>
                <div class="col-lg-3"></div>
              </div>
              <hr class="line">
              <!-- Action buttons -->
              <div class="button mt-5">
                <button @click="doPrev()" class="btn1">{{$t("cart.prevStep")}}</button>
                <button class="btn2" v-bind:class="{disabled: !isCompleted}" @click="infoSubmit">{{$t("cart.next")}}</button>
              </div>
          </div>
          <!-- Payment method -->
          <div v-if="!isconcluded">
            <div v-if="currentStep == PAYMENT || currentStep == CONFIRMORDER" v-bind:class="{invisible: currentStep == CONFIRMORDER}" class="container mt-5"> <!-- v-if is not used in order to preserve the elements for the payment; this can also be used for  -->
              <div class="row header-title p-1">
                  <div class="col-12 d-flex justify-content-center align-items-center">{{$t("cart.payMethod")}}</div>
              </div>
              <div class="col-12 mt-5 mb-5">
                <!-- Bank Transfer -->
                <div class="row row-bank-style">
                    <div class="col-12">
                        <input class="cursor-pointer" type="radio" value="BankTransfer" v-model="paymentMethod" id="scales" name="bank">
                        <label class="label-bank-class label-title" for="scales">{{ $t("checkout.bankTransf") }}</label>
                        <p v-show="paymentMethod !== 'BankTransfer' ">{{ $t("checkout.bankTransfDesc") }}</p>
                    </div>
                    <!-- Show more -->
                    <div v-show="paymentMethod === 'BankTransfer' " class="col-12 showMore">
                        <div class="row w-100">
                            <div class="col-12">
                                <label for="CardNumber" class="mb-2 label-box">{{$t("checkout.iban")}}</label>
                                <div id="account-name" class="input-pay">
                                    <input class="w-100" type="text" v-model="iban">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Multibanco -->
                    <div class="col-12 mt-3">
                        <input class="cursor-pointer" type="radio" value="Multibanco" v-model="paymentMethod" id="scales" name="bank">
                        <label class="label-bank-class label-title" for="scales">{{ $t("checkout.ATM") }}</label>
                        <p>{{ $t("checkout.ATMdesc") }}</p>
                    </div>
                    <!-- MBWAY -->
                    <div class="col-12 mt-3">
                        <input class="cursor-pointer" type="radio" value="MBWay" v-model="paymentMethod" id="scales" name="bank">
                        <label class="label-bank-class label-title" for="scales">{{ $t("checkout.mbWay") }}</label>
                        <p>{{ $t("checkout.mbWayDesc") }}</p>
                    </div>
                    <!-- Show more -->
                    <div v-show="paymentMethod === 'MBWay' " class="col-12 showMore">
                        <div class="row w-100">
                            <div class="col-12">
                                <span>{{ $t("checkout.mbwayAlert") }}</span>
                            </div>
                            <!-- <div class="col-12">
                                <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="phoneNumberMBWay" />
                            </div> -->
                        </div>
                    </div>
                    <!-- CC-EasyPay -->
                    <div class="col-12 mt-3">
                        <input class="cursor-pointer" type="radio" value="CC" v-model="paymentMethod" id="scales" name="bank">
                        <label class="label-bank-class label-title" for="scales">{{ $t("checkout.card") }}</label>
                        <p>{{ $t("checkout.cardDesc") }}</p>
                    </div>

                    <div class="horizontal-line mt-4"></div>
                </div>
              </div>
              <!-- Actions -->
              <div class="button mt-5">
                <button @click="doPrev()" class="btn1">{{$t("cart.prevStep")}}</button>
                <button class="btn2" v-bind:class="{disabled: !paymentMethod}" @click="doNext">{{$t("cart.next")}}</button>
              </div>
            </div>
          </div>
          <!-- Confirm -->
          <div v-if="currentStep == CONFIRMORDER && cartItems.length" class="container mt-5">
            <!-- Title -->
            <div class="row header-title p-1 m-0">
                <div class="col-12 d-flex justify-content-center align-items-center">{{$t("cart.confOrd")}}</div>
            </div>
            <!-- Product info -->
            <table class="table">
                <!-- Header -->
                <thead  class="text-center">
                  <tr class="border-0">
                      <th scope="col" class="border-0 background">
                        <div class="p-2 px-3 text-light">{{$t("cart.prod")}}</div>
                      </th>
                      <th scope="col" class="border col-2 border-light background">
                        <div class="py-2 text-light">Quant.</div>
                      </th>
                      <th scope="col" class="border border-light background">
                        <div class="py-2 text-light">Sub-Total</div>
                      </th>
                  </tr>
                </thead>
                <!-- Products -->
                <tbody>
                  <tr v-for="item in cartItems" :key="item.key">
                      <th scope="row" class="border border-light background-body-color cursor-pointer" @click="productClicked(item)">
                        <div class="p-2">
                            <div class="ml-3 d-inline-block align-middle">
                              <div>
                                  <h5 class="mb-0"> 
                                    {{item.product.title}}
                                  </h5>
                                  <span v-html="item.product.description" class="text-muted d-block"></span>
                              </div>
                            </div>
                        </div>
                      </th>
                      <td class="border border-light align-middle background-body-color text-center">
                        <span>{{item.quantity}}</span>
                      </td>
                      <td class="border border-light align-middle background-body-color text-center"><strong>{{(item.product.finalPrice * item.quantity).toFixed(2)}} €</strong></td>
                  </tr>
                </tbody>
                <!-- Footer -->
                <tbody>
                  <tr class="text-center">
                      <td class="border-0"></td>
                      <td class="border border-light align-middle background text-light"><strong>{{$t("cart.total")}}</strong></td>
                      <td class="border border-light align-middle background-body-color"><strong>{{totalValue}} €</strong></td>
                  </tr>
                </tbody>
            </table>
            <!-- Actions -->
            <div class="button mt-5">
                <button @click="doPrev()" class="btn1">{{$t("cart.prevStep")}}</button>
                <button class="btn2" @click="onCreateOrderRequested">
                <span v-show="!loading" class="text-uppercase">{{$t("cart.conf")}}</span>
                <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-show="loading" class="sr-only">{{$t("loading")}}</span>
                </button>
            </div>
        </div>
        <div v-if="currentStep == AtmOrCC" class="container mt-5">
            <!-- Order finished -->
              <div class="d-flex flex-column h-100-vh justify-content-center">
                  <div v-if="paymentMethod == 'Multibanco'" class="d-flex justify-content-center align-items-center flex-column">
                      <h2 class="d-flex justify-content-center shopEmpty-style align-items-center py-4">{{$t("checkout.finishOrder")}}</h2>
                      <h4 class="fw-bold">{{$t("checkout.entity")}}<span class="fw-normal">entidade</span></h4>
                      <h4 class="fw-bold">{{$t("checkout.reference")}}<span class="fw-normal">referência</span></h4>
                      <h4 class="fw-bold">{{$t("checkout.value")}}<span class="fw-normal">{{totalValue}}€</span></h4>
                  </div>
                  <div v-else-if="paymentMethod == 'CC'" class="d-flex justify-content-center align-items-center flex-column">
                      <h2 class="d-flex justify-content-center shopEmpty-style align-items-center py-4">{{$t("checkout.processOrder")}}</h2>
                      <p>{{$t("checkout.orderLink")}}</p>
                      <a class="fw-bold" href="www.google.com" target="_blank">{{$t("checkout.linkHere")}}</a>
                      <!-- <a class="fw-bold" :href="payMethod.url" target="_blank">{{$t("checkout.linkHere")}}</a> -->
                  </div>
                  <!-- <router-link class="d-flex justify-content-center" :to="{name: 'MyAccount'}"><button class="btn btn-primary my-5">{{$t("checkout.reviewOrder")}}</button></router-link> -->
              </div>
        </div>
      </div>
   </div>
</template>
<script>
import { required, email} from "vuelidate/lib/validators";
import { mapState, mapActions } from 'vuex';
import { API_HELPER } from "@/helpers/api.js";
// import VuePhoneNumberInput from 'vue-phone-number-input';

const CARTACTION = 0;
const INFO = 1;
const PAYMENT = 2;
const CONFIRMORDER = 3;
const AtmOrCC = 4;


export default {
  // components: {
  //       VuePhoneNumberInput
  //   },
    metaInfo () {
      return { 
            title: `${this.$t("vueRouter.cartTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.cartDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.cart", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.cart", 'pt')])},
            ]
            
        } 
    },
  data(){
    return{
      // Structure
      currentStep: CARTACTION,
      // Actions
      submitted: false,
      // User info
      billingAddress:
      {
        firstName: "",
        lastName: "",
        email:"",
        phoneNumber: "",
        nationality:"",
        vat:"",
        address: "",
        zipCode:"",
        city:"",
        country:"",
      },
      shippingAddress:
      {
        firstName: "",
        lastName: "",
        email:"",
        phoneNumber: "",
        nationality:"",
        vat:"",
        address: "",
        zipCode:"",
        city:"",
        country:"",
      },
      // Global payment info
      phoneNumberMBWay:"",
      paymentMethod: "",
      iban: "",
      submittedTotalValue: null,
      isconcluded: false,
      // Expose for HTML
      CARTACTION: CARTACTION,
      INFO: INFO,
      PAYMENT: PAYMENT,
      CONFIRMORDER: CONFIRMORDER,
      AtmOrCC: AtmOrCC,
    }
  },
  validations: {
    billingAddress: {
      firstName: { required},
      lastName: { required},
      email: { required, email},
      phoneNumber: { required},
      nationality: { required},
      vat: { required},
      address: { required},
      zipCode: { required},
      city: { required},
      country: { required},
    },
    // shippingAddress: {
    //   firstName: { required},
    //   lastName: { required},
    //   email: { required, email},
    //   phoneNumber: { required},
    //   nationality: { required},
    //   vat: { required},
    //   address: { required},
    //   zipCode: { required},
    //   city: { required},
    //   country: { required},
    // }
  },
  methods:{
    ...mapActions("cart", ["getList"]),
    ...mapActions("cart", ["manageCart"]),
    ...mapActions("orders", ["createOrder"]),
    ...mapActions({
            clearAlert: 'alert/clear' 
        }),
    infoSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      console.log(this.$v)
      if (this.$v.$invalid) {
        return ;
      }
      this.currentStep = this.PAYMENT;
      return true;
    },
    getStepIndex(step)
    {
      return this.steps.indexOf(this.steps.find(x=> x.step == step));
    },
    doPrev: function() {
      var indexOfAction = this.getStepIndex(this.currentStep);

      if (indexOfAction > 0) {
        this.currentStep = this.steps[indexOfAction - 1].step;
      }
    },
    doNext: function() {
      var indexOfAction = this.getStepIndex(this.currentStep);

      if(this.currentStep == PAYMENT)
      {
        this.currentStep = this.steps[indexOfAction + 1].step;
      }else{
        if (indexOfAction < this.steps.length) {
          this.currentStep = this.steps[indexOfAction + 1].step;
        }
      }
    },
    quantityChanged(cartItem)
    {
      if(cartItem.quantity < 0)
      {
        cartItem.quantity = 1;
      }
      else if(cartItem.quantity == 0)
      {
        this.removeCartItem(cartItem, function() {cartItem.quantity = 1});
      }
      else 
      {
        var productId = cartItem.product.id;
        const quantity = cartItem.quantity;
        var customFields = cartItem.customFields;

        this.manageCart({productId, quantity, customFields });
      }
    },
    removeCartItem(cartItem, secondaryCallback)
    {
      const self = this;

      var productId = cartItem.product.id;
      const quantity = 0;
      var customFields = cartItem.customFields;

      this.$emit("warning-alert-requested", { message: this.$t('warningDelete'), primaryButton: this.$t("yesPopUp"), primaryCallback: () =>
      {
        self.manageCart({productId, quantity, customFields });
      }, secondaryButton: this.$t("noPopUp"), secondaryCallback: secondaryCallback});
    },
    productClicked(product)
    {
      this.$router.push({name: "ProductDetail", params: { slug: product.slug} });
    },
    onCreateOrderRequested: function()
    {
      this.submittedTotalValue = this.totalValue;

      const billingAddress = this.billingAddress;
      const shippingAddress = this.billingAddress;
      const paymentMethod = this.paymentMethod;
      const iban = this.iban;

          if(this.paymentMethod === 'BankTransfer')
          { 
              this.createOrder({billingAddress, shippingAddress, paymentMethod, iban});            
          }
          else if(this.paymentMethod === 'MBWay')
          {
              // const phoneNumber = this.phoneNumberMBWay;
              const phoneNumber = this.billingAddress.phoneNumber;
              console.log(phoneNumber)

              this.createOrder({billingAddress, shippingAddress, paymentMethod, phoneNumber : phoneNumber});
          }
          else{
              this.createOrder({billingAddress, shippingAddress, paymentMethod});
          }
    },
    contAdd: function()
      {
        this.$router.push({name: "Shop"});
      },
    goHome: function()
      {
        this.$router.push({name: "Home"});
      }
  },
  computed: {
    ...mapState('cart', ['cartItems']),
    ...mapState('cart', ['processTask']),
    ...mapState('account', ['user']),
    ...mapState('orders', ['order']),
    ...mapState({ 
      alertType: state => state.alert.type,
    }),
    loading(){
      return this.cartItems === {} || this.processTask === null || this.order === null;
    },
    isCompleted() {
      return  this.billingAddress.firstName && this.billingAddress.lastName && this.billingAddress.email && this.billingAddress.phoneNumber && this.billingAddress.nationality && this.billingAddress.vat && this.billingAddress.address && this.billingAddress.zipCode && this.billingAddress.city && this.billingAddress.country;
    },
    totalValue() {
      let total = 0;
      this.cartItems === null ? '' : this.cartItems.forEach((item) => {
          total += item.product.finalPrice * item.quantity
        });
      
      return total.toFixed(2);
    },
    steps: function(){
      var result = [ { step: CARTACTION, completed: false, text: this.$t("cart.tripBag")}];
      result.push({step: INFO, completed: false, text: this.$t("cart.info")});
      result.push({step: PAYMENT, completed: false, text: this.$t("cart.payment")});
      result.push({step: CONFIRMORDER, completed: false, text: this.$t("cart.conf")});
      if(this.paymentMethod === 'Multibanco' || this.paymentMethod === 'CC'){
        result.push({step: AtmOrCC, completed: false, text: this.$t("cart.AtmOrCC")});
      }

      return result;
    }
  },
  watch: {
    paymentMethod(val)
    {
      if(val)
      {
        this.iban = "";
      }
    },
    currentStep: function()
    {
      // Scroll to top
      window.scrollTo(0, 0);
    },
    order: function(val)
    {
        if(val != null)
        {
            if(val)
            {
                this.isconcluded = true;
                if(this.paymentMethod === 'Multibanco' || this.paymentMethod === 'CC'){

                  this.$emit("success-alert-requested", { message: this.$t("checkout.finishOrder"), primaryButton: this.$t("Ok"), primaryCallback: this.doNext});
                }
                else{
                  this.$emit("success-alert-requested", { message: this.$t("checkout.finishOrder"), primaryButton: this.$t("Ok"), primaryCallback: this.goHome });
                }
            }
            else{
                this.isconcluded = false;
                this.$emit("error-alert-requested", { message: this.$t("checkout.orderError"), primaryButton: this.$t("close") });
            }
        }
        setTimeout(function () {
            window.scrollTo(0, 0);
        },100);
    },
    processTask: function(val)
    {
      if(val !== null)
      {
        this.getList();
      }
    }
  },
  mounted(){
    // Get cartItems on cart
    this.getList();
  },
};
</script>

<style scoped>
#cart{
  min-height: 1080px;
  display: flex;
  align-items: center;
}
.background{
  background-color:#DDB427;
}
 .progressbar{
  counter-reset: step;
}
.progressbar li{
  list-style-type: none;
  float: left;
  width: 24.33%;
  position: relative;
  text-align: center;
  word-break: break-all;
} 
.progressbar li.completed {
  color: #ccc;
}
.progressbar li:before{
  content: counter(step);
  counter-increment: step;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 3px solid #E6E6E6;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.progressbar li::after{
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ddd;
  top: 25px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active{
  color: #DDB427;
}
.progressbar li.active:before{
  background: #DDB427;
  color: white;
}
.progressbar li.completed::before{
  background: #4caf50;
  color: white;
}
.total{
  margin-top: 15px;
}
.total .item{
  padding: 8px;
}
.total .item-total{
  background-color: #DDB427;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 -10px;
  color: white;
  font-weight: bold;
}
.total .item-subtotal{
  background-color: #E6E6E6;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 -10px;
  color: black;
  font-weight: bold;
}
#ui{
  padding: 30px;
}
.back-color{
  background-color: #E6E6E6;
}
.about-you{
  background-color: #DDB427;
  padding: 8px;
  color: white;
}
.label-title{
  color: #DDB427;
}
.label-box{
  color: white;
}
.form-control{
  border: 1px solid #DDB427;
}
>>>textarea:focus , >>>input:focus{
  border-color: #DDB427;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(150, 36, 34, 0.075);
  outline: 0 none;
}
.background-body-color
{
  background-color: #E6E6E6;
}
.line{
  width: 100%;
  border: 2px solid #DDB427;
  opacity: 1;
}
.header-title{
    background: #DDB427;
    color: white;
    font-weight: bold;
}
.body-content{
    background: #E6E6E6;
    margin-top:3px
}
.form-check-input:checked {
    background-color: #DDB427;
    border-color: #DDB427;
}
.showMore{
  padding: 70px 0;
  background-color: #DDB427;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-pay > input
{
  line-height: 1.7;
  width: 100%;
  text-align: center;
}
::-moz-placeholder {
  color: #999;
}
:-ms-input-placeholder {
  color: #999;
}
::-webkit-input-placeholder {
  color: #999;
}
.button {
  display: flex;
  justify-content: space-evenly;
  padding: 40px;
}
.btn1 {
  background: white;
  color: #DDB427;
  padding: 5px 100px;
  font-size: 20px;
  border: 1px solid #DDB427;
}
.btn2 {
  background: #DDB427;
  color: white;
  padding: 5px 100px;
  font-size: 20px;
}
.btn:hover {
  color: white;
}
.icon-delete{
  object-fit: cover;
  height: 50px;
}
#quantity{
  width: 10%!important;
}
#actions{
  width: 10%;
}
#sub-total{
  width: 15%;
}
input[type="checkbox"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 18px;
  height: 18px;
  /* background-color only for content */
  background-clip: content-box;
  border: 4px solid #DDB427;
  background-color: unset;
  border-radius: 10px;
}
/* appearance for checked radiobutton */
input[type="checkbox"]:checked {
  background-color: black!important;
}
.label-bank-class{
    margin-left: 1rem;
    font-size: 17px;
    font-weight: bold;
}
.row-bank-style{
    margin-left: 3rem;
    margin-right: 3rem;
}
.row-subtotal-style{
    margin-left: 10rem;
    margin-right: 10rem;
}
@media screen and (max-width: 991px) {
  .btn1, .btn2
  {
     padding: 0px 35px;
    margin: 0 20px;
    font-size: 20px;
  }
  .showMore{
    min-height: 500px;
  }
}
@media screen and (max-width: 546px) {
  .btn1, .btn2
  {
     padding: 0px 35px;
    margin: 0 20px;
    font-size: 15px;
}
}
@media screen and (max-width: 400px) {
  .btn1, .btn2
  {
    padding: 0px 30px;
    margin: 0 20px;
    font-size: 14px;
  }
  .progressbar li{
    font-size: 12px;
  }
  th{
    font-size: 12px;
  }
}
.invisible
{
  visibility: hidden !important;
  height: 0 !important;
  margin: 0 !important;
}
.pay-icon
{
  height: 25px;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import { alert} from '@/store/alert.module';
import { hero } from '@/store/hero.module';
import { news } from '@/store/news.module';
import { pois } from '@/store/pois.module';
import { events } from '@/store/events.module';
import { products } from '@/store/products.module';
import { gallery } from '@/store/gallery.module';
import { categories } from '@/store/categories.module';
import { pages } from '@/store/pages.module';
import { faqs } from '@/store/faqs.module';
import { routes } from '@/store/routes.module';
import { reservations } from '@/store/reservations.module';
import { newsletter } from '@/store/newsletter.module';
import { favorites } from '@/store/favorites.module';
import { account } from '@/store/account.module';
import { cart } from '@/store/cart.module';
import { orders } from '@/store/orders.module';
import { message } from '@/store/message.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    hero,
    news,
    pois,
    events,
    gallery,
    categories,
    products,
    pages,
    faqs,
    routes,
    reservations,
    newsletter,
    favorites,
    account,
    cart,
    orders,
    message
  }
})

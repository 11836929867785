<template>
  <section>
    <div id="image-BG">
      <div class="container my-5">
        <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-8">
            <h1>{{ newsDetail.title }}</h1>
            <hr />
            <span class="font-clanpro-bold">{{ date }}</span> |
            <span class="font-clanpro-bold">{{ categorie[0].name }}</span>
            <hr />
            <span class="font-clanpro" v-html="newsDetail.content"></span>
          </div>
          <!-- XS, SM Gallery -->
          <ThreePartGallery v-if="gallery != null" class="col-12 d-md-none my-3" :gallery="gallery"></ThreePartGallery>
          <!-- MD+ Gallery -->
          <ThreePartGallery v-if="gallery != null" class="d-none d-md-block col-4 my-5" :gallery="gallery"></ThreePartGallery>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import ThreePartGallery from "@/components/ThreePartGallery.vue";
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    newsDetail: Object,
    categorie: Array,
  },
  components: {
    ThreePartGallery,
  },
  computed: {
    date() {
      return UTILS_HELPER.formatDate(this.newsDetail.publishDate);
    },
    gallery: function()
    {
      let images = this.newsDetail.imagesPath;
      images.push(this.newsDetail.featuredImagePath);
      return images.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file]));
    }
  },
};
</script>

<style scoped>
.image-style {
  object-fit: cover;
}
#image-BG {
  background-image: url("../assets/shop/tabuacoBG.svg");
  background-repeat: no-repeat;
  object-fit: cover;
}
@media (max-width: 850px) {
  #image-BG {
    background-image: none;
  }
}
@media (max-width: 767px) {
  .image-style {
    margin-top: 2rem;
  }
}
</style>

<template>
  <section id="WhereStayDetail">
    <LogoLoading v-if="!poiContent"/>
    <div v-else>
      <PoiDetail :poiDetail="poiContent" />
    </div>
  </section>
</template>

<script>
import PoiDetail from "@/components/PoiDetail.vue";
import "vueperslides/dist/vueperslides.css";
import router from "@/router";
import LogoLoading from "@/components/LogoLoading.vue"
import { mapState, mapActions } from "vuex";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  components: {
    PoiDetail,
    LogoLoading,
  },
  data: function () {
    return {
      poiContent: null,
      title: "",
    };
  },
   metaInfo () {
    var result =
    { 
        title: this.poiContent ? `${this.poiContent.title} - Tabuaço` : "Tabuaço", 
        htmlAttrs: {
            lang: this.$i18n.locale,
        },
        meta: 
        [
            {name: 'description', content: this.poiContent && this.poiContent.content ? UTILS_HELPER.htmlToText(this.poiContent.content) : this.$t("intro")},
            {name: 'keywords', content: this.poiContent && this.poiContent.categories && this.poiContent.categories.length ? this.poiContent.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.poiContent ? `${this.poiContent.name} - Tabuaço` : "Tabuaço"},
            {name: 'twitter:description', content: this.poiContent && this.poiContent.content ? UTILS_HELPER.htmlToText(this.poiContent.content) : this.$t("intro")},
            {name: 'twitter:image', content: this.featuredImagePath ? this.featuredImagePath : API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.poiContent ? `${this.poiContent.name} - Tabuaço` : "Tabuaço"},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: this.featuredImagePath ? this.featuredImagePath : API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.poiContent && this.poiContent.content ? UTILS_HELPER.htmlToText(this.poiContent.content) : this.$t("intro")},
        ]
    } 

    if(this.$route.query.id)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.poisDetail", 'pt')])}?id=${this.$route.query.id}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.poisDetail", 'en')])}?id=${this.$route.query.id}`},
        ];
    }

    return result;
  }, 
  mounted() {
    this.initializeParams();
  },
  methods: {
    ...mapActions("pois", ["getDetail"]),
    initializeParams: function () {
      if (!this.$route.query.id) {
        router.push("/");
      } else {
           if(this.$route.params.poiContent)
            {
              this.poiContent = this.$route.params.poiContent;
            }
            else
            {
              this.getDetail(this.$route.query.id);
            }
        }
    },
  },
  computed: {
    ...mapState("pois", ["poiOutput"]),
    loadingContent: function () {
      return this.poiOutput == null;
    },
  },
  watch: {
    poiOutput: function (val) {
      if (val != null) {
        if (val) {
          this.poiContent = val;
        } else {
          router.push("/");
        }
      }
    },
  },
};
</script>

<style scoped>
.back {
  cursor: pointer;
}
h5,
h3 {
  display: inline;
}
.box1 {
  background-size: cover;
  cursor: pointer;
  max-width: 410px;
  max-height: 300px;
}
.box1 img {
  max-height: 300px;
}
.box2 {
  background-size: cover;
  cursor: pointer;
  max-width: 205px;
  max-height: 140px;
}
.box2 img {
  max-height: 140px;
}
.box3 {
  background-size: cover;
  cursor: pointer;
  max-width: 205px;
  max-height: 140px;
}
.box3 img {
  max-height: 140px;
}
@media (max-width: 768px) {
  .box1 {
    max-width: none;
    max-height: none;
  }
  .box2 {
    max-width: none;
    max-height: none;
  }
  .box3 {
    max-width: none;
    max-height: none;
  }
}
</style>

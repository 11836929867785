<template>
    <div v-if="newsContent" id="new">
        <!-- mobile -->
        <transition name="slide">
            <div @click="mobileClick = !mobileClick" class="d-lg-none card border-0" id="wrapper-mobile">
                <img class="h-100 w-100" :src="imageUrl" alt="" loading="lazy"> 
                <div class="card-img-overlay">
                    <div class="row text-center m-0 py-2">
                        <div class="col-12 d-flex justify-content-end">
                            <div class="circle">
                                <p class="read-time m-0">{{$t('readTime')}}</p>
                                <p class="time fw-bold m-0">{{readTime}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row m-0 position-absolute info">
                        <div class="col-6 p-0 background-color-primary d-flex justify-content-center align-items-center" :style="{height: '20px'}">
                            <p class="card-info-date text-light m-0">{{ date }}</p>
                        </div>
                        <div class="col-6 col-lg-4 category d-flex justify-content-center align-items-center" 
                            :style="{'background-color': categoryBackgroundColor, height: '20px'}">
                            <span class="card-info-category text-light">{{category}}</span>
                        </div>
                    </div>
                </div>
                <div @click="sendClicked" v-show="mobileClick" class="overlay slide-up">
                    <div class="more-info d-flex flex-column h-auto">
                        <div class="row m-0 position-absolute info-click py-3">
                            <div class="col-6 p-0 d-flex justify-content-center align-items-center" 
                                :style="{'background-color': '#000000', height: '20px'}">
                                <p class="card-info-date text-light m-0">{{ date }}</p>
                            </div>
                            <div class="col-6 col-lg-4 category d-flex justify-content-center align-items-center" 
                                :style="{'background-color': categoryBackgroundColor, height: '20px'}">
                                <span class="card-info-category text-light">{{category}}</span>
                            </div>
                            <h5 class="fw-bold card-title text-light py-1">{{newsContent.title}}</h5>
                            <!-- tablet -->
                            <p class="fadeout d-none d-sm-flex text-dark m-0 p-0 description flex-grow-1">{{excerpt(EXCERPT_LENGTH_TABLET)}}</p>
                            <!-- mobile -->
                            <p class="fadeout d-flex d-sm-none text-dark m-0 p-0 description flex-grow-1">{{excerpt(EXCERPT_LENGTH_MOBILE)}}</p>
                            <span @click="sendClicked" class="text-center font-clanpro-bold text-decoration-underline">{{$t('news.ReadMore')}}</span>
                        </div>               
                    </div>
                </div>
            </div>
        </transition>
        <h5 class="d-lg-none fw-bold card-title py-1">{{newsContent.title}}</h5>
        <!-- desktop -->
        <div @click="sendClicked" class="d-none d-lg-flex card border-0 cursor-pointer" id="wrapper-desktop">
            <img class="w-100 h-100" :src="imageUrl" alt="" loading="lazy"> 
            <div class="card-img-overlay">
                <div class="row text-center m-0 py-2">
                    <div class="col-12 d-flex justify-content-end">
                        <div class="circle">
                            <p class="read-time m-0">{{$t('readTime')}}</p>
                            <p class="time fw-bold m-0">{{readTime}}</p>
                        </div>
                    </div>
                </div>
                <div class="row m-0 position-absolute info">
                    <div class="col-6 p-0 background-color-primary d-flex justify-content-center align-items-center" :style="{height: '20px'}">
                        <p class="card-info-date text-light m-0">{{ date }}</p>
                    </div>
                    <div class="col-6 col-lg-4 category d-flex justify-content-center align-items-center" 
                        :style="{'background-color': categoryBackgroundColor, height: '20px'}">
                        <span class="card-info-category text-light">{{category}}</span>
                    </div>
                    <h5 class="fw-bold card-title text-light py-1">{{newsContent.title}}</h5>
                </div>
            </div>
            <div class="overlay">
                <div class="more-info d-flex flex-column h-auto">
                    <div class="row m-0 position-absolute info py-3">
                        <div class="col-6 p-0 d-flex justify-content-center align-items-center" 
                            :style="{'background-color': '#000000', height: '20px'}">
                            <p class="card-info-date text-light m-0">{{ date }}</p>
                        </div>
                        <div class="col-6 col-lg-4 category d-flex justify-content-center align-items-center" 
                            :style="{'background-color': categoryBackgroundColor, height: '20px'}">
                            <span class="card-info-category text-light">{{category}}</span>
                        </div>
                        <h5 class="fw-bold card-title text-light py-1">{{newsContent.title}}</h5>
                        <p class="fadeout text-dark m-0 p-0 description">{{excerpt(EXCERPT_LENGTH)}}</p>
                        <span id="read-more" @click="sendClicked" class="text-center text-decoration-underline font-clanpro-bold">{{$t('news.ReadMore')}}</span>
                    </div>               
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

const EXCERPT_LENGTH = 323;
const EXCERPT_LENGTH_TABLET = 910;
const EXCERPT_LENGTH_MOBILE = 450;

export default {
    data() {
        return {
            mobileClick: false,
            EXCERPT_LENGTH: EXCERPT_LENGTH,
            EXCERPT_LENGTH_TABLET: EXCERPT_LENGTH_TABLET,
            EXCERPT_LENGTH_MOBILE: EXCERPT_LENGTH_MOBILE
        }
    },
    props: {
        newsContent: Object
    },
    methods: {
        sendClicked () {
            this.$router.push({name: "NewsDetail", params: {slug: this.newsContent.slug} });
        },
        excerpt: function(length)
        {
            var result = '';
            if(this.newsContent && this.newsContent.content)
            {
                var div = document.createElement('div');
                div.innerHTML = this.newsContent.content.trim();
                
                result = div.textContent || div.innerText || "";
                if(result.length > length)
                {
                    result = result.substring(0, length) + "...";
                }
            }

            return result;
        },
    },
    computed: {
        date() {
            return UTILS_HELPER.formatDate(this.newsContent.publishDate);
        },
        category(){
            return this.newsContent && this.newsContent.categories && this.newsContent.categories.length ? this.newsContent.categories[0].name : null;
        },
        categoryBackgroundColor()
        {
            return this.newsContent && this.newsContent.categories && this.newsContent.categories.length ? this.newsContent.categories[0].color : null
        },
        imageUrl: function()
        {
            var relativePath = this.newsContent && this.newsContent.featuredImagePath ? this.newsContent.featuredImagePath : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
        readTime: function ()
        {
            return this.newsContent.content ? UTILS_HELPER.readingTime(this.newsContent.content) : "";
        },
    },
}
</script>

<style scoped>
.card-img-overlay{
    padding: 0;
}
.circle{
    width: 100px;
    height: 35px;
    background-color: white;
    border-radius: 20px;
}
.circle .read-time{
    font-family: clanpro-news;
    font-size: 0.5rem;
    text-transform: uppercase;
}
.circle .time{
    font-family: clanpro-news;
    font-size: 1rem;
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;    
    z-index: 1;
}
.overlay:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transform-origin: bottom center;
    background: #DDB427;
    z-index: -1;
    transition: transform 0.5s;
}
.description{
    font-family: clanpro-news;
    font-weight: bold;
    font-size: 1rem;
}
.slide-up {
  animation: 0.5s slide-up;
}
.slide-enter, .slide-leave-to {
   overflow: hidden;
} 
@keyframes slide-up {
    from {
        transform: scaleY(0);
    transform-origin: bottom center;
  }
  to {
      transform: scaleY(1);
    transform-origin: bottom center;
  }
}
.fadeout
{
    -webkit-mask-image: -webkit-gradient(linear, left center, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    overflow: hidden;
}
@media (min-width: 1400px) {
    .card-info-date{
        font-size: 0.8rem;
    }
    .card-info-category{
        font-size: 0.8rem;
    }
}
@media (max-width: 1399px) {
    .description{
        font-size: 0.8rem;
    }
}
@media (min-width:992px) and (max-width:1200px) {
    .description{
        font-size: 0.7rem;
    }
    #read-more{
        font-size: 0.7rem;
    }
    .card-title{
        font-size: 0.7rem;
    }
}
@media (min-width: 992px) {
    .card-info-date{
        font-size: 0.6rem;
    }
    .card-info-category{
        font-size: 0.6rem;
    }
    .card-title{
        text-shadow: -1px 2px black;    
    }
    .info{
        bottom: 0;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    h5{
        padding-left: 0;
        padding-right: 0;
    }
    .overlay .more-info {
        visibility: hidden;
        opacity: 0;
    }
    .overlay:hover .more-info{
        visibility: visible;
        opacity: 1;
    }
    .overlay:hover .more-info .info{
        top: 0;
        bottom: unset;
    }
    .overlay:hover .more-info h5{
        padding-right: 3.4rem;
    }
    .overlay:hover::after {
        transform: scaleY(1);
    }
}
@media (min-width:768px) and (max-width:991px) {
    .description{
        font-size: 1rem;
        line-height: 2;
    }
}

@media (max-width: 991px) {
    .info{
        bottom: 0;
        width: 100%;
    }
    .info-click{
        top: 0;
        width: 100%;
    }
    .overlay::after {
        transform: scaleY(1);
    }
    .col-6{
        max-height: 30px;
    }
    .category{
        justify-content: end!important;
    }
    .card-img-overlay{
        padding: 0;
    }
    .card img{
        height: 230px;
        object-fit: cover;
    }
    .card-img-overlay .card-info-date {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
    }
    .card-img-overlay .card-info-category{
        font-size: 1rem;
    }
    .card-title{
        font-size: 1.1rem;
    }
    .description{
        padding: 0 1rem!important;
    }
}
@media (max-width: 767px) {
    .card-img-overlay .card-info-date,
    .card-img-overlay .card-info-category{
        font-size: 0.6rem;
    }
    .overlay .card-info-date,
    .overlay .card-info-category{
        font-size: 0.6rem;
    }
    .description{
        line-height: 1.7;
    }
}
@media (max-width: 575px) {
    .card img{
        min-height: 390px;
    }
    .description{
        line-height: 2;
    }
}
@media (max-width: 386px) {
    .description{
        line-height: 1.5;
    }
}
</style>
<template>
  <div class="container">
    <!-- Header and initial load more -->
    <div class="d-flex justify-content-between">
      <div class="col-6 title" :class="{'col-12': !loading && !events.length}">{{$t('events.title')}}</div>
      <div v-if="!loading && canLoadMore && itemsPerPage == DEFAULT_ITEMS_PER_PAGE" class="see d-flex align-items-center cursor-pointer" @click="loadMore">
        <div class="px-2">
          <span>{{$t("seeMore")}}</span>
        </div>
        <b-icon icon="plus-circle"></b-icon>
      </div>
      <div v-if="itemsPerPage != DEFAULT_ITEMS_PER_PAGE" @click="itemsPerPage = DEFAULT_ITEMS_PER_PAGE" class="d-flex align-items-center cursor-pointer">
        <div class="px-2">
            <span>{{$t("seeLess")}}</span>
        </div>
        <b-icon icon="dash-circle"></b-icon>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="events-container d-flex justify-content-between flex-wrap mt-2">
      <LogoLoading/>
    </div>
    <!-- Content -->
    <div v-else class="events-container d-flex justify-content-between flex-wrap mt-2">
      <EventsThumb class="event-thumb" v-for="event in events" :key="event.id" :eventContent="event"/>
      <NoResultsPlaceholder v-if="!events.length"></NoResultsPlaceholder>
    </div>
  </div>
</template>


<script>
import EventsThumb from "@/components/HomePage/EventsThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 4;

export default {
    components: {
      EventsThumb,
      LogoLoading,
      NoResultsPlaceholder
    },
    props:
    {
      searchQuery: String
    },
    data() {
      return {
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        DEFAULT_ITEMS_PER_PAGE: DEFAULT_ITEMS_PER_PAGE,
        events: []
      }
    },
    mounted () 
    {
        // GET list of events
        this.updateItemsRequested();
    },
    methods: {
      ...mapActions('events', ['getList']),

      updateItemsRequested()
      {
        const currentPage = 1;
        const searchText = this.searchQuery;
        const sorter = 2; // desc
        const propertyName = "id";

        //Make request
        var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);

        this.getList(input);
      },
      loadMore()
      {
        if(this.canLoadMore)
        {
          this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
        }
      }
    },
  computed: {
    ...mapState({ 
      eventsOutput: state => state.events.listOutput, 
    }),
    loading: function(){
      return this.eventsOutput == null;
    },
    numEvents: function(){
      return this.eventsOutput && this.eventsOutput.totalRecords ? this.eventsOutput.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numEvents > this.itemsPerPage;
    },
  },
  watch:
  {
    itemsPerPage()
    {
      this.updateItemsRequested();
    },
    searchQuery: function()
    {
      this.updateItemsRequested();
    },
    eventsOutput: function()
    {
      this.events = this.eventsOutput && this.eventsOutput.data && this.eventsOutput.data.length ? this.eventsOutput.data : [];
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 25px;
}
.events-container{
    gap: 1rem;
}
>>>#event > div,
.events-container > div,
.events-container > span
{
  width: 15vw;
  height: 15vw;
  margin-bottom: 2%;
}
>>>.overlay{
    display: none;
}

@media (max-width: 1399px) 
{
  >>>#event > div,
  .events-container > div,
  .events-container > span
  {
    width: 25vw;
    height: 25vw;
    margin-bottom: 5%;
  }
}
@media (max-width: 1199px) 
{
  >>>#event > div,
  .events-container > div,
  .events-container > span
  {
    width: 20vw;
    height: 20vw;
  }
}
@media (max-width: 991px) /* Two columns */
{
  >>>#event > div,
  .events-container > div,
  .events-container > span
  {
    width: 30vw;
    height: 30vw;
  }
}

@media (max-width: 575px) /* one column */
{
  
  >>>#event > div,
  .events-container > div,
  .events-container > span
  {
    width: 100%;
    height: 100%;
    margin-bottom: 10%
  }
  >>>.card img{
    min-height: unset;
  }
}
</style>
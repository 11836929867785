/* Imports */
import { API_HELPER } from "@/helpers/api.js";
// import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";


/* Exports */
// API services that can be invoked on the modules
export const CART_SERVICE = {
    list,
    manage, 
    update
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Cart"

/* Services */
function list()
{
    
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    
    const requestOptions = {
        method: 'GET',
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);

}

function manage(product)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify(product);
        
    const requestOptions = {
        method: 'PUT',
        headers:  { "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
function update(productId, quantity, customFields)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    
    var raw = JSON.stringify({"productId": productId, "quantity": quantity, "customFields": customFields});
    console.log(raw)
    const requestOptions = {
        method: 'PUT',
        headers:  {  "Content-Type":"application/json"},
        body: raw
    };

    // Process API call, validating the token beforehand.
    return API_HELPER.loggedApiCall(url, null, requestOptions);
}
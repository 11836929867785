import { MESSAGE_SERVICE } from "@/services/message.service.js";

const state = { processTask: null};

const actions = {
    submitMessage({ dispatch, commit },  inputMessage ) {
        dispatch('alert/clear', null, { root: true });
        commit('messageRequest');
        
        MESSAGE_SERVICE.submitMessage(inputMessage)
            .then(
                output => {
                    commit('messageSuccess', output);
                    dispatch('alert/success', output, { root: true });

                },
                error => {
                    commit('messageFailure', error);                
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* submitMessage */
    messageRequest(state) {
        state.processTask = null;
    },
    messageSuccess(state) {
        state.processTask = true;
    },
    messageFailure(state) {
        state.processTask = false;
    }
};

export const message = {
    namespaced: true,
    state,
    actions,
    mutations
};
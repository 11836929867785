<template>

    <div class="container">
        <SpinnerLoading v-if="loading"/>
        <div class="row py-5">
            <div class="col-sm-12 col-md-3 col-lg-3">
                <h4 
                    class="tab-btn my-5 cursor-pointer" 
                    v-for="(menuOption, index) in menuOptions" 
                    :key="index"
                    :class="{'active': activeTab == index}" 
                    @click="activeTab = index" 
                    >
                    {{menuOption.name}}
                </h4>
            </div>
            <div class="d-none d-md-block col-md-1 col-lg-1 verticalBar"></div>
            <!-- Orders -->
            <div class="col-sm-12 col-md-8 col-lg-8 align-self-center" :class="{'d-none': activeTab != 0}" >
                <!-- check if you have orders -->
                <div class="row m-0 align-content-center" v-if="orders.length == []">
                    <h2 class="d-flex justify-content-center">{{$t("account.noOrders")}}</h2>
                    <router-link class="w-auto my-0 mx-auto" :to="{name: 'Shop'}"><button class="btn btn-primary border-0 mt-5 mb-5">{{$t("account.goToShop")}}</button></router-link>
                </div>
                <!-- table with orders -->
                <div v-else class="table-wrapper">
                    <div class="table-title">
                        <div class="row">
                            <div class="col-12">
                                <h2><b>{{$t("account.orderDetails")}}</b></h2>
                            </div>
                        </div>
                    </div>
                    <div>
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                <th class="w-25" scope="col">{{$t("account.ref")}}</th>
                                    <th class="w-25" scope="col">{{$t("account.status")}}</th>
                                </tr>
                            </thead>
                            <tbody v-for="(order, index) in orders" :key="index">
                                <tr>
                                    <td scope="row">00{{order.id}}</td>
                                    <td>{{order.status === 'PendingPayment' ? $t('account.pendingPayment') : order.status === 'Processing' ? $t('account.processing') : order.status === 'Sent' ? $t('account.sent') : order.status === 'Concluded' ? $t('account.concluded') :  $t('account.canceled') }}</td>
                                    <td @click="activeShow == index ? activeShow = -1 : activeShow = index">
                                        <a class="view" title="View Details" data-toggle="tooltip">
                                            <b-icon :icon="activeShow == index ? 'arrow-down' : 'arrow-right'" class="cursor-pointer"></b-icon>
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="activeShow == index">
                                    <td colspan="1" class="vertical-address-bar">
                                        <a class="cursor-pointer" @click="showAddressesPopUpForm = true">{{$t("account.address")}}</a>
                                    </td>
                                    <td colspan="4">
                                        <div class="d-flex justify-content-between border-bottom" v-for="(productDetail, index) in orderDetails.products" :key="index">
                                            <div class="py-3">
                                                <p class="m-0"><strong>{{productDetail.product.title}}</strong></p>
                                                <p class="m-0">{{productDetail.product.finalPrice}}€</p>
                                            </div>
                                            <div class="py-3">
                                                <p class="m-0"><router-link :to="{ name: 'ProductDetail', params:{ slug: productDetail.product.slug}}">{{$t("account.product")}}</router-link></p>
                                            </div>
                                        </div>
                                        <AddressesDetailPopUp v-if="showAddressesPopUpForm" @closeConfirmRequested="showAddressesPopUpForm = false" :billingAddress="orderDetails.billingAddress" :shippingAddress="orderDetails.shippingAddress" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="rows >= 5" class="col-12 d-flex pagination">
                        <Pagination :rows="rows" :itemsPerPage="itemsPerPage" :loading="loading" v-model="currentPage"></Pagination>
                    </div>
                </div>
            </div>
            <!-- update user info -->
            <div class="col-sm-12 col-md-8 col-lg-8" :class="{'d-none': activeTab != 1}">
                <form id="user-form" @submit.prevent="handleSubmit">
                    <!-- Password -->
                    <div class="form-group w-100">
                        <label for="password">{{ $t("account.newPassword") }}</label>
                        <input type="password" v-model="password" id="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper')) }" />
                        <div v-if="submitted && (hasError('Password') || hasError('PasswordTooShort') || hasError('PasswordRequiresNonAlphanumeric') || hasError('PasswordRequiresDigit') || hasError('PasswordRequiresLower') || hasError('PasswordRequiresUpper'))" class="invalid-feedback">
                            <span v-if="!$v.password.required">{{ $t("login.passVal") }}</span>
                            <span v-if="errors.PasswordTooShort">{{errors.PasswordTooShort}}</span>
                            <span v-if="errors.PasswordRequiresNonAlphanumeric">{{errors.PasswordRequiresNonAlphanumeric}}</span>
                            <span v-if="errors.PasswordRequiresDigit">{{errors.PasswordRequiresDigit}}</span>
                            <span v-if="errors.PasswordRequiresLower">{{errors.PasswordRequiresLower}}</span>
                            <span v-if="errors.PasswordRequiresUpper">{{errors.PasswordRequiresUpper}}</span>
                        </div>
                    </div>
                    <!-- Submit button -->
                    <div class="form-group w-100 mt-5" v-bind:class="{disabled: loading}">
                        <button class="btn btn-primary border-0">
                            <span v-show="!loading">{{$t("account.updateUser").toUpperCase()}}</span>
                            <span v-show="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span v-show="loading" class="sr-only">{{ $t("loading") }}</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import InputGetList from "@/classes/InputGetList";
import {  mapState, mapActions } from "vuex";
import Pagination from "@/components/Pagination.vue";
import { required } from "vuelidate/lib/validators";
import { UTILS_HELPER } from "@/helpers/utils.js";
import AddressesDetailPopUp from "@/components/popups/AddressesDetailPopUp.vue";
import SpinnerLoading from "@/components/SpinnerLoading.vue";
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: {
        Pagination,
        AddressesDetailPopUp,
        SpinnerLoading
    },
    metaInfo () {
      return { 
            title: `${this.$t("vueRouter.accountTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.account", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.account", 'pt')])},
            ]
        } 
    },
    data() {
        return {
            currentPage: 1,
            activeTab: 0,
            activeShow: -1,
            menuOptions:[
                {name:this.$t("account.consultOrders")},
                {name:this.$t("account.personalDetails")},
                {name:this.$t("account.logout")}
            ],
            password: "",
            submitted: false,
            loading: false,
            showAddressesPopUpForm: false,
            itemsPerPage: localStorage.itemsPerPage ?? 5
        }
    },
    validations: {
        password: { required }
    },

    mounted: function()
    {
        // GET list of orders
        this.updateItemsRequested(1, this.itemsPerPage);
    },
    computed: {
        ...mapState({
            ordersOutput: state => state.orders.listOrders,
            orderDetailById: state => state.orders.orderOutput,
            updateInfo: state => state.account.processUpdate,
            alertType: (state) => state.alert.type,
            alertOutput: (state) => state.alert.output,
        }),
        errors: function () {
            return this.alertOutput && this.alertOutput.errors ? this.alertOutput.errors : {}; 
        },
        ordersInfo: function(){
            return this.ordersOutput ? this.ordersOutput : [];
        },
        orders: function()
        {
            var result = [];

            if(this.ordersInfo && this.ordersInfo.data)
            {
                result = this.ordersInfo.data;
            }

            return result;
        },
        orderDetails: function(){
            return this.orderDetailById ? this.orderDetailById : {};
        },
        rows: function()
        {
            return this.ordersOutput ? this.ordersOutput.totalRecords : 1;
        },
    },
    methods: {
        ...mapActions({
            getOrdersList: 'orders/getOrders',
            getOrderDetails: 'orders/getDetail',
            updateProfile: 'account/updateProfile',
            logout: 'account/logout',
            clearAlert: "alert/clear",
        }),

        handleSubmit() {
            this.clearAlert();
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.loading = true;

            this.updateProfile(this.password);
        },
        hasError(fieldName) {
            var hasLocalError = false;

            if(this.$v[UTILS_HELPER.convertToCamelCase(fieldName)])
            {
                hasLocalError = this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error;
            }

            return hasLocalError || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        updateItemsRequested()
        {
            this.loading = true;

            // GET list of orders
            const orderBy = 2;
            const propertyName = "id";
            const searchText = '';

            //Make request
            var input = new InputGetList(this.itemsPerPage, this.currentPage, orderBy, propertyName, searchText);
            input.statuses = null; // Get all

            this.getOrdersList(input);
        }
    },
    watch: {
        currentPage(){
            this.updateItemsRequested(1, this.itemsPerPage)    
        },
        ordersOutput(){
            this.loading = false; 
            this.activeShow = -1;
        },
        activeShow(val) { 
            if(this.orders[val]){
                let id = this.orders[val].id;
                this.getOrderDetails(id);
            }
        },
        updateInfo(val){
            this.submitted = false;

            if(val)
            {
                this.$emit("success-alert-requested", { message: this.$t("success"), primaryButton: this.$t("closePopUp")});
                this.loading = false;
            }
        },
        activeTab(val) {
            this.submitted = false;
            switch (val) {
                case 0: 
                {
                    this.updateItemsRequested(1, this.itemsPerPage);
                    break;
                }
                // case 1:
                // {
                //     
                //     break;
                // }
                case 2:
                {
                    this.logout()
                    break;
                }
            }
        },
    },
    

}
</script>

<style scoped>
.verticalBar {
  border-left: 4px solid #DDB427;
  height: 400px;
}
.tab-btn{
    cursor: pointer;
    opacity: 0.3;
    font-size: 1.2rem;
}
.tab-btn.active{
    color: #DDB427;
    opacity: 1;
}
a{
  color: #DDB427 !important;
}
/* table */
.table > :not(:first-child) {
    border-color: white;
}
.table-title {
    color: #fff;
    background: #DDB427;		
    padding: 15px 25px;
    border-radius: 3px 3px 0 0;
}
.table-title h2 {
    margin: 5px auto;
    font-size: 1.5rem;
}
table.table tr th, table.table tr td {
    border-color: white;
    padding: 12px 15px;
    vertical-align: middle;
}
table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}	
table.table td a {
    display: inline-block;
}
table.table td a.view {        
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-radius: 30px;
    text-align: center;
}
.border-bottom{
    border-bottom:  2px solid #DDB427!important;
}
>>>.pagination {
    justify-content: center;
}
</style>
<template>
    <div id="news">
        <div class="container">
            <div class="row">
                <div class="col-12 py-3">
                    <h1 class="text-center fw-bold">{{$t('news.title')}}</h1>
                </div>
                <hr>
            </div>
        </div>
        <div class="container-fluid">
            <!-- Loading -->
            <LogoLoading v-if="loading"/>
            <div v-else class="d-flex py-2 box" v-for="(news,index) in newsList" :key="index" @click="sendClick(index)">
                <div class="col-6 d-flex position-relative">
                    <div class="circle">
                        <p class="read-time m-0 text-center font-clanpro">{{$t('readTime')}}</p>
                        <p class="time fw-bold m-0 text-center font-clanpro">{{readTime(index)}}</p>
                    </div>
                    <div class="category-type d-flex align-items-center" :style="{'background-color': categoryBackgroundColor(index)}">
                        <span class="d-flex justify-content-center align-items-center card-info-category text-light">{{category(index)}}</span>
                    </div>
                    <div class="img w-100 cursor-pointer">
                        <img class="w-100 h-100 image-style" :src="imageUrl(index)" alt="" loading="lazy">
                    </div>
                </div>
                <div class="col-6 description">
                    <div class="col-12">
                        <h3 class="fw-bold">{{news.title}}</h3>
                    </div>
                    <div class="col-12">
                        <p class="fw-bold font-clanpro">{{date(index)}}</p>
                    </div>
                    <div class="col-12">
                        <p class="fw-bold font-clanpro">{{excerpt(index)}}</p>
                    </div>
                    <div class="col-12">
                        <a class="btn-border-black text-dec-none">{{$t('news.ReadMore')}}</a>
                    </div>
                </div>
            </div>
            <div v-show="!loading && canLoadMore" class="cursor-pointer py-5">
                <div class="col-12 d-flex justify-content-center">
                    <img @click="loadMore" class="d-block text-center cursor-pointer" src="@/assets/showMore.svg" alt="" loading="lazy">
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <p @click="loadMore" class="d-block text-center font-clanpro">{{$t("news.more")}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue"

const EXCERPT_LENGTH = 120;
const DEFAULT_ITEMS_PER_PAGE = 3;

export default {
    components: {
        LogoLoading,
    },
    data() {
        return {
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        }
    },
    metaInfo () {
    return { 
          title: this.$t("vueRouter.newsTitle"), 
          titleTemplate: '%s - Tabuaço', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRouter.newsDescription")},
            {name: 'keywords', content: this.newsList && this.newsList.length ? this.newsList.map(x=> x.title).join(", ") : this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRouter.newsTitle")},
            {name: 'twitter:description', content: this.$t("vueRouter.newsDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRouter.newsTitle")},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.$t("vueRouter.newsDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.News", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.News", 'en')])}
          ]
      } 
    }, 
    mounted() 
    {
        this.updateItemsRequested();
    },
    computed: {
        ...mapState({ 
            newsOutput: state => state.news.listOutput,
        }),
        newsList() {
            return this.newsOutput && this.newsOutput.data ? this.newsOutput.data: [];
        },
        loading: function(){
            return this.newsOutput == null;
            // return this.newsOutput == null || this.categoriesOutput == null;
        },
        numNews: function(){
            return this.newsOutput && this.newsOutput.totalRecords ? this.newsOutput.totalRecords : 0;
        },
        canLoadMore: function()
        {
            return this.numNews > this.itemsPerPage;
        },
    },
    methods: {
        ...mapActions('news', ['getList']),

        updateItemsRequested(){
            const currentPage = 1;
            const sorter = 2; // descending
            const propertyName = "id";
            const searchText = '';
            
            //Make request
            var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);
            
            this.getList(input); 
        },
        loadMore()
        {
            if(this.canLoadMore)
            {
                this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
            }
        },
        category(index){
            return this.newsList && this.newsList[index] && this.newsList[index].categories ? this.newsList[index].categories[0].name : null;
        },
        categoryBackgroundColor(index)
        {
            return this.newsList && this.newsList[index] && this.newsList[index].categories ? this.newsList[index].categories[0].color : null
        },
        imageUrl(index)
        {
            var relativePath = this.newsList[index] && this.newsList[index].featuredImagePath ? this.newsList[index].featuredImagePath : null;
            return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
        },
        date(index) {
            return UTILS_HELPER.formatDate(this.newsList[index].publishDate);
        },
        excerpt: function(index)
        {
            var result = '';
            if(this.newsList[index] && this.newsList[index].content)
            {
                var div = document.createElement('div');
                div.innerHTML = this.newsList[index].content.trim();
                
                result = div.textContent || div.innerText || "";
                if(result.length > EXCERPT_LENGTH)
                {
                result = result.substring(0, EXCERPT_LENGTH) + "...";
                }
            }

            return result;
        },
        readTime: function (index)
        {
            return this.newsList[index].content ? UTILS_HELPER.readingTime(this.newsList[index].content) : "";
        },
        sendClick(index){
            this.$router.push({name: "NewsDetail", params: {slug: this.newsList[index].slug}}); 
        },
    },
    watch: {
        itemsPerPage()
        {
            this.updateItemsRequested();
        }
    },
}
</script>

<style scoped>
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
}
.category-type{
    width: 40px;
    height: 100%;
}
.card-info-category{
    writing-mode: vertical-lr;
    width: 100%;
    transform:rotate(180deg) ;
}
.box{
    height: 340px;
}
.skeleton-box
{
  min-height: 340px;
  width: 100%;
  margin: 10px auto;
}
.description{
    background-color: #CFCBCB;
    padding: .5rem;
    word-break: break-all;
}
.circle{
    width: 100px;
    height: 35px;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    position: absolute;
    top: 2%;
    right: 1%;
}
.circle .read-time{
    font-size: 0.5rem;
    text-transform: uppercase;
}
.circle .time{
    font-size: 1rem;
}
.btn-border-black{
    display: inline-flex;
    align-items: center;
    height: 40px;
    padding: 0 48px;
    cursor: pointer;
    border: 2px solid black;
    background-color: transparent;
    color: black;
}
.image-style{
    object-fit: cover;
}
@media (max-width: 407px) {
  .btn-border-black{
    padding: 0 27px;
}
}
</style>
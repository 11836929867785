<template>
  <div v-if="products" class="container" id="store">
    <!-- Title -->
    <h4 v-if="title" class="fw-bold text-center">{{title}}</h4>
    <!-- Slides -->
    <vueper-slides v-if="products && products.length" fixed-height="550px" class="no-shadow flex-grow-1 m-0" :bullets="false" :visible-slides="3" slide-multiple :gap="1" :dragging-distance="70" :touchable="false" :breakpoints="{
        1400: { visibleSlides: 2, slideMultiple: 2},
        992: { visibleSlides: 1, slideMultiple: 1 },
        577: { visibleSlides: 1, slideMultiple: 1, touchable:true, arrows: false }
      }"
    >
      <!-- Custom arrows -->
      <template v-slot:arrow-left>
        <img src="@/assets/svg/storeSlide/arrow-left.svg" alt="" loading="lazy">
      </template>
      <template v-slot:arrow-right>
        <img src="@/assets/svg/storeSlide/arrow-right.svg" alt="" loading="lazy">
      </template>
      <!-- Content -->
      <vueper-slide v-for="product in products" :key="product.id">
        <template v-slot:content>
          <ProductThumb :product="product"/>
        </template>
      </vueper-slide>
    </vueper-slides>
    <div class="py-3 d-flex justify-content-center">
        <router-link :to="{name: 'Shop'}" class="btn text-dec-none">{{$t('shop.buttonTxt')}}</router-link>
        <!-- <a :href="buttonUrlPath" class="btn text-dec-none">Ir para a loja online</a> -->
    </div>
  </div>
</template>

<script>
import ProductThumb from "@/components/Shop/ProductThumb.vue";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import InputGetList from "@/classes/InputGetList";
import { mapState, mapActions } from 'vuex';

export default {
    components: {
        VueperSlides,
        VueperSlide,
        ProductThumb
    },
    props: {
        title: String
    },
    mounted() 
    {
      this.initializePage()
    },
    data() {
        return {
            
        }
    },
    computed: {
      ...mapState({ 
        productsOutput: state => state.products.featuredOutput,
      }),
      products: function () {
        return this.productsOutput && this.productsOutput.data && this.productsOutput.data.length ? this.productsOutput.data : [];
      },
    },
    methods: {
      ...mapActions('products', ['getFeaturedList']),

      initializePage()
      {
        // GET list of products
        const itemsPerPage = 999;
        const currentPage = 1;
        const sorter = 2; // desc
        const propertyName = "id";
        const searchText = '';
        
        //Make request
        var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
        
        // List specific parameters
        input.visible = true;
        input.featured = true;

        this.getFeaturedList(input); 
      }
    },

}
</script>

<style scoped>
>>>.vueperslides__arrow--prev{
    top: 38%;
}
>>>.vueperslides__arrow--next{
    top: 38%;
}
 >>>.vueperslide .overlay {
  visibility: hidden;
  transition: 1s ease;
  opacity: 0;
  background-color: hsla(1, 63%, 36%, 0);
}
.btn
{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  height: 45px;
  font-weight: 900;
  padding: 0 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 2px solid #DDB427;
  color: black;
  background-color: #DDB427;
  border-radius: 0!important;
}
@media (max-width: 990px) {
   >>>.vueperslide .overlay {
    visibility: visible;
    opacity: 1;
  }
}
@media (max-width: 576px) {
  .btn
  {
    font-size: 1rem;
  }
}
@media (min-width: 577px) and (max-width: 1450px) {
  >>>.vueperslides{
      padding: 0 30px;
  }
  >>>.vueperslides__arrow--prev{
      left: -2.5rem;
  }
  >>>.vueperslides__arrow--next{
      right: -2.5rem;
  }
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/helpers/i18n';

//Views
import Home from '../views/Home.vue';
import TravelPaths from '../views/TravelPaths.vue';
import Reservations from '../views/Reservations.vue';
import StaticPages from '../views/StaticPages.vue';
import Test360 from '../views/Test360.vue';
import Pois from '../views/Pois.vue';
import PoisDetail from '../views/PoisDetail.vue';
import Faqs from '../views/Faqs.vue';
import News from '../views/News.vue';
import NewsDetail from '../views/NewsDetail.vue';
import Events from '../views/Events.vue';
import EventDetail from '../views/EventDetail.vue';
import Gallery from '../views/Gallery.vue';
import Cart from '../views/Cart.vue';
import Shop from '../views/Shop.vue';
import ProductDetail from '../views/ProductDetail.vue';
import Login from '../views/Login.vue';
import ConfirmAccount from "../views/ConfirmAccount.vue";
import Favorites from "../views/Favorites.vue";
import SearchResults from "../views/SearchResults.vue";
import Account from "../views/Account.vue";
import Page404 from "../views/Page404.vue";
import Page500 from "../views/Page500.vue";

//Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

import { CONSTANTS } from '@/helpers/consts';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter,
    }
  },
  {
    path: i18n.t('vueRouter.TravelPaths'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.TravelPaths', locale.key) ),
    name: 'TravelPaths',
    components: {
      header: AppHeader,
      default: TravelPaths,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.Reservations'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Reservations', locale.key) ),
    name: 'Reservations',
    components: {
      header: AppHeader,
      default: Reservations,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRouter.Page')}/:slug`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Page', locale.key) ),
    name: 'StaticPages',
    components: {
      header: AppHeader,
      default: StaticPages,
      footer: AppFooter
    },
  },
  {
    path: "/Test360",
    // path: i18n.t('vueRouter.Pois'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Pois', locale.key) ),
    name: 'Test360',
    components: {
      header: AppHeader,
      default: Test360,
      footer: AppFooter
    }
  },
  {
    path: "/Pois",
    // path: i18n.t('vueRouter.Pois'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Pois', locale.key) ),
    name: 'Pois',
    components: {
      header: AppHeader,
      default: Pois,
      footer: AppFooter
    }
  },
  {
    path: "/PoisDetail",
    // path: i18n.t('vueRouter.Pois'),
    // alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Pois', locale.key) ),
    name: 'PoisDetail',
    components: {
      header: AppHeader,
      default: PoisDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.Faq'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Faq', locale.key) ),
    name: 'Faqs',
    components: {
      header: AppHeader,
      default: Faqs,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.News'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.News', locale.key) ),
    name: 'News',
    components: {
      header: AppHeader,
      default: News,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRouter.NewsDetail')}/:slug`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.NewsDetail', locale.key) ),
    name: 'NewsDetail',
    components: {
      header: AppHeader,
      default: NewsDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.Events'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.Events', locale.key) ),
    name: 'Events',
    components: {
      header: AppHeader,
      default: Events,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRouter.EventDetails')}/:slug`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.EventDetails', locale.key) ),
    name: 'EventDetail',
    components: {
      header: AppHeader,
      default: EventDetail,
      footer: AppFooter
    }
  },
  {   
    path: `${i18n.t('vueRouter.gallery')}`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.gallery', locale.key) ),
    name: 'Gallery',
    components: {
      header: AppHeader,
      default: Gallery,
      footer: AppFooter
    }
  },
  {   
    path: `${i18n.t('vueRouter.cart')}`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.cart', locale.key) ),
    name: 'Cart',
    components: {
      header: AppHeader,
      default: Cart,
      footer: AppFooter
    }
  },
  {   
    path: `${i18n.t('vueRouter.shop')}`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.shop', locale.key) ),
    name: 'Shop',
    components: {
      header: AppHeader,
      default: Shop,
      footer: AppFooter
    }
  },
  {
    path: `${i18n.t('vueRouter.shop')}/:slug`,
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.shop', locale.key) ),
    name: 'ProductDetail',
    components: {
      header: AppHeader,
      default: ProductDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.login'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.login', locale.key) ),
    name: 'Login',
    components: {
      header: AppHeader,
      default: Login,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.confirmAccount'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.confirmAccount', locale.key) ),
    name: 'ConfirmAccount',
    components: {
      header: AppHeader,
      default: ConfirmAccount,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.favorites'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.favorites', locale.key) ),
    name: 'Favorites',
    components: {
      header: AppHeader,
      default: Favorites,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.searchResults'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.searchResults', locale.key) ),
    name: 'SearchResults',
    components: {
      header: AppHeader,
      default: SearchResults,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.account'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.account', locale.key) ),
    name: 'Account',
    components: {
      header: AppHeader,
      default: Account,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRouter.errorPage'),
    alias: CONSTANTS.supportedLanguages.map(locale => i18n.t('vueRouter.errorPage', locale.key) ),
    name: 'Page500',
    components: {
      header: AppHeader,
      default: Page500,
      footer: AppFooter
    }
  },
  {
    path: '*',
    name: '404',
    components: {
      header: AppHeader,
      default: Page404,
      footer: AppFooter
    }
  },  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

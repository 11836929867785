<template>
  <div id="news">
    <div class="container">
      <div class="row">
        <div class="col-12 py-3">
          <h1 class="text-center fw-bold">{{ $t("eventsPage.title") }}</h1>
        </div>
        <hr />
      </div>
    </div>
    <div class="container pb-3">
      <!-- Loading -->
      <LogoLoading v-if="loading" />
      <div v-else class="container mb-5">
        <div class="row py-2" v-for="(event, index) in eventsList" :key="index">
          <div class="col-sm-12 col-md-4 col-lg-4" id="categoryCol-align">
            <div class="col-12 d-flex h-100">
              <div class="col-6">
                <div
                  class="category-type"
                  :style="{
                    'background-color': categoryBackgroundColor(index),
                  }"
                >
                  <div
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    <span class="text-light text-center font-clanpro">{{
                      category(index)
                    }}</span>
                  </div>
                </div>
                <div class="category-background">
                  <div
                    class="d-flex justify-content-center align-items-center h-100"
                  >
                    <span class="text-center"
                      >{{ startDate(index) }}<br />-<br />{{
                        endDate(index)
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 px-2" id="img-col">
                <div class="img h-100">
                  <img
                    class="image-style h-100"
                    :src="imageUrl(index)"
                    alt=""
                    loading="lazy"
                  />
                  <img
                    v-if="!isFavorite(index)"
                    @click="favoriteButtonClicked(index)"
                    class="starFav-style cursor-pointer"
                    src="@/assets/shop/favIconPois.svg"
                    alt=""
                    :style="{ width: '30px' }"
                    loading="lazy"
                  />
                  <img
                    v-if="isFavorite(index)"
                    @click="favoriteButtonClicked(index)"
                    class="starFav-style cursor-pointer"
                    src="@/assets/shop/isFav.svg"
                    alt=""
                    :style="{ width: '30px' }"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-8 col-lg-8 description">
            <div class="col-12">
              <h3 class="fw-bold">{{ event.title }}</h3>
              <br />
              <p class="fw-bold font-clanpro">{{ event.content }}</p>
              <br />
              <a @click="sendClick(index)" class="btn view-event">{{
                $t("eventsPage.eventBtn")
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!loading && canLoadMore" class="cursor-pointer py-5">
        <div class="col-12 d-flex justify-content-center">
          <img
            @click="loadMore"
            class="d-block text-center cursor-pointer"
            src="@/assets/showMore.svg"
            alt=""
            loading="lazy"
          />
        </div>
        <div class="col-12 d-flex justify-content-center">
          <p @click="loadMore" class="d-block text-center font-clanpro">
            {{ $t("news.more") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";
import LogoLoading from "@/components/LogoLoading.vue";

const DEFAULT_ITEMS_PER_PAGE = 3;

export default {
  components: {
    LogoLoading,
  },
   metaInfo () {
    return { 
          title: this.$t("vueRouter.eventsTitle"), 
          titleTemplate: '%s - Tabuaço', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRouter.eventsDescription")},
            {name: 'keywords', content: this.eventsOutput && this.eventsOutput.length ? this.eventsOutput.map(x=> x.title).join(", ") : this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRouter.eventsTitle")},
            {name: 'twitter:description', content: this.$t("vueRouter.eventsDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRouter.eventsTitle")},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.$t("vueRouter.eventsDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Events", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.Events", 'en')])}
          ]
      } 
  }, 
  data() {
    return {
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      clicked: false,
    };
  },

  mounted() {
    this.updateItemsRequested();
    if (this.listOutput) {
      this.getFavsRequested();
    }
  },
  computed: {
    ...mapState({
      eventsOutput: (state) => state.events.listOutput,
    }),
    ...mapState("favorites", ["listOutput"]),
    ...mapState("favorites", ["addTask"]),
    ...mapState("favorites", ["removeTask"]),

    eventsList() {
      console.log(this.eventsOutput);
      return this.eventsOutput && this.eventsOutput.data
        ? this.eventsOutput.data
        : [];
    },
    loading: function () {
      return this.eventsOutput == null;
    },
    numEvents: function () {
      return this.eventsOutput && this.eventsOutput.totalRecords
        ? this.eventsOutput.totalRecords
        : 0;
    },
    canLoadMore: function () {
      return this.numEvents > this.itemsPerPage;
    },
  },
  methods: {
    ...mapActions({
      getEvents: "events/getList",
    }),
    ...mapActions("favorites", ["getList"]),
    ...mapActions("favorites", ["addFavorite"]),
    ...mapActions("favorites", ["removeFavorite"]),

    updateItemsRequested() {
      const currentPage = 1;
      const sorter = 2; // descending
      const propertyName = "id";
      const searchText = "";

      //Make request
      var input = new InputGetList(
        this.itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        searchText
      );

      this.getEvents(input);
    },
    loadMore() {
      if (this.canLoadMore) {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE;
      }
    },
    category(index) {
      return this.eventsList &&
        this.eventsList[index] &&
        this.eventsList[index].categories
        ? this.eventsList[index].categories[0].name
        : null;
    },
    categoryBackgroundColor(index) {
      return this.eventsList &&
        this.eventsList[index] &&
        this.eventsList[index].categories
        ? this.eventsList[index].categories[0].color
        : null;
    },
    imageUrl(index) {
      var relativePath =
        this.eventsList[index] && this.eventsList[index].featuredImagePath
          ? this.eventsList[index].featuredImagePath
          : null;
      return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]);
    },
    startDate(index) {
      return UTILS_HELPER.formatshortDate(this.eventsList[index].startDate);
    },
    endDate(index) {
      return UTILS_HELPER.formatshortDate(this.eventsList[index].endDate);
    },
    sendClick(index) {
      this.$router.push({
        name: "EventDetail",
        params: { slug: this.eventsList[index].slug },
      });
    },
    isFavorite: function (index) {
      return this.listOutput && this.listOutput.data
        ? this.listOutput.data.find((x) => x.slug == this.eventsList[index].slug)
        : false;
    },
    favoriteButtonClicked(index) {
      this.clicked = true;

      if (this.isFavorite(index)) {
        this.removeFavorite(this.eventsList[index].slug);
      } else {
        this.addFavorite(this.eventsList[index].slug);
      }
    },
    getFavsRequested() {
      const itemsPerPage = 999;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";

      //Make request
      var inputGetFavorites = new InputGetList(
        itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        null
      );

      // Get favs
      this.getList(inputGetFavorites);
    },
  },
  watch: {
    itemsPerPage() {
      this.updateItemsRequested();
    },
    addTask: function (val) {
      if (val && this.clicked) {
        this.getFavsRequested();
      }
      if (val != null) this.clicked = false;
    },
    removeTask: function (val) {
      if (val && this.clicked) {
        this.getFavsRequested();
      }
      if (val != null) this.clicked = false;
    },
  },
};
</script>

<style scoped>
hr {
  opacity: 1 !important;
  background-color: transparent;
  border: 1px solid black !important;
}
.category-type {
  width: 100%;
  height: 15%;
}
.category-background {
  background-color: #cfcbcb;
  word-break: break-all;
  margin-top: 10px;
  width: 100%;
  height: 80%;
}
.skeleton-box {
  min-height: 200px;
  width: 100%;
  margin: 10px auto;
}
.description {
  background-color: #cfcbcb;
  padding: 10px;
  word-break: break-all;
  position: relative;
}
.btn-border-black {
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 0 48px;
  cursor: pointer;
  border: 2px solid black;
  background-color: transparent;
  color: black;
}
.image-style {
  object-fit: cover;
  width: 100%;
  min-height: 184px;
}
.img {
  position: relative;
}
.starFav-style {
  z-index: 1;
  right: 0;
  top: 0;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  position: absolute;
}
.view-event {
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-top: 5px solid white;
  border-left: 5px solid white;
  background-color: #ddb427;
  width: 140px;
  height: 45px;
  text-align: center;
}
#categoryCol-align {
  padding-right: 0 !important;
}
@media (max-width: 767px) {
  .description {
    margin-top: 1rem;
    justify-content: center;
    display: grid;
  }
  .view-event {
    display: flex;
    justify-content: center;
    align-items: center;
    position: unset;
    border-top: none;
    border-left: none;
    background-color: #ddb427;
    width: 140px;
    height: 45px;
    bottom: 0;
    right: unset;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  #categoryCol-align {
    padding: 0 !important;
  }
  #img-col {
    padding-left: 1rem !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 407px) {
  .btn-border-black {
    padding: 0 27px;
  }
}
</style>
<template>
  <section id="page-style">
    <LogoLoading v-if="!newsDetail"/>
    <div v-else>
      <NewDetail v-if="newsDetail" :newsDetail="newsDetail" :categorie="categorieNews"/>
    </div>
  </section>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import NewDetail from "@/components/NewDetail.vue";
import router from '@/router';
import { mapState, mapActions } from "vuex";
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
export default {
components: {
   NewDetail,
   LogoLoading
},
 metaInfo () {
      var result =
      { 
          title: this.newsDetail ? `${this.newsDetail.title} - Tabuaço` : "Tabuaço", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.newsDetail && this.newsDetail.content ? UTILS_HELPER.htmlToText(this.newsDetail.content) : this.$t("intro")},
              {name: 'keywords', content: this.newsDetail && this.newsDetail.categories && this.newsDetail.categories.length ? this.newsDetail.categories.map(category=> category.name).join(", ") : this.$t("vueRouter.defaultKeywords")},
              // Twitter Card
              {name: 'twitter:card', content: "summary"},
              {name: 'twitter:title', content: this.newsDetail ? `${this.newsDetail.title} - Tabuaço` : "Tabuaço"},
              {name: 'twitter:description', content: this.newsDetail && this.newsDetail.content ? UTILS_HELPER.htmlToText(this.newsDetail.content) : this.$t("intro")},
              {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              //Facebook OpenGraph
              {property: 'og:title', content: this.newsDetail ? `${this.newsDetail.title} - Tabuaço` : "Tabuaço"},
              {property: 'og:site_name', content: 'Tabuaço'},
              {property: 'og:type', content: 'website'},
              {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
              {property: 'og:description', content: this.newsDetail && this.newsDetail.content ? UTILS_HELPER.htmlToText(this.newsDetail.content) : this.$t("intro")}
          ]
      } 

      if(this.$route.params.slug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.NewsDetail", 'pt')])}/${this.$route.params.slug}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.NewsDetail", 'en')])}/${this.$route.params.slug}`},
          ];
      }

      return result;
  }, 
data: function () {
    return {
      newsContent: null,
    }
  },
  methods: {
    ...mapActions({
          getSlug: 'news/getDetails',
        }),
    initializeParams: function(){
      if(!this.$route.params.slug)
      {
        router.push({name: "News"});
      }
      else{
        this.getSlug(this.$route.params.slug);
      }
    },
    date() {
      return UTILS_HELPER.formatDate(this.newsDetail.publishDate);
    },
  },
  computed:
  {
    ...mapState('news', ['detailOutput']),
    loadingContent: function()
    {
      return this.detailOutput == null;
    },
    newsDetail() 
    {
      return this.detailOutput && this.detailOutput ? this.detailOutput: null;
    },
    categorieNews()
    {
      return this.newsDetail && this.newsDetail.categories ? this.newsDetail.categories : [];
    }
  },
  mounted() {
    this.initializeParams();
  },
  watch:
  {
    detailOutput: function(val)
    {
      if(val == false)
      {
        router.push({name: "News"});
      }
    }
  }
}
</script>

<style scoped>
#page-style{
  min-height: 100vh;
}
#image-BG{
  background-image: url("../assets/shop/tabuacoBG.svg");
  background-repeat: no-repeat;
  object-fit: cover;
}
@media (max-width: 850px)
{
  #image-BG{
    background-image: none;
  }
}
</style>
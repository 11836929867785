<template>
<section>
  <LogoLoading v-if="loading"/>
  <div v-show="!loading">
    <div id="shop" class="container-fluid p-4">        
      <BannerSlider/>
      <hr class="container">
      <FeaturedSlider :title="$t('shop.featuredTitle')"/>
      <hr class="container">
      <BestSellersSlider :title="$t('shop.bestSellersTitle')"/>
      <hr class="container">
      <PublishDateSlider :title="$t('shop.newEntriesTitle')"/>
      <BannerSlider class="py-4"/>
      <hr class="container">
      <LatestViewedSlider />
      <!-- Newsletter -->
      <Newsletter class="py-5 mt-5" />
    </div>
  </div>
</section>
</template>

<script>
import BannerSlider from "@/components/Shop/BannerSlider.vue";
import FeaturedSlider from "@/components/Shop/FeaturedSlider.vue";
import PublishDateSlider from "@/components/Shop/PublishDateSlider.vue";
import BestSellersSlider from "@/components/Shop/BestSellersSlider.vue";
import LatestViewedSlider from "@/components/Shop/LatestViewedSlider.vue";
import Newsletter from "@/components/Newsletter.vue";
import { mapState } from "vuex";
import LogoLoading from "@/components/LogoLoading.vue";
import { API_HELPER } from "@/helpers/api.js";

export default {
  components: {
    BannerSlider,
    FeaturedSlider,
    PublishDateSlider,
    BestSellersSlider,
    Newsletter,
    LatestViewedSlider,
    LogoLoading
  },
  data() {
    return {};
  },
metaInfo () {
    return { 
          title: this.$t("vueRouter.shopTitle"), 
          titleTemplate: '%s - Tabuaço', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRouter.shopDescription")},
            {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRouter.shopTitle")},
            {name: 'twitter:description', content: this.$t("vueRouter.shopDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRouter.shopTitle")},
            {property: 'og:site_name', content: 'Tabuaço'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
            {property: 'og:description', content: this.$t("vueRouter.shopDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.shop", 'en')])}
          ]
      } 
    }, 
  computed: {
    ...mapState({
      getBannersOutput: (state) => state.products.bannerOutput,
      getFeaturedProductsOutput: state => state.products.featuredOutput, 
      getBestSellersProductsOutput: state => state.products.bestSellersOutput,    
      getPublishDateProductsOutput: state => state.products.publishDateOutput    
    }),
    loading() {
      return this.getBannersOutput == null || this.getFeaturedProductsOutput == null || this.getBestSellersProductsOutput == null || this.getPublishDateProductsOutput == null;
    }
  },
};
</script>

<style scoped>
hr {
  margin: 1rem auto;
}
</style>

<template>
  <div id="app">
    <div id="nav">
      <router-view name="header" @hash-requested="onHashRequested"></router-view>
    </div>
    <router-view :hash="hash" @hash-requested="onHashRequested" ref="container" @success-alert-requested="showSuccessAlert" @error-alert-requested="showErrorAlert" @warning-alert-requested="showWarningAlert"/>
    <router-view name="footer" @hash-requested="onHashRequested"></router-view>
  </div>
</template>
<script>
import ResultPopup from '@/components/popups/ResultPopup.vue';
import Vue from "vue";
import EventBus from '@/events/bus.js';

const SUCCESS = 0;
const WARNING = 1;
const ERROR = 2;

export default {
  name: 'Home',
  created() {
    EventBus.$on('success-alert-requested', this.showSuccessAlert)
    EventBus.$on('warning-alert-requested', this.showWarningAlert)
    EventBus.$on('error-alert-requested', this.showErrorAlert)
  },
  destroyed() {
    EventBus.$on('success-alert-requested')
    EventBus.$on('warning-alert-requested')
    EventBus.$on('error-alert-requested')
  },
  methods: {
    onHashRequested: function(hash)
    {
      if(this.$route.name != "Home")
      {
        this.$router.push("/");
      }

      Vue.prototype.$hash = hash;
      this.$vueEventBus.$emit('hash-changed', hash)
    },
    showSuccessAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(SUCCESS, title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showWarningAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(WARNING, title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showErrorAlert({title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback}){
      this.showAlert(ERROR, title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showAlert(type, title, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback)
    {
      var ComponentClass = Vue.extend(ResultPopup)
      var instance = new ComponentClass({
        propsData: {type: type, title: title, message: message, primaryButtonText: primaryButton, primaryButtonCallback: primaryCallback, secondaryButtonText: secondaryButton, secondaryButtonCallback: secondaryCallback }
      })
      instance.$mount() // pass nothing
      this.$refs.container.$el.appendChild(instance.$el)
    }
  },
}
</script>

<style>
@font-face {
  font-family: 'trajan';
  src:
    url('fonts/Trajan Pro.ttf') format('truetype'),
    url('fonts/TrajanPro-Bold.otf') format('truetype'),
    url('fonts/TrajanPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
  font-family: 'clanpro-news';
  src:
    url('fonts/clanPro/FFClanProRegular.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

#app {
  font-family: 'trajan', 'clanpro-news'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
.primary-color{
  color: #DDB427;
}
.background-color-primary{
  background-color: #DDB427;
}
.btn-primary{
  background-color: #DDB427 !important;
}
a{
  text-decoration: none!important;
}
.cursor-pointer{
  cursor: pointer;
}
.pt-4{
  padding-top: 4rem;
}
.mt-6{
  margin-top: 4rem;
}
.font-weight-normal
{
  font-weight: 300;
}
.font-weight-bold
{
  font-weight: bold;
}
.font-bolder
{
  font-weight: 900;
}
/* text-dec none */
.text-dec-none{
  text-decoration: none;
}
.text-end{
  text-align: end;
}
.text-center{
  text-align: center;
}
.text-justify{
  text-align: justify;
}
hr:not([size]){
  height: 2px!important;
}
.font-clanpro{
  font-family: clanpro-news;
}
.font-clanpro-bold{
  font-family: clanpro-news;
  font-weight: bold;
}
.disabled
{
  pointer-events: none;
  opacity: 0.6;
}
.invalid-feedback{
  font-family: clanpro-news;
  font-weight: bold;
  text-align: left;
  font-size: 0.7rem!important;
}
/* custom popup style */
.swal2-styled.swal2-confirm{
  background-color: #DDB427!important;
  color: black!important;
}
.swal2-styled.swal2-confirm:focus{
  outline: none!important;
  box-shadow: none!important;
}
</style>

<template>
  <section>
      <LogoLoading v-if="!routesOutput"/>
    <div v-else>
        <img class="travel-image-styles" src="@/assets/TravelPaths/TravelPaths.jpg" alt="" loading="lazy">
        <div class="row m-0" :style="{height: '8.1vh'}">
            <div class="col-12 d-flex justify-content-center align-items-center">
                <div class="moving-mouse-holder d-flex align-items-center flex-column">
                    <div class="mouse">
                        <div class="mouse-button"></div>
                    </div>
                    <div class="text-scroll ">
                        {{$t("scroll")}}
                    </div>
                </div>
            </div>
        </div>
        <div v-for="route in routesList" :key="route.id">
            <div class="row" id="titlerow-style">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <span>{{route.title}}</span>
                </div>
            </div>
            <div class="container font-clanpro-bold">
                <div class="row my-3">
                    <div class="col-sm-12 col-md-6 col-lg-6" v-for="routeOption in route.options" :key="routeOption.name">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.route")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.name}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.typology")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.topology}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.difficulty")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.difficulty}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.distance")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.distance}}km | {{formatTime(routeOption.duration)}}</div>
                                    <!-- {{timeFormat}} -->
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.unevenness")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.unevenness}}</div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4 text-end">{{$t("TravelPath.visitPeriod")}}</div> 
                                    <div class="col-sm-12 col-md-8 col-lg-8">{{routeOption.time}}</div>
                                </div>
                            </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <img class="map-points" src="@/assets/TravelPaths/MapPoints.jpg" alt="" loading="lazy">
                    </div>
                    <hr/>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-12 col-md-2 col-lg-2 text-end">{{$t("TravelPath.intro")}}</div> 
                            <div class="col-sm-12 col-md-10 col-lg-10 text-justify">{{route.intro}}</div>
                        </div>
                    </div>
                    <hr/>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-12 col-md-2 col-lg-2 text-end">{{$t("TravelPath.find")}}</div> 
                            <div class="col-sm-12 col-md-10 col-lg-10 text-justify">{{route.content}}</div>
                        </div>
                    </div>
                    <hr/>
                </div> 
                <div class="row" v-if="route.filesPath.length">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-sm-12 col-md-2 col-lg-2 text-end">{{$t("TravelPath.download")}}</div> 
                            <div class="col-sm-12 col-md-10 col-lg-10" v-for="file in route.filesPath" :key="file">
                                <a :href="basePDFPath + '/' + file" target="_blank" class="cursor-pointer">
                                    <img src="@/assets/TravelPaths/pdf-icon.svg" alt="" loading="lazy"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import { UTILS_HELPER } from "@/helpers/utils.js";
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
export default {
    components: {
        LogoLoading
    },
  metaInfo () {
      return { 
            title: `${this.$t("vueRouter.TravelPathsTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.TravelPathsDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.TravelPaths", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.TravelPaths", 'pt')])},
            ]
            
        } 
    },
    mounted() 
    {
        this.updateItemsRequested();
    },
    computed: {
        ...mapState({ 
            routesOutput: state => state.routes.listOutput,
        }),
        routesList() {     
            console.log(this.routesOutput.data)       
            return this.routesOutput && this.routesOutput.data ? this.routesOutput.data: [];
        },
        basePDFPath: function()
        {
            return process.env.VUE_APP_URL_ROOT;
        },
    },
    methods:{
        ...mapActions('routes', ['getList']),

        updateItemsRequested(){
            const itemsPerPage = 10;
            const currentPage = 1;
            const sorter = 2; // descending
            const propertyName = "id";
            const searchText = '';
            
            //Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            
            this.getList(input); 
        },
        formatTime(duration){
            return UTILS_HELPER.formatTime(duration)
        }
    }
}
</script>

<style scoped>
.travel-image-styles{
    width: 100%;
    height: 60vh;
}
.moving-mouse-holder .mouse {
  width: 26px;
  height: 40px;
  position: relative;
  border-radius: 18px;
  border: 2px solid #000000;
}
.moving-mouse-holder .mouse-button {
  background-color: #000000;
  width: 4px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0 0 0 -2px;
  animation: mouse-scroll 1s infinite alternate;
}
.moving-mouse-holder .text-scroll {
  color: black;
  font-size: 15px;
  line-height: 1.5em;
}
@keyframes mouse-scroll {
  to {
    transform: translate(0, 6px) scale(1, 0.8);
  }
}
#titlerow-style{
    width: 100%;
    height: 3rem;
    background-color: #DDB427;
    margin: 0;
}
.map-points{
    width: 100%;
    height: 30vh;
}
hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
@media (max-width: 767px) {
    .text-end{
        text-align: start !important;
    }
}
</style>
<template>
  <div class="container">
    <h2 class="mt-5 mb-4 primary-color font-weight-bold">{{$t("favorites.title")}}</h2>
    <!-- Loading -->
    <div v-if="loading" class="inspiration-container d-flex flex-wrap mt-2">
      <LogoLoading/>
    </div>
    <!-- Content -->
    <div v-else class="inspiration-container d-flex flex-wrap mt-2">
      <FavoriteThumb v-for="item in filterItems" :key="item.id" :favorite="item" @warning-alert="deleteItem(item.slug)"></FavoriteThumb>
      <NoResultsPlaceholder v-if="!favorites.length"></NoResultsPlaceholder>
    </div>
    <div v-if="!loading && canLoadMore" class="see d-flex justify-content-center align-items-center flex-column">
      <b-icon @click="loadMore" icon="plus-circle"></b-icon>
      <p @click="loadMore" class="cursor-pointer">{{ $t("favorites.seeMore") }}</p>
    </div>  
  </div>
</template>
<script>
import FavoriteThumb from "@/components/FavoriteThumb.vue"
import { mapState, mapActions } from "vuex";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  components: {
    FavoriteThumb,
    LogoLoading,
    NoResultsPlaceholder,
  },
  data() {
    return {
      // Expose consts to HTML
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
    }
  },
  metaInfo () {
      return { 
            title: `${this.$t("vueRouter.favoritesTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.favotitesDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.favorites", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.favorites", 'pt')])},
            ]
            
        } 
    },
  mounted () 
  {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions('favorites', ['getList']),
    ...mapActions('favorites', ['removeFavorite']),

    deleteItem(itemSlug)
    {
      this.$emit("warning-alert-requested", { message: this.$t("favorites.removedWarning"), primaryCallback: () =>
      {
        this.removeFavorite(itemSlug);
      }, primaryButton: this.$t("yesPopUp"), secondaryButton: this.$t("noPopUp")});
    },
    loadMore()
    {
      if(this.canLoadMore)
      {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
      }
    },
    updateItemsRequested()
    {
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";
        
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, null);    
      // Get favs
      this.getList(input);
    }
  },
  computed: {
    ...mapState('favorites', ['listOutput']),
    ...mapState('favorites', ['removeTask']),

    loading: function(){
      return this.listOutput == null;
    },
    favorites: function() {
      return this.listOutput && this.listOutput.data ? this.listOutput.data : [];
    },
    filterItems: function(){
      return this.favorites ? this.favorites.filter(product => product.slug ? product.categories !== undefined || product.categories == undefined : product.type === "Poi" || product.type === "Product" ) : [];
    },
    numFavs: function(){
      return this.listOutput && this.listOutput.totalRecords ? this.listOutput.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numFavs > this.itemsPerPage;
    },
  },
  watch:{
    itemsPerPage()
    {
      this.updateItemsRequested();
    },
    removeTask: function(val)
    {
      if(val)
      {
        this.updateItemsRequested();
      }
    }
  }
};
</script>

<style scoped>
.see .bi-plus-circle {
  cursor: pointer;
  font-size: 25px;
  color: #ddb427;
}
.see > p {
  color: #ddb427;
}
.container {
  max-width: 1800px;
  padding: 0 50px;
  min-height: 100vh;
 }
.skeleton-box
{
  min-height: 557px;
}
/* Size of inspiration elements: these are not done using bootstrap to have correct alignments, using gap */
.inspiration-container /* Four columns */
{
  gap: 4%;
}
.inspiration-container > div,
.inspiration-container > span
{
  width: 22%;
  margin-bottom: 4%;
}

@media (max-width: 1450px) 
{
  .inspiration-container
  {
    gap: 5%;
  }

  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 30%;
    margin-bottom: 5%;
  }
}
@media (max-width: 991px) 
{
 
  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 45%;
    margin-bottom: 5%;
  }
}

@media (max-width: 767px) /* Two columns */
{
  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 45%;
    margin-bottom: 10%;
  }
}
@media (max-width: 575px)
{
  .inspiration-container > div,
  .inspiration-container > span
  {
    width: 100%;
    margin-bottom: 10%;
  }
}

</style>

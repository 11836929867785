<template>
  <header v-if="isNotMobile">
    <section id="header">
      <SideMenu @hash-requested="onHashRequested"/>
      <nav class="navbar fixed-top background-color-primary">
        <div class="col-6 col-sm-3 d-flex justify-content-start px-3">
          <div id="navbar-toggle" v-b-toggle.sidebar-left>
            <img id="btn-open" src="@/assets/svg/menu/SideMenu.svg" alt="" loading="lazy">
            <img id="btn-close" class="sizebtn" src="@/assets/svg/close-icon.svg" alt="closeIcon" loading="lazy"/>
          </div>
        </div>
        <div class="col-6 d-none d-sm-flex justify-content-center">
          <a href="/"><img src="@/assets/svg/menu/logo.svg" alt="" :style="{width: '170px'}" loading="lazy"></a>
        </div>
        <div class="col-6 col-sm-3 d-flex justify-content-end align-items-center px-2 px-xxl-3">
          <div class="nav-interaction-icons px-xxl-2">
            <router-link :to="{ name: 'Favorites'}"><img class="cursor-pointer px-xxl-2" src="@/assets/svg/menu/fav.svg" alt="" loading="lazy"></router-link>
            <router-link :to="{ name: 'Cart'}"><img class="cursor-pointer px-xxl-2" src="@/assets/svg/menu/bag.svg" alt="" loading="lazy"></router-link>
            <router-link :to="{ name: 'Account'}"><img class="cursor-pointer px-xxl-2" src="@/assets/svg/menu/user.svg" alt="" loading="lazy"></router-link>
            <img @click="mobileSearch = true" class="cursor-pointer d-xxl-none" src="@/assets/svg/menu/search.svg" alt="" :style="{width: '25px', height: '25px'}" loading="lazy">
          </div>
          <li class="d-none d-xxl-inline nav-search-items">
            <div class="search_field" id="search">
              <input class="inp" :placeholder="$t('topMenu.oQueProcura')" v-model="searchQuery" v-on:keyup.enter="searchClicked"/>
              <button class="search-button" type="submit" @click="searchClicked">
                <img src="@/assets/svg/menu/search.svg" alt="" loading="lazy">
              </button>
            </div>
            <div class="dropdown" id="dropdown">
              <button class="btn .bg-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{ $i18n.locale.toUpperCase() }}</button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a href="#" @click.prevent="setLocale('pt')" ref="account" class="dropdown-item">
                    <span class="ml-2 h-8 w-8">{{ $t("dropDown.portuguese")}}</span>
                  </a>
                </li>
                <li>
                  <a href="#" @click.prevent="setLocale('en')" class="dropdown-item">
                    <span class="ml-2">{{ $t("dropDown.english")}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </div>
      </nav>
      <transition name="fade">
        <div class="search-mobile" v-if="mobileSearch">
          <!-- Close -->
          <div class="close-button cursor-pointer d-flex justify-content-end">
            <p @click="mobileSearch = false"><img src="@/assets/svg/close-icon.svg" class="w-100" loading="lazy"/></p>
          </div>
          <div class="search_field" id="search">
            <div class="d-flex">
              <input class="inp w-100" :placeholder="$t('topMenu.oQueProcura')" v-model="searchQuery" v-on:keyup.enter="searchClicked"/>
              <b-icon class="cursor-pointer" @click="searchClicked" icon="search"></b-icon>
            </div>
          </div>
        </div>
      </transition>
    </section>
  </header>
</template>

<script>
import  SideMenu  from "@/components/SideMenu.vue";

export default {
  name: "AppHeader",
  components: {SideMenu},
  data() {
    return {
      searchQuery: '',
      mobileSearch: false,
      isNotMobile: true,
    };
  },
  created()
  {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var isMobile = params.get("mode");
    if(isMobile == "mobileapp" )
    {
        this.isNotMobile = false;
    }
  },
  methods: {
    onHashRequested: function(hash)
    {
      this.$emit('hash-requested', hash);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.$router.push("/");
      this.$router.go(0);
    },
    searchClicked()
    {
      if(this.searchQuery)
      {
        this.$router.push({name: 'SearchResults', query: { query: this.searchQuery } });
        this.searchQuery = '';
        this.mobileSearch = false
      }
    }
  },
};
</script>

<style scoped>
#header, nav{
  min-height: 70px;
  border-bottom: 1px solid black;
  height: 8.9vh;
}
.collapsed #btn-close,
.not-collapsed #btn-open
{
  display: none;
}
.nav-search-items {
  position: relative;
  white-space: nowrap;
  margin: auto 0;
  list-style: none;
}
.search_field{
  border-bottom: 1px solid black;
}
.search-button {
  background: transparent;
  border: none;
  outline: none;
  /* margin-left: -45px; */
}
.bi-search{
  font-size: 30px;
}
.search-button span {
  position: relative;
  top: 15px;
  left: 10px;
}
.inp {
  border: none;
  background-color: transparent !important;
  color: black;
  outline: none;
  font-size: 0.7rem;
  font-family: clanpro-news;
}
.btn:focus {
  box-shadow: none!important;
}
::placeholder {
  color: black;
  opacity: 1;
}
[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}
.dropdown-menu {
  left: -13%!important;
}
.dropdown-menu a {
  color: black !important;
  margin: 0 10px !important;
  padding: 0px !important;
  text-decoration: none!important;
}
.dropdown > button {
  color: black;
  float: right;
}
.dropdown ul li {
  width: 145px;
}
.dropdown ul a:focus {
  background: #DDB427;
  border-color: #DDB427;
}
.dropdown-toggle::after{
  display: none;
}
.search-mobile{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #DDB427;
  z-index: 9999;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
}
.close-button p{
  width: 30px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  }
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media (max-width: 1399px)
{
  .nav-interaction-icons{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .inp{
    font-size: 1.5rem;
  }
}
</style>
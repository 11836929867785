<template>
  <div v-if="searchQuery" class="container my-5 page-style">
    <span class="search-title">{{$t("searchResults")}}:</span> <span class="search-content">{{searchQuery}}</span>
    <!-- Go back (show when a specific search block is selected) -->
    <SearchPois class="py-3" :searchQuery="searchQuery" :title="$t('whatToSee')" :type="1"/>
    <SearchPois class="py-3" :searchQuery="searchQuery" :title="$t('whereToEat')" :type="2"/>
    <SearchPois class="py-3" :searchQuery="searchQuery" :title="$t('whereToStay')" :type="3"/>
    <SearchProducts class="py-3" :searchQuery="searchQuery"/>
    <SearchNews class="py-3" :searchQuery="searchQuery"/>
    <SearchEvents class="py-3" :searchQuery="searchQuery"/>
  </div>
</template>


<script>
// import { UTILS_HELPER } from "@/helpers/utils.js";
import SearchPois from "@/components/SearchPois.vue"
import SearchProducts from "@/components/SearchProducts.vue"
import SearchNews from "@/components/SearchNews.vue";
import SearchEvents from "@/components/SearchEvents.vue";
import { UTILS_HELPER } from "@/helpers/utils.js";  
import { API_HELPER } from "@/helpers/api.js";

export default {
      metaInfo () {
      return { 
        title: this.searchQuery,
        titleTemplate: '%s - Tabuaço', 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
          // // Twitter Card
          {name: 'twitter:card', content: "summary"},
          {name: 'twitter:title', content:  this.searchQuery},
          {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
          // //Facebook OpenGraph
          {property: 'og:title', content:  this.searchQuery},
          {property: 'og:site_name', content: 'Wild Douro'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assets/logo.png"]) },
          {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        ], 
        link: [
          {rel: 'canonical', href: process.env.VUE_APP_URL_ROOT},
        ]
      } 
    }, 
    components: {
    SearchPois,
    SearchProducts,
    SearchNews,
    SearchEvents
},
    data() {
      return {
        searchQuery: ''
      }
    },
    methods: 
    {
      initializePage () 
      {
        if(!this.$route.query.query)
        {
            this.$router.push({ path: '/' });
        }
        else
        {
            this.searchQuery = this.$route.query.query;
        }
      },
    },
    mounted() {
      this.initializePage();
    },
    watch: {
      $route(to) {
        if (to.name == "SearchResults") {
          this.initializePage();
        }
      },
    },
}
</script>
<style scoped>
.page-style{
  min-height: 100vh;
}
.search-title{
  font-size: 15px;
}
.search-content{
  font-size: 15px;
  color: #DDB427;
  font-weight: bold;
}
</style>
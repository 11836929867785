/* Imports */
import { API_HELPER } from "@/helpers/api.js";


/* Exports */
// API services that can be invoked on the modules
export const MESSAGE_SERVICE = {
    submitMessage
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Message";

/* Services */
function submitMessage(content)
{
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);
    var raw = JSON.stringify(content);
    
    const requestOptions = {
        method: 'POST',
        headers: {  "Content-Type":"application/json" },
        body: raw,
    }

    // Process API call
    return API_HELPER.apiCall(url, null, requestOptions);
}

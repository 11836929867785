<template>
  <section id="footer" v-if="isNotMobile">
    <div class="footer-dark background-color-primary">
      <footer>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-3 col-xxl-2 d-flex justify-content-center justify-content-xxl-start item">
              <ul>
                <li><router-link :to="{ name: 'StaticPages', params: {slug: 'o-projeto'} }">{{ $t("footer.oProjecto") }}</router-link></li>
                <li><router-link :to="{ name: 'News'}">{{ $t("footer.noticias")}}</router-link></li>
                <li><router-link :to="{ name: 'Events'}">{{ $t("footer.agenda")}}</router-link></li>
                <!-- This logic, using the default hash navigation, is not working correctly, so a custom implementation was done -->
                <!-- <li><a href="#support">{{ $t("footer.contatos") }}</a></li> -->
                <li><a @click="$emit('hash-requested', 'support')">{{ $t("footer.contactos") }}</a></li>
              </ul>
            </div>
            <div class="col-12 col-md-3 col-xxl-2 d-flex justify-content-center justify-content-xxl-start item">
              <ul>
                <li><router-link :to="{ name: 'TravelPaths'}">{{ $t("footer.percursos")}}</router-link></li>
                <li><router-link :to="{ name: 'Pois', query: { type: 1 }}">{{ $t("footer.oQueVisitar")}}</router-link></li>
                <li><router-link :to="{ name: 'Pois', query: { type: 2 }}">{{ $t("footer.ondeComer")}}</router-link></li>
                <li><router-link :to="{ name: 'Pois', query: { type: 3 }}">{{ $t("footer.ondeDormir")}}</router-link></li>
              </ul>
            </div>
            <div class="col-12 col-md-3 col-xxl-2 d-flex justify-content-center justify-content-xxl-start item">
              <ul>
                <li><router-link :to="{ name: 'Shop'}">{{ $t("footer.lojaOnline")}}</router-link></li>
                <li><router-link :to="{ name: 'Reservations' }">{{ $t("footer.reservas")}}</router-link></li>
                <li><router-link :to="{ name: 'Gallery' }">{{ $t("footer.galeriaVisual")}}</router-link></li>
              </ul>
            </div>
            <div class="col-12 col-md-3 col-xxl-2 d-flex justify-content-center justify-content-xxl-start item">
              <ul>
                <li><router-link :to="{ name: 'Faqs' }">{{ $t("footer.qa&faq")}}</router-link></li>
                <li><router-link :to="{ name: 'StaticPages', params: {slug: $t('slug.termsConditions') }}">{{ $t("footer.termosCondicoes")}}</router-link></li>
                <li><router-link :to="{ name: 'StaticPages', params: {slug: $t('slug.privacyPolicy') }}">{{ $t("footer.politica")}}</router-link></li>
                <li><router-link :to="{ name: 'StaticPages', params: {slug: $t('slug.cookieUsage') }}">{{ $t("footer.utilizacaoCookies")}}</router-link></li>
              </ul>
            </div>
            <div class="col-12 col-xxl-2 d-flex justify-content-center flex-column align-items-center py-2 p-xxl-0">
              <img src="@/assets/svg/menu/logo.svg" alt="" :style="{width: '200px'}" loading="lazy">
                <IconsSocial></IconsSocial>
            </div>
            <div class="col-12 item">
              <router-link :to="{ name: 'StaticPages', params: {slug: $t('slug.financialIncentives')}}">
                <div class="row d-flex justify-content-center">
                  <img src="@/assets/svg/footer/footer-1.svg" alt="image" loading="lazy"/>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";

export default {
  name: "AppFooter",
  components: {
    IconsSocial
  },
   data() {
    return {
      isNotMobile: true,
    };
  },

  created()
  {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var isMobile = params.get("mode");
    if(isMobile == "mobileapp" )
    {
        this.isNotMobile = false;
    }
  },
}
</script>

<style scoped>
#footer{
  bottom: 0 !important;
}
a {
  text-decoration: none;
  cursor: pointer;
}
p{
  color: black;
  margin-left: 21%;
  width: 61%;
}
.footer-dark {
  padding: 25px 0;
}
.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 2;
  font-size: 0.8rem;
  margin-bottom: 0;
  text-align: left;
  color: black;
}
.footer-dark ul a {
  color: inherit !important;
  text-decoration: none;
}
.footer-dark .item.text {
  margin-bottom: 36px;
}
.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}
.footer-dark .item img {
  width: 50%;
}
.footer-dark ul a:hover,
.footer-dark .item > a:hover,
.footer-dark .icons span:hover {
  opacity: 0.9;
}
@media (max-width: 991px) { 
  .footer-dark .item img {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .footer-dark ul {
    text-align: center;
    padding: 20px;
  }
}
</style>
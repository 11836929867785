<template>
    <div id="gallery">
        <h4 class="fw-bold text-center py-3">{{$t('gallery.title')}}</h4>
        <div class="d-none d-lg-flex justify-content-center flex-wrap p-2">
            <div class="d-flex flex-column" id="first-column">
                <img class="img-fluid m-1 item cursor-pointer" :src="firstImage" @click="showImage(0)" alt="" loading="lazy">
                <img class="img-fluid m-1 item cursor-pointer" :src="secondImage" @click="showImage(1)" alt="" loading="lazy">
            </div>
            <div class="d-flex flex-column">
                <img class="img-fluid h-100 m-1 item-large cursor-pointer" :src="thirdImage" @click="showImage(2)" alt="" loading="lazy">
            </div>
            <div class="d-flex flex-column" id="third-column">
                <img class="img-fluid m-1 item cursor-pointer" :src="fourthImage" @click="showImage(3)" alt="" loading="lazy">
                <img class="img-fluid m-1 item cursor-pointer" :src="fifthImage" @click="showImage(4)" alt="" loading="lazy">
            </div>
            <div class="d-flex flex-column" id="fourth-column">
                <img class="img-fluid m-1 item cursor-pointer" :src="sixthImage" @click="showImage(5)" alt="" loading="lazy">
                <div class="d-flex h-100">
                    <img class="img-fluid m-1 item-small cursor-pointer" :src="seventhImage" @click="showImage(6)" alt="" loading="lazy">
                    <img class="img-fluid m-1 item-small cursor-pointer" :src="eighthImage" @click="showImage(7)" alt="" loading="lazy">
                </div>
            </div>
            <div id="fifth-column" class="d-flex flex-column">
                <img class="img-fluid m-1 h-100 last-item cursor-pointer" :src="ninthImage" @click="showImage(8)" alt="" loading="lazy">
            </div>
        </div>
        <!-- small devices -->
        <div class="row d-lg-none justify-content-center m-0">
            <img class="img-fluid m-1 item cursor-pointer" :src="firstImage" @click="showImage(0)" alt="" loading="lazy">
            <img class="img-fluid m-1 item cursor-pointer" :src="secondImage" @click="showImage(1)" alt="" loading="lazy">
            <img class="img-fluid m-1 item-large cursor-pointer" :src="thirdImage" @click="showImage(2)" alt="" loading="lazy">
            <img class="img-fluid m-1 item cursor-pointer" :src="fourthImage" @click="showImage(3)" alt="" loading="lazy">
            <img class="img-fluid m-1 item cursor-pointer" :src="fifthImage" @click="showImage(4)" alt="" loading="lazy">
            <img class="img-fluid m-1 item cursor-pointer" :src="sixthImage" @click="showImage(5)" alt="" loading="lazy">
            <img class="img-fluid m-1 item-small cursor-pointer" :src="seventhImage" @click="showImage(6)" alt="" loading="lazy">
            <img class="img-fluid m-1 item-small cursor-pointer" :src="eighthImage" @click="showImage(7)" alt="" loading="lazy">
            <img class="img-fluid m-1 h-100 last-item cursor-pointer" :src="ninthImage" @click="showImage(8)" alt="" loading="lazy">
        </div>
        <!-- Gallery pop up -->
        <FullScreenFile v-if="showImageIndex != null" :startIndex="showImageIndex" @closeRequest="showImageIndex = null" :gallery="gallery"></FullScreenFile>
    </div>
</template>

<script>
import FullScreenFile from "@/components/FullScreenFile.vue";
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: {
        FullScreenFile,
    },
    data() {
        return {
            showImageIndex: null
        }
    },
    mounted() 
    {
        this.initializePage();
    },
    computed: 
    {
        ...mapState({ 
            galleryOutput: state => state.gallery.listOutput,
        }),
        gallery() {
            return this.galleryOutput && this.galleryOutput.data ? this.galleryOutput.data: [];
        },
        firstImage: function() {
            return this.gallery && this.gallery[0] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[0].path]) : null; 
        },
        secondImage: function() {
            return this.gallery && this.gallery[1] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[1].path]) : null; 
        },
        thirdImage: function() {
            return this.gallery && this.gallery[2] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[2].path]) : null; 
        },
        fourthImage: function() {
            return this.gallery && this.gallery[3] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[3].path]) : null; 
        },
        fifthImage: function() {
            return this.gallery && this.gallery[4] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[4].path]) : null; 
        },
        sixthImage: function() {
            return this.gallery && this.gallery[5] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[5].path]) : null; 
        },
        seventhImage: function() {
            return this.gallery && this.gallery[6] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[6].path]) : null; 
        },
        eighthImage: function() {
            return this.gallery && this.gallery[7] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[7].path]) : null; 
        },
        ninthImage: function() {
            return this.gallery && this.gallery[8] ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.gallery[8].path]) : null; 
        },
    },
    methods:
    {
        ...mapActions('gallery', ['getList']),

        showImage: function(index)
        {
            this.showImageIndex = index;
        },

        initializePage(){
            
            const itemsPerPage = 9;
            const currentPage = 1;
            const sorter = 1; // ascending
            const propertyName = "id";
            const searchText = '';
            
            //Make request
            var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
            
            this.getList(input); 
        }
    }
}
</script>

<style scoped>
.item{
    max-width: 303px;
    max-height: 209px;
    object-fit: cover;
}
.last-item{
    max-width: 303px;
    object-fit: cover;
}
.item-large{
    max-width: 616px;
    max-height: 426px;
    object-fit: cover;
}
.item-small{
    max-width: 146px;
    max-height: 208px;
    object-fit: cover;
}
@media (max-width: 991px) {
    .item-large,
    .item-small,
    .item,
    .last-item{
        max-width: 400px;
        max-height: 400px;
    }
}
</style>
<template>
    <div>
        <div class="row">
            <div v-if="firstImage" class="col-12 cursor-pointer" @click="showImage(0)">
                <img class="w-100" :src="firstImage" alt="" loading="lazy"/>
            </div>
            <div v-if="secondImage" class="col-6 cursor-pointer d-flex mt-4" @click="showImage(1)">
                <img class="w-100" :src="secondImage" alt="" loading="lazy"/>
            </div>
            <div v-if="thirdImage" class="col-6 cursor-pointer d-flex mt-4 position-relative" @click="showImage(2)">
                <img class="w-100" :src="thirdImage" alt="" loading="lazy"/>
                <div class="gallery-more position-absolute d-flex align-items-end justify-content-end" v-if="plusNumber"><span>+ {{plusNumber}}</span></div>
            </div>
        </div>
        <FullScreenFile v-if="showImageIndex != null" :startIndex="showImageIndex" @closeRequest="showImageIndex = null" :gallery="gallery"></FullScreenFile>
    </div>
</template>

<script>
import FullScreenFile from "@/components/FullScreenFile.vue";

export default {
    data: function () 
    {
        return {
            showImageIndex: null
        };
    },  
    components: { FullScreenFile },
    props : { gallery: Array },
    computed: 
    {
        firstImage: function() {
            return this.gallery && this.gallery.length ? this.gallery[0] : '';
        },
        secondImage: function() {
            return this.gallery && this.gallery.length >= 2 ? this.gallery[1] : '';
        },
        thirdImage: function() {
            return this.gallery && this.gallery.length >= 3 ? this.gallery[2] : '';
        },
        plusNumber: function()
        {
            return this.gallery && this.gallery.length >=4 ? this.gallery.length - 3 : 0;
        }
    },
    methods:
    {
        showImage: function(index)
        {
            this.showImageIndex = index;
        },
    }
}
</script>

<style scoped>
img
{
  object-fit: contain;
  height: 100%;
}

.gallery-more
{
    background-color: rgba(0,0,0,0.5);
    top: 0;
    right: calc(var(--bs-gutter-x) * .5);
    left: 50%;
    bottom: 0;
    min-height: auto;
    padding: 0.5rem 1rem;
    color: white;
}

@media (max-width: 1199px) {
    .gallery-more
    {
        padding: 0.5rem;
        min-height: auto;

    }
}
</style>
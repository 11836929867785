<template>
    <section>
        <div class="container p-0">
            <div class="col-12">
                <!-- <img class="img-fluid" src="@/assets/map/map.png" alt="" loading="lazy"> -->
                <div class="col-12 mb-4 card p-0">
                    <vue-three-sixty 
                        :amount=36
                        imagePath="https://scaleflex.cloudimg.io/v7/demo/suv-orange-car-360"
                        fileName="orange-{index}.jpg"
                        spinReverse
                        autoplay
                        buttonClass="dark"
                        scrollImage
                    >
                        <template v-slot:header>
                        <div class="v360-header text-light">
                            <span class="v360-header-title">{{$t('map.title')}}</span>
                            <span class="v360-header-description"></span>
                        </div>
                        </template>
                    </vue-three-sixty>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import VueThreeSixty from 'vue-360'
export default {
    mounted() {
    //safari zoom bug fix
    this.disableZoomin();
  },
  methods: {
    disableZoomin(){
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
          document.body.style.zoom = 0.99;
      });
      document.addEventListener("gesturechange", function (e) {
        e.preventDefault();
        document.body.style.zoom = 0.99;
      });
      
      document.addEventListener("gestureend", function (e) {
          e.preventDefault();
          document.body.style.zoom = 1;
      });
    }
  }
}
</script>

<style scoped>
.title{
    height: 60px;
}
.v360-header {
    background-color: #DDB427 !important;
    padding: 10px;
    color: #FFFFFF;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
    flex: 0 1 auto;
}
</style>
<template>
    <BasePopUp @detailsClosed="closeConfirmRequested">
        <template v-slot:content>
            <div class="popup-addresses d-flex">
                <div v-if="billingAddress " class="modal-body">
                    <h4 class="mb-4 text-left fw-bold">{{ $t("address.billAddress") }}</h4>
                    <p v-if="billingAddress.firstName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.firstName") }}: </span>{{billingAddress.firstName}}</p>
                    <p v-if="billingAddress.lastName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.lastName") }}: </span>{{billingAddress.lastName}}</p>
                    <p v-if="billingAddress.email" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.email") }}: </span>{{billingAddress.email}}</p>
                    <p v-if="billingAddress.phoneNumber" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.phoneNumber") }}: </span>{{billingAddress.phoneNumber}}</p>
                    <p v-if="billingAddress.nationality" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nationality") }}: </span>{{billingAddress.nationality}}</p>
                    <p v-if="billingAddress.vat" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nif") }}: </span>{{billingAddress.vat}}</p>
                    <p v-if="billingAddress.address" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.address") }}: </span>{{billingAddress.address}}</p>
                    <p v-if="billingAddress.zipCode" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.zip") }}: </span>{{billingAddress.zipCode}}</p>
                    <p v-if="billingAddress.city" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.city") }}: </span>{{billingAddress.city}}</p>
                    <p v-if="billingAddress.country" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.country") }}: </span>{{billingAddress.country}}</p>
                </div>
                <div v-if="shippingAddress" class="modal-body">
                    <h4 class="mb-4 text-left fw-bold">{{ $t("address.shipAddress") }}</h4>
                    <p v-if="shippingAddress.firstName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.firstName") }}: </span>{{shippingAddress.firstName}}</p>
                    <p v-if="shippingAddress.lastName" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.lastName") }}: </span>{{shippingAddress.lastName}}</p>
                    <p v-if="shippingAddress.email" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.email") }}: </span>{{shippingAddress.email}}</p>
                    <p v-if="shippingAddress.phoneNumber" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.phoneNumber") }}: </span>{{shippingAddress.phoneNumber}}</p>
                    <p v-if="shippingAddress.nationality" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nationality") }}: </span>{{shippingAddress.nationality}}</p>
                    <p v-if="shippingAddress.vat" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.nif") }}: </span>{{shippingAddress.vat}}</p>
                    <p v-if="shippingAddress.address" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.address") }}: </span>{{shippingAddress.address}}</p>
                    <p v-if="shippingAddress.zipCode" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.zip") }}: </span>{{shippingAddress.zipCode}}</p>
                    <p v-if="shippingAddress.city" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.city") }}: </span>{{shippingAddress.city}}</p>
                    <p v-if="shippingAddress.country" class="mb-2 text-left"><span class="fw-bold">{{ $t("address.country") }}: </span>{{shippingAddress.country}}</p>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-primary border-0 mx-2 px-3" @click="closeConfirmRequested">{{ $t("address.close") }}</button>
        </template>    
    </BasePopUp>        
</template>

<script>
import BasePopUp from './BasePopUp.vue';

export default {
    props: {
        billingAddress: {
            type: Object
        },
        shippingAddress: {
            type: Object
        },
    },
    components: {
        BasePopUp,
    },
    methods:{
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        }
    }
}
</script>

<style scoped>
@media screen and (max-width: 576px) {
  .popup-addresses{
    flex-direction: column;
  }
}
</style>
<template>
    <div class="row d-flex justify-content-center py-4">
        <div class="col p-1">
            <a target="_blank" href="https://www.facebook.com/CMTabuaco" rel="noopener"><img src="@/assets/svg/iconsSocial/face-icon.svg" alt="" loading="lazy"/></a>
        </div>
        <div class="col p-1">
            <a target="_blank" href="https://www.instagram.com/camaramunicipaldetabuaco/?hl=pt" rel="noopener"><img src="@/assets/svg/iconsSocial/insta-icon.svg" alt="" loading="lazy"/></a>
        </div>
        <div class="col p-1">
            <a target="_blank" href="" rel="noopener"><img src="@/assets/svg/iconsSocial/twitter-icon.svg" alt="" loading="lazy"/></a>
        </div>
        <div class="col p-1">
            <a target="_blank" href="" rel="noopener"><img src="@/assets/svg/iconsSocial/linkedin-icon.svg" alt="" loading="lazy"/></a>
        </div> 
        <div class="col p-1">
            <a target="_blank" href="" rel="noopener"><img src="@/assets/svg/iconsSocial/youtube-icon.svg" alt="" loading="lazy"/></a>
        </div> 
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
a{
    text-decoration: none;
}

</style>
<template>
  <section>
    <LogoLoading v-if="!detailPage"/>
    <div v-else>
      <div class="about-text py-4" :class="{ generalBgColor }">
        <img v-if="$route.params.slug != 'o-projeto'" class="img-logo img-fluid" src="@/assets/staticLogo.png" alt="" loading="lazy">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="section-title fw-bold text-center">
                {{detailPage.title}}
              </div>
              <hr>
              <div class="p font-clanpro" v-html="detailPage.content"></div>
            </div>
          </div>
        </div>
        <div v-if="$route.params.slug == 'o-projeto'" class="b-color py-2 container-fluid">
          <h4 class="text-center">Os promotores</h4>
          <div class="row flex-wrap">
            <div class="col py-4" v-for="promoter in promoters" :key="promoter.id">
              <div class="d-flex justify-content-center">
                <div class="vl"></div>
                <img class="px-3" :src="promoter.image" alt="" loading="lazy">
                <div class="vl"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";  

export default {
  components: {
    LogoLoading
  },
   metaInfo () {
    var result = 
    { 
      title: this.detailPage ? `${this.detailPage.title} - Tabuaço` : "Tabuaço", 
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.detailPage && this.detailPage.content  ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
        {name: 'keywords', content: this.$t("vueRouter.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: this.detailPage ? `${this.detailPage.title} - Tabuaço` : "Tabuaço"},
        {name: 'twitter:description', content: this.detailPage && this.detailPage.content ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assetslogo.png"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: this.detailPage ? `${this.detailPage.title} - Tabuaço` : "Tabuaço"},
        {property: 'og:site_name', content: 'Tabuaço'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, "assetslogo.png"]) },
        {property: 'og:description', content: this.detailPage && this.detailPage.content ? UTILS_HELPER.htmlToText(this.detailPage.content) : this.$t("intro")},
      ], 
    };

    if(this.$route.query.id)
    {
      result.link = 
      [
        {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.page", 'pt')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.page", 'en')])}?id=${this.$route.query.id}`},
      ];
    }

    return result; 
  }, 
data() {
    return {
      promoters: [
        {id:0 ,image: require('@/assets/promoter.png')},
        {id:1 ,image: require('@/assets/promoter.png')},
        {id:2 ,image: require('@/assets/promoter.png')},
        {id:3 ,image: require('@/assets/promoter.png')},
        {id:4 ,image: require('@/assets/promoter.png')},
        {id:5 ,image: require('@/assets/promoter.png')},
        {id:6 ,image: require('@/assets/promoter.png')},
        {id:7 ,image: require('@/assets/promoter.png')},
        {id:8 ,image: require('@/assets/promoter.png')},
        {id:9 ,image: require('@/assets/promoter.png')},
        {id:10 ,image: require('@/assets/promoter.png')},
        {id:11 ,image: require('@/assets/promoter.png')}
      ]
    };
  },
mounted() 
  {
    this.initializePage();
  },
computed: {
    ...mapState("pages", ["detailOutput"]),
    detailPage: function () {
      console.log(this.detailOutput)
      return this.detailOutput ? this.detailOutput : null;
    },
    generalBgColor()
    {
      return this.$route.params.slug !== 'o-projeto';
    }
  },
  methods:
  {
    ...mapActions('pages', ['getDetails']),

    initializePage()
    {
      if (!this.$route.params.slug) {
        router.push({ name: "Home" });
      }else{
        this.getDetails(this.$route.params.slug);
       }
    }
  },
  watch: {
    $route(to) {
      if(to.name)
      {
        this.initializePage();
      }
    },
  }
};
</script>

<style scoped>
.generalBgColor{
  background-color: #E6E6E6;
}
.img-logo{
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.about-text {
  position: relative;
  min-height: 100vh;
}
.section-title {
  font-size: 40px;
  line-height: 56px;
  margin-bottom: 24px;
  color: black;
}
.p {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 32px;
  color: black;
}
.b-color{
  background-color: #E6E6E6;
}
.vl {
  border-left: 2px dotted black;
  height: 100px;
}
@media (min-width: 1806px) {
  .container-fluid{
    padding: 0 300px;
  }
}
@media (max-width: 1805px) {
  .img-logo{
    display: none;
  }

}
</style>

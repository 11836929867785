<template>
    <div class="row row-spaces" :style="{'flex-direction':flexDirection}">
        <div class="p-0 m-0 col-sm-12 col-md-6 col-lg-6" v-if="imageUrl">
            <img class="image-style" :src="imageUrl" alt="" loading="lazy">
        </div>
        <div class="p-0 m-0 col-sm-12 div-style content-align" :class="[(imageUrl ? 'col-md-6' : '')]">
            <h2 class="text-center">{{title}}</h2>
            <button @click="reservationInfo = !reservationInfo" class="button font-clanpro-bold">{{callToAction}}</button>
        </div>
        <div v-if="reservationInfo" class="row p-4 m-0 row-style">
            <div class="col-sm-12 col-md-12 square-style p-0 slot-empty" :class="[(!imageUrl ? 'col-lg-12' : 'col-lg-4')]">
                <slot name="slotContent">
                    <!-- Content goes here -->
                </slot>
            </div>
            <div class="col-sm-12 col-md-12" :class="[(!imageUrl ? 'col-lg-12' : 'col-lg-8')]">
                <slot name="parent-rectangle">
                    <!-- Content goes here -->
                    <div class="row content-style">
                        <h4>{{titleRetangle}}</h4>
                        <p class="font-clanpro">{{textRetangle}}</p>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    data() {
      return {  
        reservationInfo: false
      }
    },
    props:
    {
        showModal: {
            type: Boolean,
            required: false,
        },
        title:{
            type: String,
            required: false,
        },
        titleRetangle:{
            type: String,
            required: false,
        },
        textRetangle:{
            type: String,
            required: false,
        },
        imageUrl:{
            type: String,
            required: false,
        },
        flexDirection: {      
            type: String,      
            required: false  
        },
        callToAction: {      
            type: String,      
            required: false  
        }
    }
};
</script>

<style scoped>

.slot-empty:empty{
    display: none;
}
.row-style{
    background-color: #DBCEA4;
    flex-wrap: wrap;
}
.square-style{
    background-color: #DDB427;
    border: 1px solid black;
}
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.div-style{
  background-color: #cfcbcb;
  height: 439px;
}
.content-align{
  display: grid;
  justify-content: center;
  align-content: center;
}
.button{
  background-color: transparent !important;
}
.image-style{
  object-fit: cover;
  width: 100%;
  height: 439px;
}
.row-spaces{
  margin: 20px 0;
  flex-wrap: wrap;
  flex-direction: column;
}
.content-style{
    margin: 30px;
}
#small-ecample-responsivity{
  display: flex;
}
@media (max-width: 400px) {
  #small-ecample-responsivity{
    display: grid !important;
  }
}
</style>
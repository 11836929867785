<template>
<section>
  <div id="image-BG">
    <div class="container">
      <div class="row">
        <!-- Title -->
        <div class="d-flex col-sm-12 col-md-6 col-lg-6">
          <h2>{{poiFullDetail.title}}</h2>
        </div>
        <!-- Navigation buttons -->
        <div id="nav-buttons" class="d-flex col-sm-12 col-md-6 col-lg-6">
          <img
            v-if="!isFavorite"
            @click="favoriteButtonClicked"
            class="cursor-pointer favIcon"
            src="@/assets/shop/favIcon.svg"
            :style="{ width: '25px' }"
            loading="lazy"
          />
          <img
            v-if="isFavorite"
            @click="favoriteButtonClicked"
            class="cursor-pointer favIcon"
            src="@/assets/shop/isFav.svg"
            :style="{ width: '25px' }"
            loading="lazy"
          />
        <a @click="$router.back()" class="backBtn cursor-pointer">{{ $t("goBack")}}</a> 
        </div>
        <hr>
        <div class="col-12 col-md-8" >
          <!-- Title -->
          <div class="title-section d-flex align-items-center flex-wrap">
            <span class="font-clanpro-bold">{{category}}</span>
            <span v-if="this.poiDetail.rating">
              <!-- Rating component -->
              <StarRating :rating="this.poiDetail.rating"></StarRating>
            </span>
          </div>
          <!-- XS, SM Gallery -->
          <ThreePartGallery v-if="gallery != null" class="d-md-none col-12 my-3" :gallery="gallery"></ThreePartGallery>
          <!-- Descriptions -->
          <div class="font-clanpro mt-4" v-html="poiFullDetail.content"></div>
          <!-- Price -->
          <div class="row mt-4" v-if="poiFullDetail.minPrice != null && poiFullDetail.maxPrice != null">
            <div class="col-12">
              <span class="font-clanpro-bold">{{$t("shop.priceRange")}}</span>
            </div>
            <div class="col-12">
              <span>{{poiFullDetail.minPrice}}€</span>
              <span class="font-clanpro"> {{$t("shop.to")}} </span>
              <span>{{poiFullDetail.maxPrice}}€</span>
            </div>
          </div>
          <!-- Contact -->
          <div class="row mt-4">
            <div class="col-12">
              <span class="font-clanpro-bold">{{$t("shop.contact")}}</span>
            </div>
            <div class="col-12">
              <span class="font-clanpro">{{poiFullDetail.contact}}</span>
            </div>
          </div>
          <!-- Address -->
          <div class="row mt-4">
            <div class="col-12">
              <span class="font-clanpro-bold">{{$t("shop.address")}}</span>
            </div>
            <div class="col-12">
              <span class="font-clanpro">{{poiFullDetail.address}}</span>
              <br>
              <span class="font-clanpro">{{poiFullDetail.latitude}}, </span>
              <span class="font-clanpro">{{poiFullDetail.longitude}}</span>
            </div>
          </div>
          <!-- WebSite -->
          <div class="row mt-4">
            <div class="col-12">
              <span class="font-clanpro-bold">{{$t("shop.website")}}</span>
            </div>
            <div class="col-12">
              <span class="font-clanpro">{{poiFullDetail.website}}</span>
            </div>
          </div>
        </div>
        <!-- MD+ Gallery -->
        <ThreePartGallery v-if="gallery != null" class="d-none d-md-block col-4 my-5" :gallery="gallery"></ThreePartGallery>
      </div>
    </div>
    <iframe class="mt-3" width="100%" height="320" :src="mapUrl" title="Main content" name="contents"></iframe>
  </div>
</section>
</template>

<script>
import StarRating from "@/components/StarRating.vue"
import { API_HELPER } from "@/helpers/api.js";
import ThreePartGallery from "@/components/ThreePartGallery.vue";
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: {ThreePartGallery, StarRating},
  props: {
    poiDetail: Object
  },
  mounted () {
    this.getFavsRequested();
  },
  computed:{
    ...mapState("favorites", ["listOutput"]),
    ...mapState("favorites", ["addTask"]),
    ...mapState("favorites", ["removeTask"]),
    ...mapState('pois', ['poiOutput']),
    poiFullDetail: function()
    {
      return this.poiOutput ? this.poiOutput : [];
    },
    gallery: function()
    {
      let images = this.poiFullDetail.imagesPath;
      images.push(this.poiFullDetail.featuredImagePath);
      return images.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file]));
    },
    mapUrl: function()
    {
      return this.poiFullDetail ? `https://maps.google.com/maps?q=${this.poiFullDetail.latitude},${this.poiFullDetail.longitude}&hl=es;z=14&output=embed` : null;
    },
    seeAllRoute: function()
    {
      const result = this.$t("vueRouter.pois");
      const type = this.poiDetail.categories[0].type;

      switch (type) 
      {
        case "See":
          return result + "?type=1";
        case "Eat":
          return result + "?type=2";
        case "Stay":
          return result + "?type=3";
      }

      return 'Home';
    },
    category: function()
    {
      return this.poiDetail && this.poiDetail.categories && this.poiDetail.categories.length ? this.poiDetail.categories[0].name : "";
    },
    isFavorite: function () {
      return this.listOutput && this.listOutput.data
        ? this.listOutput.data.find((x) => x.slug == this.poiDetail.slug)
        : false;
    },
  },
  methods: {
    ...mapActions("favorites", ["getList"]),
    ...mapActions("favorites", ["addFavorite"]),
    ...mapActions("favorites", ["removeFavorite"]),

    getFavsRequested() {
      const itemsPerPage = 999;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";

      //Make request
      var inputGetFavorites = new InputGetList(
        itemsPerPage,
        currentPage,
        sorter,
        propertyName,
        null
      );

      // Get favs
      this.getList(inputGetFavorites);
    },

    favoriteButtonClicked() {
      if (this.isFavorite) {
        this.removeFavorite(this.poiDetail.slug);
      } else {
        this.addFavorite(this.poiDetail.slug);
      }
    },
  },
  watch: {
    addTask: function (val) {
      if (val) {
        this.getFavsRequested();
      }
    },
    removeTask: function (val) {
      if (val) {
        this.getFavsRequested();
      }
    },
  },
};
</script>

<style scoped>
.title-section
{
  gap: 4px;
}
h6, h3{
  display: inline;
  margin: 0;
}

.h-separator
{
  font-size: 22px;
}

.backBtn{
  text-decoration: none;
  color: black;
  padding: 0.5rem;
  align-self: center;
}

hr{
  opacity: 1 !important;
  background-color:transparent;
  border: 1px solid black !important;
  margin: 1rem auto;
}
#image-BG{
  background-image: url("../assets/shop/tabuacoBG.svg");
  background-repeat: no-repeat;
  object-fit: cover;
}
#nav-buttons
{
  justify-content: flex-end;
}
@media (min-width: 991px) {
  .container
  {
    margin-top: 3rem;
  }
}
@media (max-width: 991px)
{
  .container
  {
    margin-top: 1rem;
  }
}
@media (max-width: 850px)
{
  #image-BG{
    background-image: none;
  }
}
@media (min-width: 700px)
{
  .favIcon{
  width: 20px;
  height: 49px;
  }
}
</style>

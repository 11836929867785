<template>
  <div class="container">
    <!-- Header and initial load more -->
    <div class="d-flex justify-content-between">
      <div class="col-6 title" :class="{'col-12': !loading && !newsOutput.length}">{{$t('news.title')}}</div>
        <div v-if="!loading && canLoadMore && itemsPerPage == DEFAULT_ITEMS_PER_PAGE" class="see d-flex align-items-center cursor-pointer" @click="loadMore">
          <div class="px-2">
              <span>{{$t("seeMore")}}</span>
          </div>
          <b-icon icon="plus-circle"></b-icon>
        </div>
        <div v-if="itemsPerPage != DEFAULT_ITEMS_PER_PAGE" @click="itemsPerPage = DEFAULT_ITEMS_PER_PAGE" class="d-flex align-items-center cursor-pointer">
            <div class="px-2">
                <b-icon icon="dash-circle"></b-icon>
            </div>
            <span>{{$t("seeLess")}}</span>
        </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="news-container d-flex justify-content-between flex-wrap mt-2">
      <LogoLoading/>
    </div>
    <!-- Content -->
    <div v-else class="news-container d-flex justify-content-between flex-wrap mt-2">
      <NewsThumb class="news-thumb" v-for="news in newsOutput" :key="news.id" :newsContent="news" />
      <NoResultsPlaceholder v-if="!newsOutput.length"></NoResultsPlaceholder>
    </div>
  </div>
</template>

<script>
import NewsThumb from "@/components/HomePage/NewsThumb.vue"
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue"

const DEFAULT_ITEMS_PER_PAGE = 4;

export default {
    components: {
      NewsThumb,
      LogoLoading,
      NoResultsPlaceholder
    },
    props: {
      searchQuery: String
    },
    data() {
        return {
          initialized: false,
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
          //
          DEFAULT_ITEMS_PER_PAGE: DEFAULT_ITEMS_PER_PAGE,
          newsOutput: []
        }
    },
    mounted() {
      // GET list of news
      this.updateItemsRequested();
    },
    methods: {
      ...mapActions('news', ['getList']),

      updateItemsRequested() {
        const currentPage = 1;
        const searchText = this.searchQuery;
        const sorter = 2; // desc
        const propertyName = "id";

        //Make request
        var input = new InputGetList(this.itemsPerPage, currentPage, sorter, propertyName, searchText);

        this.getList(input);
      },
      loadMore() {
        if (this.canLoadMore) {
          this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE;
        }
      }
    },
    computed: {
      ...mapState({
        newslistOutput: state => state.news.listOutput,
      }),
      loading: function () {
        return this.newslistOutput == null;
      },
      numNews: function () {
        return this.newslistOutput && this.newslistOutput.totalRecords ? this.newslistOutput.totalRecords : 0;
      },
      canLoadMore: function () {
        return this.numNews > this.itemsPerPage;
      },
    },
    watch: {
      itemsPerPage() {
        this.updateItemsRequested();
      },
      searchQuery: function () {
        this.updateItemsRequested();
      },
      newslistOutput: function () {
        this.newsOutput = this.newslistOutput && this.newslistOutput.data && this.newslistOutput.data.length ? this.newslistOutput.data : [];
      }
    }
}
</script>

<style scoped>
.title {
    font-size: 25px;
}

>>>#wrapper-mobile {
    height: 100%;
}

.news-container {
    gap: 1rem;
}

>>>#wrapper-desktop,
.news-container>div,
.news-container>span {
    width: 15vw;
    height: 15vw;
    margin-bottom: 2%;
}

>>>.overlay {
    display: none;
}

>>>.info>div {
    width: 50%;
}

>>>#wrapper-desktop {
    display: flex !important;
}

@media (max-width: 1399px) {

    >>>#wrapper-desktop,
    .news-container>div,
    .news-container>span {
        width: 25vw;
        height: 25vw;
        margin-bottom: 5%;
    }
}

@media (max-width: 1199px) {

    >>>#wrapper-desktop,
    .news-container>div,
    .news-container>span {
        width: 20vw;
        height: 20vw;
    }
}

@media (max-width: 991px)

/* Two columns */
    {

    >>>#wrapper-desktop,
    .news-container>div,
    .news-container>span {
        width: 30vw;
        height: 30vw;
    }

    >>>.card-info-date,
    >>>.card-info-category {
        font-size: 0.6rem !important;
    }

    >>>#wrapper-mobile {
        display: none !important;
    }
}

@media (max-width: 767px) {

    >>>.card-info-date,
    >>>.card-info-category {
        font-size: 0.5rem !important;
    }
}

@media (max-width: 575px)

/* one column */
    {

    >>>#wrapper-desktop,
    .news-container>div,
    .news-container>span {
        width: 100%;
        height: 100%;
        margin-bottom: 10%
    }

    >>>.card-info-date,
    >>>.card-info-category {
        font-size: .6rem !important;
    }
}
</style>

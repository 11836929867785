<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="no-results d-flex flex-column justify-content-center align-items-center p-5 w-100">
            <img class="no-results-img my-2" src="../assets/logo.png"/>
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">{{$t("page404.title")}}</h4>
            <p class="text-muted">{{$t("page404.text")}}</p>
            <button class="btn" @click="goHome">{{$t("page500.homePage")}}</button>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
export default {
  name: 'Page404',
   metaInfo () {
      return { 
            title: `${this.$t("vueRouter.errorPageTitle")} - Tabuaço`, 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRouter.errorPageDescription")},
            ],
            link: [
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.errorPage", 'en')])},
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$t("vueRouter.errorPage", 'pt')])},
            ]
        } 
    },
  methods: 
  {
    goHome: function()
    {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
.no-results-img
{
    width: 30%;
    min-width: 200px;
}
.btn {
  background: #DDB427;
  color: white;
  padding: 5px 100px;
  font-size: 20px;
}
.btn:hover {
  color: white;
}
</style>
import { render, staticRenderFns } from "./RelatedSlider.vue?vue&type=template&id=602af17b&scoped=true&"
import script from "./RelatedSlider.vue?vue&type=script&lang=js&"
export * from "./RelatedSlider.vue?vue&type=script&lang=js&"
import style0 from "./RelatedSlider.vue?vue&type=style&index=0&id=602af17b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "602af17b",
  null
  
)

export default component.exports
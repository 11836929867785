<template>
    <div id="hero">
      <vueper-slides
        v-if="heros && heros.length"
        fixed-height="83vh" 
        :arrows="false"
        class="img-slide" 
        :touchable="false" 
        autoplay
        :duration="10000"
        :breakpoints="{992: { touchable: true, bullets:false}}"
      >
        <vueper-slide v-for="(hero, i) in heros" :key="i">
          <template v-slot:content>
            <HeroThumb :heroContent="hero" />
          </template>
        </vueper-slide>
        <template #bullet="{ active }">
          <div :class="active ? 'check_rectangle' : 'unchecked_rectangle'"></div>
        </template>
      </vueper-slides>
  
      <div class="row m-0" :style="{height: '8.1vh'}">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="moving-mouse-holder d-flex align-items-center flex-column">
            <div class="mouse">
                <div class="mouse-button"></div>
            </div>
            <div class="text-scroll ">
                {{$t("scroll")}}
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import HeroThumb from '@/components/HomePage/HeroThumb.vue';
import InputGetList from "@/classes/InputGetList";
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    VueperSlides, VueperSlide, HeroThumb
  },
  mounted() 
  {
    this.initializePage()
  },
  data() {
      return {
        
      }
  },
  computed: {
    ...mapState({ 
      herosOutput: state => state.hero.listOutput,
    }),
    heros: function () {
      return this.herosOutput && this.herosOutput.data && this.herosOutput.data.length ? this.herosOutput.data : [];
    },
  },
  methods: {
    ...mapActions('hero', ['getList']),

    initializePage()
    {
      // GET list of hero
      const itemsPerPage = 10;
      const currentPage = 1;
      const sorter = 1; // asc
      const propertyName = "id";
      const searchText = '';
      
      //Make request
      var input = new InputGetList(itemsPerPage, currentPage, sorter, propertyName, searchText);
      
      // List specific parameters
      input.visible= true;

      this.getList(input); 
    }
  },
}
</script>

<style scoped>
.moving-mouse-holder .mouse {
  width: 26px;
  height: 40px;
  position: relative;
  border-radius: 18px;
  border: 2px solid #000000;
}
.moving-mouse-holder .mouse-button {
  background-color: #000000;
  width: 4px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0 0 0 -2px;
  animation: mouse-scroll 1s infinite alternate;
}
.moving-mouse-holder .text-scroll {
  color: black;
  font-size: 15px;
  line-height: 1.5em;
}
@keyframes mouse-scroll {
  to {
    transform: translate(0, 6px) scale(1, 0.8);
  }
}
.check_rectangle{
  width: 250px;
  height: 5px;
  background-color: white;
}
.unchecked_rectangle{
  width: 30px;
  height: 5px;
  background-color: #DDB427;
}
</style>
<template>
    <section id="support">
        <div class="background">
            <div class="container">
                <div class="row col-12 form">
                    <!-- Talk with us -->
                    <div class="col-12 col-lg-5" id="support">
                        <div class="row">
                            <h3 class="mt-3 text-light">Loja Interactiva de Turismo</h3>
                            <div class="mt-2 text-light">
                                <span class="d-block">Morada: Rua Macedo Pinto</span>
                                <span class="d-block">5120-400 Tabuaço</span>
                                <span class="d-block">Tel: +351 254 789 049</span>
                                <span class="d-block">{{$t("callTo")}}</span>
                                <span class="d-block">E-mail: welcome@visit-tabuaco.com</span>
                            </div>
                        </div>
                        <form style="padding: 1.9rem 0">
                            <h2 class="font-weight-bold text-light">{{ $t('contact.faleConnosco') }}</h2>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group mt-4">
                                            <input type="text" class="form-control" :placeholder="$t('contact.nome')" v-model="firstName" :class="{ 'is-invalid': submitted && hasError('FirstName') }">
                                            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                                                <span v-if="!$v.firstName.required">{{$t("contact.invalidFirstName")}}</span>
                                                <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group mt-4">
                                            <input type="text" class="form-control" :placeholder="$t('contact.apelido')" v-model="lastName" :class="{ 'is-invalid': submitted && hasError('LastName') }">
                                            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                                                <span v-if="!$v.lastName.required">{{$t("contact.invalidLastName")}}</span>
                                                <span v-if="errors.LastName">{{errors.LastName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mt-4">
                                            <input type="email" class="form-control" id="email" :placeholder="$t('contact.email')" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }">
                                            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                                                <span v-if="!$v.email.required">{{$t("contact.invalidEmail")}}</span>
                                                <span v-if="!$v.email.email">{{$t("contact.invEmail")}}</span>
                                                <span v-if="errors.Email">{{errors.Email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mt-4">
                                            <textarea class="form-control" id="Textarea1" rows="4" :placeholder="$t('contact.mensagem')" v-model="message" :class="{ 'is-invalid': submitted && hasError('Message') }"></textarea>
                                            <div v-if="submitted && hasError('Message')" class="invalid-feedback">
                                                <span v-if="!$v.message.required">{{$t("contact.invalidContent")}}</span>
                                                <span v-if="errors.Message">{{errors.Message}}</span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div :class="{disabled: loading}" class="col mt-3 btn btn-button w-100" @click="submitClicked">
                                    <span v-if="!loading" class="text-uppercase">{{ $t("contact.submeter")}}</span>
                                    <span v-if="loading" class="sr-only">{{$t("loading")}}</span>
                                    <span v-if="loading" class="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                </div>
                            </form>
                    </div>
                    <!-- Map -->
                    <div id="directions" class="col-12 col-lg-7">
                        <h2 class="font-weight-bold mt-3 text-light">{{ $t('contact.comoChegar') }}</h2>
                        <iframe class="mt-4" width="100%" height="480" :src="MAP_URL" title="Main message" name="contents"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import { UTILS_HELPER } from "@/helpers/utils.js";
import EventBus from '@/events/bus.js';

const MAP_URL = "https://maps.google.com/maps?q=41.1169951,-7.5688054&hl=es;z=14&output=embed";

export default {
    data() {
        return {
            // Message
            firstName: '',
            lastName: '',
            email: '',
            message: '',
            // Others
            submitted: false,
            loading: false,
            sent: false,
            // Consts
            MAP_URL: MAP_URL,
        }
    },
    validations() {
        return {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            message: { required }
        }
    },
    computed:
    {
        ...mapState('message', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.errors ? this.alertOutput.errors : {};   
        }
    },
    methods: 
    {
        ...mapActions("message", ["submitMessage"]),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        submitClicked: function()
        {      
            this.clearAlert();
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;
            const {firstName, lastName, email, message} = this;

            this.submitMessage({firstName, lastName, email, message});   
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        resetForm() {  
            this.submitted = false;
            this.firstName = "";
            this.lastName = "";
            this.email = "";
            this.message = "";
        },
    },
    watch:
    {
        alertType: function(val)
        {
            if(val != null)
            {
                this.loading = false;

                if(val == "alert-success")
                {
                    this.resetForm();
                    EventBus.$emit("success-alert-requested", { message: this.$t("message.popupSuccess"), primaryButton: this.$t("closePopUp")});
                }
                else if(val == "alert-danger"){
                    EventBus.$emit("error-alert-requested", { message: this.$t("message.popupError"), primaryButton: this.$t("closePopUp")});
                }                   
            }
        }
    }
}
</script>

<style scoped>
.background{
    background-color: #9D9E9E;
}
.btn-button{
    background-color: #DDB427;
    color: black;
}
.btn-button.disabled
{
    opacity: 0.5;
}
.form{
    padding: 30px 0;
    margin: 0 auto;
}
.form-group{
    font-family: clanpro-news;
}
</style>